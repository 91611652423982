import 'projects/sharedComponents/src/geneticStats/wolfiness/geneticStatsWolfiness.scss';

import { Button, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { GeneticStatsWolfinessFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { WolfinessModal } from 'projects/sharedComponents/src/geneticStats/wolfiness/wolfinessModal';
import { buildEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';
import { useIsMobileScreen } from 'projects/sharedComponents/src/hooks/responsive/useIsMobileScreen';

type GeneticStatsWolfinessProps = {
  pet: GeneticStatsWolfinessFragment;
};

// The same as the fragment but with genotype and wolfiness as required fields.
type RequiredWolfiness = GeneticStatsWolfinessFragment & {
  genotype: {
    wolfiness: number;
  };
};

export function shouldShowWolfinessSection(pet: GeneticStatsWolfinessFragment): pet is RequiredWolfiness {
  if (!pet.genotype) {
    return false;
  }

  // Intentionally using null here instead of ! so 0's don't trigger false.
  if (pet.genotype.wolfiness == null) {
    return false;
  }

  return true;
}

export const GeneticStatsWolfiness: FC<GeneticStatsWolfinessProps> = (props) => {
  const { pet } = props;

  const [isDialogOpen, setDialogOpen] = useState(false);

  const isSmallScreen = useIsMobileScreen();

  if (!shouldShowWolfinessSection(pet)) {
    return null;
  }

  let wolfinessPercent = pet.genotype.wolfiness;

  // A small number of dogs go over 100.
  if (wolfinessPercent > 100) {
    wolfinessPercent = 100;
  }

  // Display wolfiness like 15% or 15.5% but not 15.0%
  const wolfinessDisplayPercent = parseFloat(wolfinessPercent.toFixed(1));

  return (
    <>
      <WolfinessModal open={isDialogOpen} onClose={() => setDialogOpen(false)} />
      <Stack direction="row" spacing={isSmallScreen ? 3 : 5}>
        <img
          className="geneticStats-sectionImage"
          src={buildEmbarkCDNUrl('/img/genetic-stats/wolfiness-genetic-stats-icon.svg')}
          alt=""
        />
        <Stack direction="column" spacing={0.5}>
          <div>
            <b className="geneticStatsSection-subSectionHeader">Wolfiness</b>
          </div>
          <div>
            <Typography variant="h6" component="span">
              {wolfinessDisplayPercent}%
            </Typography>
            {!!pet.genotype.wolfinessContext && (
              <Typography
                variant="body2"
                component="span"
                className="geneticStatsWolfiness-wolfinessContext"
                data-testid="geneticStatsWolfiness-wolfinessContext"
              >
                <b>{pet.genotype.wolfinessContext}</b>
              </Typography>
            )}
          </div>
          <div>
            <Button variant="text" className="geneticStatsModal-button" onClick={() => setDialogOpen(true)}>
              <b>What is wolfiness?</b>
            </Button>
          </div>
        </Stack>
      </Stack>
    </>
  );
};
