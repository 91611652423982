import './loadingButton.scss';

import { Button, ButtonProps, CircularProgress } from '@mui/material';

import { FC } from 'react';
import { Heap } from 'clientServerShared/types/eventTracking';
import { SafeTranslateableText } from 'projects/sharedComponents/src/safeTranslateableText/safeTranslateableText';
import { getHeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';

export type LoadingButtonProps = ButtonProps &
  Heap & {
    loading?: boolean;
    loadingCircleSize?: number;
  };

/**
 * Component that adds a loading state to an MUI button
 *
 * TODO: When we move to MUI5, we can replace this with https://mui.com/components/buttons/#loading-button
 */
export const LoadingButton: FC<LoadingButtonProps> = ({
  loading = false,
  loadingCircleSize = 20,
  children,
  heapMetadata,
  ...rest
}) => {
  return (
    <>
      {loading ? (
        <Button {...rest} startIcon="" endIcon="" disabled>
          <CircularProgress className="loadingButton-loadingSpinner" size={loadingCircleSize} />
        </Button>
      ) : (
        <Button {...rest} {...getHeapDataAttributes(heapMetadata)}>
          <SafeTranslateableText>{children}</SafeTranslateableText>
        </Button>
      )}
    </>
  );
};
