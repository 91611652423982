import {
  LocationAutocompleteResult,
  useFetchGoogleLocationPrediction,
} from '@embark/shared-components/src/locationAutocomplete/locationAutocompleteHooks';
import {
  PetVeterinaryInformationFragment,
  UpdateVetPracticeDetailsMutationFn,
  useUpdateVetPracticeDetailsMutation,
} from '@embark/shared-components/src/generated/graphql-client-types';
import { UseFormHandleSubmit, UseFormReturn, useForm } from 'react-hook-form';
import { useEffect, useRef } from 'react';

import { LocationAutocompleteProps } from '@embark/shared-components/src/locationAutocomplete/locationAutocomplete';
import { graphqlErrorMessages } from 'clientServerShared/types/graphqlErrors';
import { showCommonErrorSnackbar } from '@embark/shared-components/src/services/snackbar/snackbar';
import { useSnackbar } from 'notistack';

type UseEditVetInformationFormReturn = {
  onChangeAddress: LocationAutocompleteProps['onChange'];
};

type UseVetLocationProps = {
  setModalOpen: () => void;
  embarkMayContactVet: PetVeterinaryInformationFragment['embarkMayContactVet'] | null;
  practiceLocation: PetVeterinaryInformationFragment['practiceLocation'] | null;
};

export interface VetLocationForm {
  embarkMayContactVet: boolean | null;
  practiceLocation: LocationAutocompleteResult | null;
}

type UseVetInformationReturn = {
  formInformation: UseFormReturn<VetLocationForm, object>;
  handleSubmit: UseFormHandleSubmit<VetLocationForm>;
  handleClose: () => void;
  placesServiceRef: React.RefObject<HTMLDivElement>;
  updateVetPracticeDetails: UpdateVetPracticeDetailsMutationFn;
  onChangeAddress: LocationAutocompleteProps['onChange'];
};

export function useEditVetInformationModal(props: UseVetLocationProps): UseVetInformationReturn {
  const { setModalOpen, embarkMayContactVet, practiceLocation } = props;
  const practiceSearchName = practiceLocation
    ? `${practiceLocation.name} ${practiceLocation.formattedAddress}`
    : undefined;
  const { data: options } = useFetchGoogleLocationPrediction(practiceSearchName);
  const { enqueueSnackbar } = useSnackbar();
  const [updateVetPracticeDetails, {}] = useUpdateVetPracticeDetailsMutation({
    onError: (error) => {
      if (error.message === graphqlErrorMessages.NotAuthorized) {
        enqueueSnackbar(`Failed to update information: ${error.message}`, {
          variant: 'error',
        });
      } else {
        showCommonErrorSnackbar(enqueueSnackbar);
      }
    },
    onCompleted: () => {
      setModalOpen();
      location.reload();
    },
  });
  const placesServiceRef = useRef<HTMLDivElement>(null);

  const handleClose = async (): Promise<void> => {
    setModalOpen();
  };

  const formInformation = useForm<VetLocationForm>({
    mode: 'onChange',
    defaultValues: {
      embarkMayContactVet: embarkMayContactVet ?? true,
    },
  });

  const { setValue, handleSubmit } = formInformation;

  useEffect(() => {
    if (!!options) {
      setValue('practiceLocation', options[0]);
    }
  }, [options, setValue]);

  function useAddressFormInput(): UseEditVetInformationFormReturn {
    const onChangeAddress: LocationAutocompleteProps['onChange'] = (_event, newValue): void => {
      if (newValue) {
        setValue('practiceLocation', newValue);
      }
    };

    return {
      onChangeAddress,
    };
  }

  const { onChangeAddress } = useAddressFormInput();
  return {
    formInformation,
    handleSubmit,
    handleClose,
    placesServiceRef,
    updateVetPracticeDetails,
    onChangeAddress,
  };
}
