import { Integration, Options, Scope, Severity } from '@sentry/types';
import { captureException, captureMessage } from '@sentry/minimal';

import { LogLevel } from './logAdapter';
import { Writable } from 'stream';

export const customerSitePrefixesToTrace = [
  '/members',
  '/dog',
  '/login',
  '/signinup',
  '/forgot',
  '/logout',
  '/breeds',
  '/related',
  '/vet-report',
  '/internal-print-report',
  '/ogimage',
  '/reset',
];

const enum ErrorSeverity {
  TRACE,
  DEBUG,
  INFO,
  WARN,
  ERROR,
  FATAL,
}

interface HandledErrorEvent {
  message: string;
  severity?: ErrorSeverity;
  eventDatas?: Record<string, Record<string, any>>;
  exception?: Error;
}

export interface HealthMonitor {
  notifyErrorHandled: (handledErrorEvent: HandledErrorEvent) => void;
  deserializer: (serializedError: any) => any;
}

export interface MinimalAdaptableSentry {
  init: (options: Options) => void;
  captureException: typeof captureException;
  captureMessage: typeof captureMessage;
}

export interface HealthMonitorConfig {
  environment: string;
  deserializer: (serializedError: any) => any;
  dsn: string | undefined;
  appContext?: {
    name: string;
  };
  integrations?: Integration[];
  tracesSampler: Options['tracesSampler'];
  denyUrls?: Array<string | RegExp>;
  allowUrls?: Array<string | RegExp>;
  release?: string;
}

export function bindAndAdaptSentryToMonitor(sentry: any, config: HealthMonitorConfig): HealthMonitor {
  sentry.init({
    dsn: config.dsn,
    environment: config.environment,
    integrations: function (integrations) {
      const filteredDefaultIntegrations = integrations.filter(function (integration) {
        // Disable Mongo integration to prevent conflicts caused by the @shopify/shopify-api package
        return integration.name !== 'Mongo';
      });
      return [...filteredDefaultIntegrations, ...(config.integrations ?? [])];
    },
    tracesSampler: config.tracesSampler,
    denyUrls: config.denyUrls,
    allowUrls: config.allowUrls,
    release: config.release,
  });

  return {
    notifyErrorHandled: (errorEvent: HandledErrorEvent) => {
      function mapToSeverity(severity: ErrorSeverity | undefined): Severity {
        switch (severity) {
          case ErrorSeverity.TRACE:
            return Severity.Log;
          case ErrorSeverity.DEBUG:
            return Severity.Debug;
          case ErrorSeverity.INFO:
            return Severity.Info;
          case ErrorSeverity.WARN:
            return Severity.Warning;
          case ErrorSeverity.FATAL:
            return Severity.Fatal;
          case ErrorSeverity.ERROR:
          default:
            return Severity.Error;
        }
      }

      const captureFn = !!errorEvent.exception ? sentry.captureException : sentry.captureMessage;
      const capturePrimaryData = !!errorEvent.exception ? errorEvent.exception : errorEvent.message;

      captureFn(capturePrimaryData, (scope: Scope): Scope => {
        if (errorEvent.eventDatas) {
          for (const [eventKey, eventData] of Object.entries(errorEvent.eventDatas)) {
            scope.setContext(eventKey, eventData);
          }
        }
        scope.setLevel(mapToSeverity(errorEvent.severity));
        if (config.appContext) {
          scope.setContext('app', { app_name: config.appContext.name });
        }

        return scope;
      });
    },
    deserializer: config.deserializer,
  };
}

export interface BunyanLogRecord {
  level: LogLevel;
  msg: string;
  err?: string | Record<string, any>;
}

export function createBunyanErrorMonitorStream(healthMonitor: HealthMonitor): NodeJS.WritableStream {
  return new (class ErrorMonitorWritableStream extends Writable {
    write(record: string | Record<string, any>): boolean {
      function mapFromBunyanLevel(logLevel: LogLevel): ErrorSeverity {
        switch (logLevel) {
          case LogLevel.TRACE:
            return ErrorSeverity.TRACE;
          case LogLevel.DEBUG:
            return ErrorSeverity.DEBUG;
          case LogLevel.INFO:
            return ErrorSeverity.INFO;
          case LogLevel.WARN:
            return ErrorSeverity.WARN;
          case LogLevel.ERROR:
            return ErrorSeverity.ERROR;
          case LogLevel.FATAL:
            return ErrorSeverity.FATAL;
          default:
            return ErrorSeverity.ERROR;
        }
      }

      const bunyanLogRecord: BunyanLogRecord = typeof record === 'string' ? JSON.parse(record) : record;

      healthMonitor.notifyErrorHandled({
        message: bunyanLogRecord.msg,
        severity: mapFromBunyanLevel(bunyanLogRecord.level),
        eventDatas: {
          bunyanLogger: bunyanLogRecord,
        },
        exception: bunyanLogRecord.err ? healthMonitor.deserializer(bunyanLogRecord.err) : undefined,
      });

      // See docs for significance: https://nodejs.org/api/stream.html#stream_writable_write_chunk_encoding_callback
      return false;
    }
  })();
}
