import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { ReplayBreedRevealBanner } from '@embark/shared-components/src/pet/breedReveal/replayBreedRevealBanner';
import { ReplayBreedRevealBannerFragment } from '@embark/shared-components/src/generated/graphql-client-types';

export function initPublicProfileBreedRevealBannerContainer(): void {
  ReactDOM.render(
    <AppProviders>
      <PublicProfileBreedRevealBannerContainer />
    </AppProviders>,
    document.getElementById('public-profile-breed-reveal-banner')
  );
}

const PublicProfileBreedRevealBannerContainer: FC = () => {
  const data: ReplayBreedRevealBannerFragment = window['reactPet'];

  return <ReplayBreedRevealBanner pet={data} />;
};
