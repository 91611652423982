import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AccountDetails } from 'clientServerShared/types/account';

export enum UserLoginState {
  LoggedIn = 'LoggedIn',
  NotLoggedIn = 'NotLoggedIn',
  Pending = 'Pending',
}

export type AccountDetailsState = AccountDetails & { userLoginState: UserLoginState };

export const initialState: AccountDetailsState = {
  userLoginState: UserLoginState.Pending,
  user: null,
  shopLink: 'https://shop.embarkvet.com',
  ecommLink: 'https://embarkvet.com',
};

export const accountDetailsSlice = createSlice({
  name: 'accountDetails',
  initialState,
  reducers: {
    authenticatedUserReceived: (state, action: PayloadAction<AccountDetails>) => {
      state.userLoginState = !!action.payload?.user ? UserLoginState.LoggedIn : UserLoginState.NotLoggedIn;
      state.user = action.payload?.user || null;
      state.impersonator = action.payload.impersonator;
      state.shopLink = action.payload.shopLink;
      state.ecommLink = action.payload.ecommLink;
    },
    noUserReceived: (state) => {
      state.userLoginState = UserLoginState.NotLoggedIn;
      state.user = null;
      state.impersonator = undefined;
      state.shopLink = initialState.shopLink;
      state.ecommLink = initialState.ecommLink;
    },
  },
});
