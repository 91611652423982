<template>
  <div>
    <label :for="name" class="signup small-gray-text">{{ labelText }}</label>
    <input v-bind:type="passwordHidden ? 'password' : 'text'" :name="name" :id="name" data-minlength="6" required />

    <label class="toggle-password-displayed">
      <input type="checkbox" class="hidden" v-model="passwordHidden" />
      <img
        :src="imageUrl"
        class="signInUp-showHidePassword"
        alt="Eye icon"
        :aria-label="passwordHidden ? 'Show password' : 'Hide password'"
      />
    </label>
  </div>
</template>
<script>
import Vue from 'vue';
export default Vue.extend({
  props: ['imageHost', 'labelText', 'name'],
  data() {
    return {
      passwordHidden: true,
    };
  },
  computed: {
    imageUrl() {
      const openClosed = this.passwordHidden ? 'open' : 'closed';
      return `${this.imageHost}/icons/eye-${openClosed}.svg`;
    },
  },
});
</script>
