import {
  CloudinaryResultsCallback,
  CloudinaryWidget,
  CloudinaryWidgetOptions,
} from '../../../frontEnd/src/services/cloudinary/cloudinaryWidget';
import { DOCUMENT, uploadPresets } from '../../../../clientServerShared/cloudinary';

import { RotateResponse } from '../../../../models/cloudinary';
import { httpGet } from './ajax-helper';

declare const global: {
  cloudinary: CloudinaryWidget;
};
interface CustomWindow extends Window {
  permissions: Permissions;
  cloudinaryCloudName: string;
}

declare const window: CustomWindow;

export interface GalleryUploadOpts {
  mediaType: string;
  uploadBtnDomStr: string;
}

interface ChangeProfilePicResponse {
  success: boolean;
  imageUrl: string;
}

export interface SimplePublicIdResponse {
  publicId: string;
  success: boolean;
}

export interface Permissions {
  isReadOnly: boolean;
  isEditable: boolean;
}

export type HTTPVerb = 'EDIT' | 'DELETE';

// call back on uploading to cloudinary
function onUploadComplete(mediaType: string, isGalleryUpload: boolean): CloudinaryResultsCallback {
  return async function (_err, data) {
    if (data && data.event === 'success' && data.info) {
      const result = data.info;
      const publicId = result.public_id;
      const resourceType = result.resource_type;
      const phash = result.phash; // perceptual hash, for measuring similarity of different images

      if (mediaType === DOCUMENT) {
        window.location.href = `/members/doc-upload-succeeded/${window.petNum}/${encodeURIComponent(
          publicId
        )}/${resourceType}`;
        return;
      }

      window.location.href = [
        `/members/pet-upload-succeeded/${mediaType}/`,
        `${window.petNum}?publicId=${encodeURIComponent(publicId)}`,
        `&phash=${phash}&isGalleryUpload=${isGalleryUpload}`,
      ].join('');
    }
  };
}

const cloudinaryWidgetOptions = (mediaType: string, multiple = false): CloudinaryWidgetOptions => {
  if (!window.cloudinaryCloudName) {
    throw new Error('cloudinary cloud_name missing in window.cloudinaryCloudName');
  }
  return {
    ...{
      cloud_name: window.cloudinaryCloudName,
      upload_preset: uploadPresets[mediaType],
      multiple,
      sources: ['local'],
      // we use resource type auto for documents such that we can support PDFs whose resource type is image
      resource_type: mediaType === DOCUMENT ? 'auto' : mediaType,
      theme: 'minimal',
      button_caption: `Upload ${mediaType}`,
      text: {
        'sources.url.note': `Public URL of a ${mediaType} file:`,
        'progress.failed_note': 'Some of your files failed uploading.',
      },
      show_powered_by: false,
    },
  };
};

export function openCloudinaryMediaWidgetWithUploadHandler(
  mediaType: string,
  uploadHandler: CloudinaryResultsCallback
): void {
  return global.cloudinary.openUploadWidget(cloudinaryWidgetOptions(mediaType), uploadHandler);
}

// used by the gallery upload buttons and document upload button
export const mediaUploadHandler = (opts: GalleryUploadOpts): void => {
  const { mediaType, uploadBtnDomStr } = opts;
  $(uploadBtnDomStr).click(() => {
    // redirects to a page with a flash card incase user doesn't have full right
    const hasPermissions = checkPermissions();
    if (!hasPermissions) {
      return;
    }
    global.cloudinary.openUploadWidget(cloudinaryWidgetOptions(mediaType), onUploadComplete(mediaType, true));
  });
};

export const rotateMedia = (mediaType: string, publicId: string): Promise<RotateResponse> => {
  if (!window.petNum) {
    throw new Error('petNum missing on window object');
  }
  const rotateUri = `/members/pet-rotate/${mediaType}/${window.petNum}?publicId=${encodeURIComponent(
    publicId
  )}&isAjax=true`;
  return httpGet(rotateUri);
};

export const deleteMedia = (mediaType: string, publicId: string): Promise<SimplePublicIdResponse> => {
  if (!window.petNum) {
    throw new Error('petNum missing on window object');
  }
  const deleteUri = `/members/pet-delete/${mediaType}/${window.petNum}?publicId=${encodeURIComponent(
    publicId
  )}&isAjax=true`;
  return httpGet(deleteUri);
};

export const changeProfilePic = (publicId: string, action: HTTPVerb): Promise<ChangeProfilePicResponse> => {
  if (!window.petNum) {
    throw new Error('petNum missing on window object');
  }
  const uploadSucceededUri = `/members/change-pet-profile-pic/${
    window.petNum
  }?action=${action}&publicId=${encodeURIComponent(publicId)}&isAjax=true`;
  return httpGet<ChangeProfilePicResponse>(uploadSucceededUri);
};

export const checkPermissions = (): boolean => {
  const permissions = window.permissions as Permissions;
  const sharedPetNoPermissionUrl = '/members/forbidden/shared-pet-operation-no-permission';
  const readOnlyUrl = 'members/forbidden/read-only-account';

  if (!permissions) {
    throw new Error('pet permissions object missing');
  }
  if (!permissions.isEditable) {
    window.location.href = sharedPetNoPermissionUrl;
    return false;
  }
  if (permissions.isReadOnly) {
    window.location.href = readOnlyUrl;
    return false;
  }
  return true;
};
