import * as _ from 'lodash';

import { focusAfterContentInInput } from '../../helpers/input';
import { httpGet } from '../../ajax-helper';

const validMessage = 'Valid';
const newLineKey = 'Enter';

export function init(): void {
  addValidationListeners();

  const trackingNumberInput = findTrackingNumberInput();
  if (trackingNumberInput.value) {
    validateTrackingInput(trackingNumberInput);
  }
  const serialNumberInputs = findSerialNumberInputs();
  focusAfterContentInInput(serialNumberInputs[0]);
}

function addValidationListeners(): void {
  findTrackingNumberInput().addEventListener('keyup', function (this: HTMLInputElement) {
    validateTrackingInput(this);
  });

  findSerialNumberInputs().forEach((serialNumberInput) => {
    serialNumberInput.addEventListener('keypress', async function (this: HTMLInputElement, event: KeyboardEvent) {
      if (event.key === newLineKey) {
        event.preventDefault();
        await validateSerialInput(this);
        const serialNumberInputs = findSerialNumberInputs();
        const currentIndex = serialNumberInputs.indexOf(this);
        const nextInput = serialNumberInputs[currentIndex + 1];
        if (nextInput) {
          setTimeout(() => {
            focusAfterContentInInput(nextInput);
          }, 1);
        } else {
          findSerialScanningSaveButton().focus();
        }
      }
    });

    serialNumberInput.addEventListener(
      'keyup',
      _.debounce(function (this: HTMLInputElement) {
        void validateSerialInput(this);
      }, 300)
    );
    serialNumberInput.addEventListener('keyup', function (this: HTMLInputElement) {
      updateFieldAndFormState(this, 'Validating...', StatusLabelState.INFO);
    });
  });
}

async function validateSerialInput(input: HTMLInputElement): Promise<void> {
  if (isInputValueDuplicated(input)) {
    updateFieldAndFormState(input, 'Duplicate code!', StatusLabelState.ERROR);
    return;
  }

  if (input.id.startsWith('box-key-input')) {
    await validateEmCodeInput(input);
  } else if (input.id.startsWith('swab-code-input')) {
    await validateSwabCodeInput(input);
  } else if (input.id.startsWith('methylation-swab-code-input')) {
    await validateMethylationSwabCodeInput(input);
  }
}

enum StatusLabelState {
  INFO,
  ERROR,
  VALID,
}

const statusLabelColors: Record<StatusLabelState, string> = {
  [StatusLabelState.INFO]: 'black',
  [StatusLabelState.ERROR]: 'red',
  [StatusLabelState.VALID]: 'green',
};

function updateFieldAndFormState(element: HTMLInputElement, message: string, state: StatusLabelState): void {
  element.dataset.validated = state === StatusLabelState.VALID ? '1' : '0';
  const statusLabelElement = element.parentElement?.getElementsByClassName('status-label')[0] as HTMLElement | null;
  if (!!statusLabelElement) {
    statusLabelElement.textContent = message;
    statusLabelElement.style.color = statusLabelColors[state];
    findSerialScanningSaveButton().disabled = !isFormValid();
  }
}

interface ValidationResponse {
  error?: string;
}

async function validateEmCodeInput(element: HTMLInputElement): Promise<void> {
  const boxKey = element.value.trim();
  const orderSku = element.dataset.sku;

  if (boxKey.length < 4) {
    return updateFieldAndFormState(element, 'Please provide full EM code', StatusLabelState.ERROR);
  } else if (!/^EM-/.test(boxKey)) {
    return updateFieldAndFormState(element, 'Code must begin with EM-', StatusLabelState.ERROR);
  }

  const response = await httpGet<ValidationResponse>(
    `/admin/api/shipping/serial-scanning/validate-boxkey?boxKey=${boxKey}&orderSku=${orderSku}`
  );

  if (boxKey !== element.value.trim()) {
    return;
  }

  if (response.error) {
    updateFieldAndFormState(element, response.error, StatusLabelState.ERROR);
  } else {
    updateFieldAndFormState(element, validMessage, StatusLabelState.VALID);
  }
}

async function validateSwabCodeInput(element: HTMLInputElement): Promise<void> {
  const swabCode = element.value.trim();

  if (swabCode.length < 12) {
    return updateFieldAndFormState(element, 'Please provide full swab code', StatusLabelState.ERROR);
  }

  const response = await httpGet<ValidationResponse>(
    `/admin/api/shipping/serial-scanning/validate-swabcode?swabCode=${swabCode}`
  );

  if (swabCode !== element.value.trim()) {
    return;
  }

  if (response.error) {
    updateFieldAndFormState(element, response.error, StatusLabelState.ERROR);
  } else {
    updateFieldAndFormState(element, validMessage, StatusLabelState.VALID);
  }
}

async function validateMethylationSwabCodeInput(element: HTMLInputElement): Promise<void> {
  const swabCode = element.value.trim();

  if (swabCode.length < 12) {
    return updateFieldAndFormState(element, 'Please provide full swab code', StatusLabelState.ERROR);
  }
  updateFieldAndFormState(element, validMessage, StatusLabelState.VALID);
}

function isInputValueDuplicated(input: HTMLInputElement): boolean {
  if (isDefaultValueForInput(input.value)) {
    return false;
  }

  const serialNumberInputValues = findSerialNumberInputs()
    .filter((serialNumberInput) => !isDefaultValueForInput(serialNumberInput.value))
    .map((serialNumberInput) => serialNumberInput.value.trim());
  const numberOfInputtedValue = serialNumberInputValues.filter(
    (inputValue) => inputValue === input.value.trim()
  ).length;
  return numberOfInputtedValue !== 1;
}

function isDefaultValueForInput(value: string): boolean {
  return value.length === 0 || value === 'EM-';
}

function validateTrackingInput(element: HTMLInputElement): void {
  const trackingNumber = element.value.trim();

  if (trackingNumber.length > 3) {
    updateFieldAndFormState(element, validMessage, StatusLabelState.VALID);
  } else {
    updateFieldAndFormState(element, 'Please provide tracking number', StatusLabelState.ERROR);
  }
}

function findTrackingNumberInput(): HTMLInputElement {
  return document.getElementById('tracking-number-input') as HTMLInputElement;
}

function findSerialNumberInputs(): HTMLInputElement[] {
  const adminTextInputs = Array.from(document.getElementsByTagName('input'));
  return adminTextInputs.filter(
    (element) =>
      element.id.startsWith('swab-code-input-') ||
      element.id.startsWith('methylation-swab-code-input-') ||
      element.id.startsWith('box-key-input-')
  );
}

function findSerialScanningSaveButton(): HTMLButtonElement {
  return document.getElementById('submit-serial-scanning') as HTMLButtonElement;
}

function isFormValid(): boolean {
  const serialNumberInputs = findSerialNumberInputs();
  const trackingNumberInput = findTrackingNumberInput();
  return [...serialNumberInputs, trackingNumberInput].every((validatedInput) => {
    return validatedInput?.dataset.validated === '1';
  });
}
