import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { DigInBanner } from '@embark/shared-components/src/publicProfile/digInBanner';
import ReactDOM from 'react-dom';

export function initPublicProfileDigInBanner(): void {
  const elements = document.querySelectorAll('[id=public-profile-dig-in-banner]');
  elements.forEach((element) => {
    ReactDOM.render(
      <AppProviders>
        <DigInBanner />
      </AppProviders>,
      element
    );
  });
}
