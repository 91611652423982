export function pluralize(count: number, singular: string, plural: string): string {
  return count === 1 ? singular : plural;
}

export function computeFullDogDisplayName(registeredName: string, callName?: string | null): string {
  if (!!callName) {
    return `\u201c${callName}\u201d ${registeredName}`;
  }

  return registeredName;
}

export function shorten(string: string, chars: number): string {
  const HORIZONTAL_ELLEPSIS_CHAR_CODE = 0x02026;
  return !!string
    ? string.substring(0, chars) + (string.length > chars ? String.fromCharCode(HORIZONTAL_ELLEPSIS_CHAR_CODE) : '')
    : '';
}

export function lowercaseFirstLetter(str: string): string {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function toNumber(stringNumber: string): number {
  return +stringNumber;
}

export function addCorrectPossession(str: string): string {
  const TYPOGRAPHIC_APOSTROPHE_CHAR_CODE = 0x02019;
  return str.slice(-1) === 's'
    ? `${str}${String.fromCharCode(TYPOGRAPHIC_APOSTROPHE_CHAR_CODE)}`
    : `${str}${String.fromCharCode(TYPOGRAPHIC_APOSTROPHE_CHAR_CODE)}s`;
}

const wordFromNumberBetweenOneAndTen: Record<number, string> = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
};

export function getWordFromNumberBetweenOneAndTen(numberToConvert: number): string {
  if (numberToConvert <= 0) {
    return 'zero or less';
  }

  if (numberToConvert > 10) {
    return numberToConvert.toString();
  }

  return wordFromNumberBetweenOneAndTen[numberToConvert];
}
