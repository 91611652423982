<template>
  <div class="overlayLoader-container">
    <div v-if="loading" class="overlayLoader-overlayMask grid-x align-center align-middle">
      <div class="loader">Loading...</div>
    </div>
    <slot>
      <div v-if="loading" class="overlayLoader-defaultOverlaySpace"></div>
    </slot>
  </div>
</template>
<script>
import Vue from 'vue';
export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
});
</script>
<style scoped>
.overlayLoader-container {
  position: relative;
}

.overlayLoader-overlayMask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: white;
}

.overlayLoader-defaultOverlaySpace {
  width: 100%;
  height: 80vh;
  position: relative;
  margin: 0;
  padding: 0;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #797d82;
  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  top: -2.5em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
</style>
