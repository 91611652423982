import { PetWithTitlesList } from './types/aboutPet';
import Vue from 'vue';

export function init(data: PetWithTitlesList): void {
  data.causeOfDeathDescription = data.causeOfDeathDescription || '';

  new Vue({
    el: '#aboutpet-passed-away-container-vue',
    data: data,
    methods: {
      setPassed: function (): void {
        data.isDeceased = true;
      },
      setAlive: function (): void {
        data.isDeceased = false;
      },
      setOptInToComms: function (): void {
        data.optOutOfCommunications = false;
      },
      setOptOutToComms: function (): void {
        data.optOutOfCommunications = true;
      },
    },
    computed: {},
    watch: {},
  });
}
