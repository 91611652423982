<template>
  <div class="form-group">
    <div id="dateOfAdoption" class="small-gray-text" style="margin-top: 15px; margin-bottom: 15px">
      Date of adoption, known or estimated (Optional)
    </div>
    <estimated-date-picker
      date-type-prefix="dateOfAdoption"
      :date-is-estimated-for-select-box="dateOfAdoptionIsEstimatedForSelectBox"
      :date-parts="dateOfAdoptionParts"
      :date-parts-year="dateOfAdoptionYear"
    ></estimated-date-picker>
    <div class="small-12 cell">
      <p v-if="dateOfAdoptionYear" class="text-dark-gray edit-page-descriptive">
        The day you welcomed your dog into your home. If you don’t know the exact date, you can just enter the year, or
        the nearest year and month.
      </p>
    </div>
  </div>
</template>
<script>
import EstimatedDatePicker from './date/estimated-date-picker.vue';
import Vue from 'vue';
export default Vue.extend({
  components: { EstimatedDatePicker },
  props: ['dateOfAdoptionIsEstimatedForSelectBox', 'dateOfAdoptionParts', 'dateOfAdoptionYear'],
});
</script>
