/** A list of URLs where, if a script is downloaded from this URL, we never send errors to the server */
export function getSentryFrontendDenyUrls(): (RegExp | string)[] {
  return [
    /^safari\-extension\:/i,
    /maps\.googleapis\.com/i,
    /(www\.)?google\-analytics\.com/i,
    /(www\.)?googletagmanager\.com/i,
    /edge\.fullstory\.com/i,
    /pinterest\.com/i,
    /lightboxcdn\.com/i,
    /static\.hotjar\.com/i,
    /s\.pinimg\.com/i,
    /admin\//i,
    /front\.embarkvet\.com\/js\/lib/i,
    /cdn\.heapanalytics\.com/i,
    /abtasty.com/i,
    /cdn\.cookielaw\.org/i,
  ];
}
