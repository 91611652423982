declare const global: any;
// To ensure everything loads in the correct order, global.google.charts is loaded by a dynamically added script tag in this code
// global.publicProfileViewsByDay is written into the passport.hbs page when it is rendered

export function init(): void {
  // NOTE that google charts loader can only be loaded once on any page
  const newScript = document.createElement('script');
  newScript.id = 'google-charts-loader';
  newScript.src = 'https://www.gstatic.com/charts/loader.js';
  newScript.onload = onChartsLoaderLoaded;
  document.body.appendChild(newScript);
}

function onChartsLoaderLoaded(): void {
  // Load the Visualization API and the corechart package.
  global.google.charts.load('current', { packages: ['corechart'] });

  // Set a callback to run when the Google Visualization API is loaded.
  global.google.charts.setOnLoadCallback(function () {
    // add chart reveal handler in callback after google charts has initialized
    // for width that is responsive to the container size, need to redraw each time we reveal the chart
    $('.reveal-views-by-day-chart').click(function () {
      let chart;
      $('.views-by-day-chart-container').toggle(0, function () {
        if (chart) {
          return;
        } // only need to draw chart once
        for (const el of Array.from(document.getElementsByClassName('views-by-day-chart-container'))) {
          const data = JSON.parse(global.publicProfileViewsByDay);
          // Create the data table.
          const table = new global.google.visualization.DataTable();
          table.addColumn('date', 'Date');

          // in these early days, we will only show the Total number, since it is the higher number, and showing higher view counts is good for business
          table.addColumn('number', 'Views'); // Total views
          // table.addColumn('number', 'Unique')
          for (const datum of data) {
            table.addRows([
              // in these early days, we will only show the Total number, since it is the higher number, and showing higher view counts is good for business
              // [new Date(datum.viewed_at_date), +datum.view_count, +datum.unique_viewer_ip_address_count]
              [new Date(datum.viewed_at_date), +datum.view_count],
            ]);
          }

          // Set chart options. https://google-developers.appspot.com/chart/interactive/docs/gallery/columnchart
          // This is meant to be a small, almost sparkline-like chart, just give big enough to give a quick feel for the trend over time
          const options = {
            width: '100%',
            chartArea: {
              width: '70%',
            },
            hAxis: {
              format: 'M/d',
            },
            vAxis: {
              title: 'Views',
              minValue: 0,
            },
            // in these early days, we will only show the Total number, since it is the higher number, and showing higher view counts is good for business; TODO add back the legend if/when we start showing both Total and Unique again
            // legend: {
            //   position: 'bottom'
            // },
            title: 'Views by day',
          };

          // Instantiate and draw our chart, passing in some options.
          chart = new global.google.visualization.LineChart(el);
          chart.draw(table, options);
        }
      });
    });
  });
}
