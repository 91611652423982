export const categories = [
  'Breed Club Registration',
  'Health & Veterinary',
  'Microchip Certificate',
  'Pedigree',
  'Title Certificate',
  'Veterinary Verification Form',
  'Other',
];

export const recordTypes = [
  'Allergies/Serum_IgE',
  'BAER Tests',
  'Bile Acids',
  'Blood Chem',
  'CBC',
  'Elbows',
  'Eyes',
  'Heart',
  'Hips',
  'Knees',
  'Radiography',
  'Serum_VWF',
  'Thyroid',
  'Other',
];
