import 'projects/sharedComponents/src/geneticStats/lifeStage/lifeStageCalculationAccordion.scss';

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC } from 'react';

export type LifeStageCalculationAccordionProps = {
  size: string;
  header: string;
  subtext: string;
  lifeStageCalculation: { lifeStage: string; text: string }[];
};

export const LifeStageCalculationAccordion: FC<LifeStageCalculationAccordionProps> = ({
  size,
  header,
  subtext,
  lifeStageCalculation,
}) => {
  return (
    <Accordion className={`lifeStageModal-accordion lifeStageModal-accordion-${size}`}>
      <AccordionSummary
        className="lifeStageModal-accordionSummary"
        expandIcon={<ExpandMoreIcon className="lifeStageModal-expandIcon" />}
      >
        <div className="lifeStageModal-accordionSummary-content">
          <Typography variant="h6" component="h3">
            <strong>{header}</strong>
          </Typography>
          <Typography>{subtext}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className="lifeStageModal-accordionDetails">
        {lifeStageCalculation.map((lifeStage, i) => {
          return (
            <div key={i} className="lifeStageModal-accordionDetails-content">
              <Typography>
                <strong>{lifeStage.lifeStage}</strong>
              </Typography>
              <Typography className="lifeStageModal-accordionDetails-ageText">{lifeStage.text}</Typography>
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
