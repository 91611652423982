import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { BackToLink } from 'projects/sharedComponents/src/pet/navigation/backToLink';
import ReactDOM from 'react-dom';
import { UrlPathPrefix } from '@embark/shared-components/src/services/links/linkBuilder';

export function initBackToLink(): void {
  const backToPageName: string = window['backToPageName'];
  const backToPageUrl: UrlPathPrefix = window['backToPageUrl'];
  const petNum: number | undefined = window['petNum'];
  ReactDOM.render(
    <AppProviders>
      <BackToLink
        urlPath={`${backToPageUrl}?i=${petNum}`}
        linkText={`Back to ${backToPageName}`}
        isReactRouterLink={false}
      />
    </AppProviders>,
    document.getElementById('backToLink')
  );
}
