export enum ConfigTypes {
  API_URL_BASE = 'REACT_APP_API_URL_BASE',
  EMBARK_CDN_BASE_URL = 'REACT_APP_EMBARK_CDN_BASE_URL',
  MY_EV_LEGACY_URL = 'REACT_APP_MY_EV_LEGACY_URL',
  CLOUDINARY_CLOUD_NAME = 'REACT_APP_CLOUDINARY_CLOUD_NAME',
  HEALTH_CONDITIONS_TESTED_FOR_COUNT = 'REACT_APP_HEALTH_CONDITIONS_TESTED_FOR_COUNT',
  TRAITS_TESTED_FOR_COUNT = 'REACT_APP_TRAITS_TESTED_FOR_COUNT',
  CONTENTFUL_SPACE_ID = 'REACT_APP_CONTENTFUL_SPACE_ID',
  CONTENTFUL_API_KEY = 'REACT_APP_CONTENTFUL_API_KEY',
  CONTENTFUL_ENVIRONMENT = 'REACT_APP_CONTENTFUL_ENVIRONMENT',
  BASE_QUALTRICS_SURVEY_URL = 'REACT_APP_BASE_QUALTRICS_SURVEY_URL',
  QUALTRICS_GENERAL_ACTIVATION_SURVEY_TEMPLATE_ID = 'REACT_APP_QUALTRICS_GENERAL_ACTIVATION_SURVEY_TEMPLATE_ID',
  QUALTRICS_METHYLATION_ACTIVATION_SURVEY_TEMPLATE_ID = 'REACT_APP_QUALTRICS_METHYLATION_ACTIVATION_SURVEY_TEMPLATE_ID',
  LAUNCH_PAD_CLIENT_ID = 'REACT_APP_LAUNCH_PAD_CLIENT_ID',
  EMBARKVET_APP = 'REACT_APP_EMBARKVET_APP',
}

type GetConfigReturn = string;

export function getConfig(key: ConfigTypes): GetConfigReturn {
  const value = process.env[key];

  if (!value) {
    throw new Error(`Missing required environment variable ${key}`);
  }

  return value;
}
