import * as EmbarkUrlUtilsVue from './vue-components/plugins/url';

import ChatInbox from './vue-components/chat-inbox.vue';
import Vue from 'vue';

const assert = require('assert');

export function init() {
  Vue.use(EmbarkUrlUtilsVue);

  assert(document.getElementById('chat-inbox'));

  global.vueModel = new Vue({
    el: document.getElementById('chat-inbox'),
    components: {
      ChatInbox,
    },
  });
}
