import { captureException } from '@sentry/react';
import { useEffect } from 'react';

type HeapProperties = Record<string, string | number | boolean | null | undefined>;

export function useHeapEventProperties(properties: HeapProperties): void {
  useEffect(() => {
    addHeapEventProperties(properties);

    return () => {
      Object.keys(properties).map((property) => removeHeapEventProperty(property));
    };
  }, [properties]);
}

const removeHeapEventProperty = (property: string): void => {
  try {
    window.heap?.removeEventProperty(property);
  } catch (err) {
    const message = 'Failed to remove heap event property';
    captureException(err, { extra: { message, property } });
    console.error(message);
  }
};

const addHeapEventProperties = (properties: HeapProperties): void => {
  try {
    window.heap?.addEventProperties(properties);
  } catch (err) {
    const message = 'Failed to add heap event properties';
    captureException(err, { extra: { message, properties } });
    console.error(message);
  }
};
