import Vue from 'vue';
const gauge = require('./vue-components/relatedness-gauge');
gauge.register(Vue);

export function init() {
  global.vueModel = new Vue({
    el: '#pair-relatives-container',
    data: {},
  });
}
