import { createTheme } from '@mui/material';

// The commonTheme is a subset of the component library theme.
export const commonTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 905,
      lg: 1240,
      xl: 1440,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        // In MUI5 the default type is outlined.
        variant: 'standard',
      },
    },
  },
});
