import { Card, CardActionArea, CardContent, Stack, Typography, useMediaQuery } from '@mui/material';
import { HeapAction, HeapCategory } from 'clientServerShared/types/eventTracking';
import { buildEmbarkCDNUrl, buildUrlFromConfig } from 'projects/sharedComponents/src/services/links/linkBuilder';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ConfigTypes } from 'projects/sharedComponents/src/services/getConfig/getConfig';
import { FC } from 'react';
import { ReplayBreedRevealBannerFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { getHeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';
import { styled } from '@mui/material/styles';

export type ReplayBreedRevealBannerProps = {
  pet: ReplayBreedRevealBannerFragment;
};

export const ReplayBreedRevealBanner: FC<ReplayBreedRevealBannerProps> = (props) => {
  const { pet } = props;

  const breedRevealUrl = buildUrlFromConfig(ConfigTypes.EMBARKVET_APP, `/pet/${pet.id}/breed-reveal`);

  return (
    <ReplayBreedRevealCard elevation={0}>
      <CardActionArea
        disableRipple
        href={breedRevealUrl}
        {...getHeapDataAttributes({
          heapAction: HeapAction.REPLAY_BREED_REVEAL,
          heapCategory: HeapCategory.BREED_REVEAL,
        })}
      >
        <ReplayBreedRevealCardContent>
          <Stack direction="row" spacing={{ xs: 2, sm: 3 }} alignItems="center">
            <img alt="" src={buildEmbarkCDNUrl('/img/pet/breedRevealImage.png')} width={56} height={56} />
            <Stack flexGrow={1}>
              <Typography>
                <b>Replay {pet.name}’s breed reveal!</b>
              </Typography>
            </Stack>
            <ChevronRightIcon aria-hidden="true" fontSize="large" />
          </Stack>
        </ReplayBreedRevealCardContent>
      </CardActionArea>
    </ReplayBreedRevealCard>
  );
};

const ReplayBreedRevealCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.helix.blueLight,
  borderRadius: theme.spacing(1),
}));

const ReplayBreedRevealCardContent = styled(CardContent)(({ theme }) => {
  const isSmallOrLargerScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return {
    'padding': `${theme.spacing(2)} ${theme.spacing(isSmallOrLargerScreen ? 3 : 2)}`,
    'paddingRight': `${theme.spacing(isSmallOrLargerScreen ? 2 : 1)}`,
    '&.MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(2),
    },
  };
});
