import { PetWithTitlesList } from './types/aboutPet';
import Vue from 'vue';

export function init(data: PetWithTitlesList): void {
  new Vue({
    el: '#aboutpet-hide-pet-container-vue',
    data: data,
    methods: {
      setShowOnUserPage: function (): void {
        data.showOnUserPage = true;
      },
      unsetShowOnUserPage: function (): void {
        data.showOnUserPage = false;
      },
    },
    computed: {},
    watch: {},
  });
}
