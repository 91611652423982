import 'projects/sharedComponents/src/feedback/feedbackBannerContents.scss';

import { Button, ButtonProps, Typography } from '@mui/material';
import { useDeepCompareEffect, useToggle } from 'react-use';

import { FeedbackDialog } from 'projects/sharedComponents/src/feedback/feedbackDialog';
import { FeedbackProps } from 'projects/sharedComponents/src/feedback/feedbackHooks';
import { ReactElement } from 'react';
import { RenderIf } from 'projects/sharedComponents/src/renderStates/renderIf';
import { getHeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';
import { useShowFeedbackQuery } from 'projects/sharedComponents/src/generated/graphql-client-types';

type FeedbackBannerContentsProps = FeedbackProps & { header?: string };

export const FeedbackBannerContents = (props: FeedbackBannerContentsProps): ReactElement => {
  const { page, variant, petUuid, heapCategory, header = 'Was this page helpful?' } = props;
  const [isDialogOpen, setIsDialogOpen] = useToggle(false);
  const [showBanner, setShowBanner] = useToggle(false);
  const [isHelpful, setIsHelpful] = useToggle(true);

  const handleYesClick: ButtonProps['onClick'] = () => {
    setIsHelpful(true);
    setIsDialogOpen(true);
  };

  const handleNoClick: ButtonProps['onClick'] = () => {
    setIsHelpful(false);
    setIsDialogOpen(true);
  };

  const { data, refetch } = useShowFeedbackQuery({
    variables: { page, variant: variant ?? null, petUuid: petUuid ?? null },
  });

  const onClose = async (): Promise<void> => {
    await refetch();
    setIsDialogOpen(false);
  };

  useDeepCompareEffect(() => {
    if (!!data) {
      const shouldShowFeedback = data.me.shouldShowFeedback ?? false;
      setShowBanner(shouldShowFeedback);
      setShowFeedbackWrapper(shouldShowFeedback);
    }
  }, [data, setShowBanner]);

  return (
    <RenderIf condition={showBanner}>
      <div className="feedbackBanner-box">
        <div className="feedbackBanner">
          <div>
            <Typography>{header}</Typography>
          </div>
          <div className="feedbackBanner-buttonBlock">
            <Button
              variant="text"
              onClick={handleYesClick}
              className="feedbackBanner-button"
              {...getHeapDataAttributes({
                heapCategory,
                heapAction: 'Select Feedback Banner',
                heapDetails: 'Yes',
              })}
            >
              <strong>Yes</strong>
            </Button>
            <Button
              variant="text"
              onClick={handleNoClick}
              className="feedbackBanner-button"
              {...getHeapDataAttributes({
                heapCategory,
                heapAction: 'Select Feedback Banner',
                heapDetails: 'No',
              })}
            >
              <strong>No</strong>
            </Button>
          </div>
        </div>
        <FeedbackDialog
          key={isDialogOpen.toString()}
          {...props}
          isHelpful={isHelpful}
          isOpen={isDialogOpen}
          onClose={onClose}
        />
      </div>
    </RenderIf>
  );
};

function setShowFeedbackWrapper(isShown: boolean): void {
  const feedbackBannerWrapper = document.getElementById('feedback-banner-wrapper');
  if (feedbackBannerWrapper) {
    feedbackBannerWrapper.hidden = !isShown;
  }
}
