import type { Theme, ThemeOptions } from '@mui/material/styles';

import { CSSInterpolation } from '@mui/system';
import { CSSProperties } from 'react';
import { commonTheme } from 'projects/sharedComponents/src/theme/commonTheme';
/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme } from '@mui/material';
import { materialUIColorPalette } from 'projects/sharedComponents/src/theme/colorPalette';

declare module '@mui/material/styles' {
  interface DefaultTheme extends Theme {}

  interface TypographyVariants {
    embarkLinkBlue: CSSProperties;
    sectionTitle: CSSProperties;
    h7: CSSProperties;
  }

  interface TypographyVariantsOptions {
    embarkLinkBlue?: CSSProperties;
    sectionTitle: CSSProperties;
    h7: CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    embarkLinkBlue: true;
    sectionTitle: true;
    h7: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    black: true;
  }
}
declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
  }
}

const muiInputColorOverride = '#3075B2';
const myevCoreBlueDark = '#0073A8';
const myevCoreBlueLightest = '#DEF3FB';
const embarkDarkBlue = '#3c7bb6';

const embarkLinkBlueVariantStyles: CSSInterpolation = {
  color: myevCoreBlueDark,
  fontWeight: 700,
  fontSize: 16,
  letterSpacing: '0',
  textTransform: 'none',
  cursor: 'pointer',
  whiteSpace: 'normal',
};

const baseSharedThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#FFCE34',
    },
    secondary: {
      main: '#57C1E9',
    },
    background: {
      default: '#F7F7F7',
    },
    black: {
      main: '#000',
    },
    ...materialUIColorPalette,
  },
  typography: {
    fontFamily: ['"Maison Neue"', '"Helvetica Neue"', 'Helvetica', 'Roboto', 'Arial', 'sans-serif'].join(','),
    button: {
      fontWeight: 'bold',
    },
    embarkLinkBlue: {
      color: myevCoreBlueDark,
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: '0',
      textTransform: 'none',
      cursor: 'pointer',
      whiteSpace: 'normal',
    },
    sectionTitle: {
      fontWeight: '700',
    },
    h7: {
      fontSize: 18,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        // MUI5 defaults color to primary while MUI4 used `color="default"`. To mimic the behavior of MUI4 I've added
        // a black color and set it as the default. If we want to use primary we must be explicit.
        color: 'black',
      },
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
        textSecondary: {
          // disable the material-ui focus transitions
          'transition': 'none', // I don't think this does what it's supposed to. I believe this should be achieved with the prop `disableRipple`.
          'color': myevCoreBlueDark,
          ':hover': {
            backgroundColor: myevCoreBlueLightest,
          },
          // The override below doesn't appear to be the same as the default value now.
          '.Mui-disabled': {
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        // The default color in MUI5 is now primary
        color: 'secondary',
      },
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: myevCoreBlueDark,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          // left align the first child when there are 3 or more children.
          '> *:first-child': {
            marginRight: 'auto',
          },
          '> *:nth-last-child(2)': {
            marginRight: 'inherit',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: ({ theme }) => {
          return {
            padding: theme.spacing(2),
          };
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          // This style override is overridden by the rules in `input.scss` as the underline only has a border-bottom.
          ':after': {
            borderColor: muiInputColorOverride,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: muiInputColorOverride,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'embarkLinkBlue' && {
            ...embarkLinkBlueVariantStyles,
            gap: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
              gap: theme.spacing(3),
            },
          }),
        }),
        gutters: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'embarkLinkBlue' && {
            paddingTop: theme.spacing(1.5),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(1.5),
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            },
          }),
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        variantMapping: {
          embarkLinkBlue: 'inherit',
          sectionTitle: 'h2',
        },
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'embarkLinkBlue' && embarkLinkBlueVariantStyles),
        }),
      },
    },
    MuiRadio: {
      defaultProps: {
        // The default color in MUI5 is now primary
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: myevCoreBlueDark,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: embarkDarkBlue,
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
        },
        dotActive: {
          backgroundColor: embarkDarkBlue,
        },
      },
    },
  },
};

const baseSharedTheme = createTheme(baseSharedThemeOptions);
baseSharedTheme.typography.sectionTitle = {
  ...baseSharedTheme.typography.sectionTitle,
  fontSize: '1.25rem',
  [baseSharedTheme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
};

// The properties of the second theme will overwrite corresponding properties of the first.
const sharedTheme = createTheme(commonTheme, baseSharedTheme);

export { sharedTheme };
