import * as axios from 'axios';

import Vue from 'vue';

// Each image or video has an associated instance of the like-button component which is initially populated with
// the mediaType and publicId of its associated image or video, and the number of likes and whether the current user had already liked it.
// There will be a short delay after the page starts rendering, while Vue gets going, before Vue renders the like buttons to the screen.
Vue.component('gallery-like-button', {
  props: {
    mediaType: {
      type: String,
      required: true,
    },
    publicId: {
      type: String,
      required: true,
    },
    // original value, before the page was loaded and the user started clicking like buttons
    numberOfLikes: {
      type: Number,
      default: 0,
    },
    // original value, before the page was loaded and the user started clicking like buttons
    isLikedByUser: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    // we want to mutate the 'current' variables, so we should define them just within the component as local data properties
    return {
      currentNumberOfLikes: this.numberOfLikes,
      currentIsLikedByUser: this.isLikedByUser,
      errorMessage: null,
    };
  },
  methods: {
    async unlikeAction() {
      this.errorMessage = null;
      if (!this.currentIsLikedByUser || this.currentNumberOfLikes < 1) {
        return;
      }
      this.currentIsLikedByUser = false;
      // if the user had originally liked this, then the new number of likes is the original number minus 1
      this.currentNumberOfLikes = this.isLikedByUser ? this.numberOfLikes - 1 : this.numberOfLikes;
      const result = (await axios.get(`/api/media-likes/delete/${this.mediaType}?publicId=${this.publicId}`)).data;
      if (result !== 'deleted') {
        // api call failed, undo changes
        this.currentIsLikedByUser = true;
        this.currentNumberOfLikes = this.isLikedByUser ? this.numberOfLikes : this.numberOfLikes + 1;
        this.errorMessage = 'Action failed!';
      }
    },
    async likeAction() {
      this.errorMessage = null;
      if (this.currentIsLikedByUser) {
        return;
      }
      this.currentIsLikedByUser = true;
      // if the user had not originally liked this, then the new number of likes is the original number plus 1
      this.currentNumberOfLikes = this.isLikedByUser ? this.numberOfLikes : this.numberOfLikes + 1;
      const result = (await axios.get(`/api/media-likes/add/${this.mediaType}?publicId=${this.publicId}`)).data;
      if (result !== 'added') {
        // api call failed, undo changes
        this.currentIsLikedByUser = false;
        this.currentNumberOfLikes = this.isLikedByUser ? this.numberOfLikes - 1 : this.numberOfLikes;
        this.errorMessage = 'Action failed!';
      }
    },
  },
  template: `<div>
    <p v-if="currentNumberOfLikes" v-bind:class="{ 'shake-wag': currentIsLikedByUser }" class="gallery-number-of-likes text-dark-blue no-space-below"><i class="fi-paw"></i> {{currentNumberOfLikes}} {{currentNumberOfLikes === 1 ? 'wag' :  'wags'}}</p>
    <button type="button" v-if="currentIsLikedByUser" @click="unlikeAction" class="gallery-like-buttons pointer button-full-width text-dark-blue" aria-label="You gave a wag! Click to remove the wag.">
      <i class="fi-paw"></i> You gave a wag!
    </button>
    <button type="button" v-else @click="likeAction" class="gallery-like-buttons pointer button-full-width text-medium-gray">
      <i class="fi-paw"></i> Give a wag
    </button>
    <p v-if="errorMessage" role="alert" class="callout alert">{{errorMessage}}</p>
  </div>`,
});

export function init() {
  // we bind to the whole public-pet-gallery element even though only the gallery-like-button components are managed by Vue.
  global.vueModel = new Vue({
    el: '#public-pet-gallery',
  });
}
