import 'projects/sharedComponents/src/geneticStats/geneticStatsSection.scss';

import { Divider, Stack, Typography } from '@mui/material';
import { FC, Fragment, ReactNode } from 'react';
import {
  GeneticStatsLifeStage,
  shouldShowLifeStageSection,
} from 'projects/sharedComponents/src/geneticStats/lifeStage/geneticStatsLifeStage';
import {
  GeneticStatsPredictedAdultWeight,
  shouldShowPredictedAdultWeightSection,
} from 'projects/sharedComponents/src/geneticStats/predictedAdultWeight/geneticStatsPredictedAdultWeight';
import {
  GeneticStatsWolfiness,
  shouldShowWolfinessSection,
} from 'projects/sharedComponents/src/geneticStats/wolfiness/geneticStatsWolfiness';

import { AccountDetails } from 'clientServerShared/types/account';
import { GeneticStatsSectionFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';

type GeneticStatsSectionProps = {
  isPublicProfile: boolean;
  pet: GeneticStatsSectionFragment;
  accountDetails: AccountDetails;
};

export const GeneticStatsSection: FC<GeneticStatsSectionProps> = (props) => {
  const { isPublicProfile, pet, accountDetails } = props;

  const sections: ReactNode[] = [];

  if (shouldShowWolfinessSection(pet)) {
    sections.push(<GeneticStatsWolfiness pet={pet} />);
  }

  if (shouldShowPredictedAdultWeightSection(pet)) {
    sections.push(<GeneticStatsPredictedAdultWeight pet={pet} />);
  }

  if (shouldShowLifeStageSection(pet, isPublicProfile)) {
    sections.push(
      <GeneticStatsLifeStage accountDetails={accountDetails} pet={pet} isPublicProfile={isPublicProfile} />
    );
  }

  return (
    <div className="geneticStatsSection-container">
      <Typography variant="h3" component="h2" className="geneticStatsSection-header">
        Genetic stats
      </Typography>

      <Stack direction="column" spacing={3}>
        {sections.map((section, index) => {
          const showDivider = index < sections.length - 1;
          return (
            <Fragment key={index}>
              {section}
              {showDivider && <Divider />}
            </Fragment>
          );
        })}
      </Stack>
    </div>
  );
};
