import { getImageHost } from '../../../../../../clientServerShared/services/url';

export function install(vue: Vue.VueConstructor): void {
  vue.mixin({
    data() {
      return {
        get imgHost() {
          return getImageHost();
        },
      };
    },
  });
}
