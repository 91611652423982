import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { EditVeterinaryInformationModalButton } from 'projects/legacyClient/src/react/pet/veterinaryInformation/editVeterinaryInformationModalButton';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { RenderIf } from '@embark/shared-components/src/renderStates/renderIf';
import { getPetNumFromQueryString } from 'projects/legacyClient/src/react/pet/getPetNumFromQueryString';
import { handleGraphQLError } from '@embark/shared-components/src/services/errors/handleGraphQLError';
import { useGetVeterinaryInformationForPetQuery } from '@embark/shared-components/src/generated/graphql-client-types';
import { usePetUuid } from 'projects/legacyClient/src/react/hooks/usePetUuid';

export function initVeterinaryInformationModal(): void {
  ReactDOM.render(
    <AppProviders>
      <VeterinaryInformationContainer />
    </AppProviders>,
    document.getElementById('veterinaryInformationModal')
  );
}

const VeterinaryInformationContainer: FC = () => {
  const petNum = getPetNumFromQueryString();
  const { petUuid } = usePetUuid();

  const { data, error } = useGetVeterinaryInformationForPetQuery({
    variables: {
      petNum: petNum,
    },
  });

  if (error) {
    handleGraphQLError(error);
    return <></>;
  }

  if (!data?.me || !data.me.petByPetNum || !petUuid) {
    return <></>;
  }

  return (
    <RenderIf condition={!!data.me.petByPetNum && !!petUuid}>
      <EditVeterinaryInformationModalButton pet={data.me.petByPetNum} petUuid={petUuid} />
    </RenderIf>
  );
};
