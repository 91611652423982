import { Button, ButtonProps } from '@mui/material';
import { FC, HTMLAttributeAnchorTarget } from 'react';

import { useHistory } from 'react-router';

export type AnchorButtonProps = ButtonProps & {
  /** True to force the link to not use React Router and do a full page refresh */
  isLegacyMyEvLink?: boolean;
  target?: HTMLAttributeAnchorTarget;
  rel?: string;
};

/**
 * Component that allows us to use material-ui Button's href property with React Router.
 *
 * This is otherwise just a material-ui Button and has no other styling.
 */
export const AnchorButton: FC<AnchorButtonProps> = (props) => {
  const { isLegacyMyEvLink, ...buttonProps } = props;

  const history = useHistory();

  const onNavigation: AnchorButtonProps['onClick'] = (event) => {
    if (!!props.onClick) {
      props.onClick(event);
    }

    if (!props.href) {
      return;
    }

    // Allow linking to other sites like https://embarkvet.com
    // Otherwise, use React Router to navigate
    if (isLegacyMyEvLink || isLinkToAnotherSite(props.href)) {
      return;
    }

    if (!!event) {
      event.preventDefault();
    }

    history.push(props.href);
  };

  return <Button {...buttonProps} onClick={onNavigation} />;
};

function isLinkToAnotherSite(url: string): boolean {
  return url.startsWith('http');
}
