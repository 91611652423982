import { v4 as uuid } from 'uuid';

export function register(Vue) {
  Vue.component('relatedness-gauge', {
    // fillLevel = 0.0 - 100.0
    props: ['fillLevel'],
    data() {
      const SVG_HEIGHT = 56; // This height is hard-coded into the SVG graphic and cannot be changed

      // make the gauge more interesting by 'scaling up' common low values, e.g. 3% -> 0.17 and 5% -> 0.22, and 50% -> 0.70
      const nonlinearFillLevel = Math.sqrt(this.fillLevel / 100);

      const fillHeight = SVG_HEIGHT * nonlinearFillLevel;
      const fillY = SVG_HEIGHT - fillHeight;

      const uniqueID = uuid();

      return {
        fillHeight,
        fillY,
        uniqueID,
      };
    },
    template: `
  <svg width="47px" height="56px" viewBox="0 0 47 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
          <path d="M23.3166954,17.2828308 C25.3460962,11.8280234 29.1472513,6.58985093 34.7365348,1.69145056 C37.617297,-0.833224888 41.9547531,-0.492634356 44.4245142,2.4521808 C46.8942752,5.39699597 46.5610929,9.83089416 43.6803306,12.3555696 C32.4393924,22.2070319 32.4393924,31.544746 44.1804042,44.1293495 C46.7992402,46.9363488 46.6961988,51.3820614 43.9502547,54.0591264 C41.2043106,56.7361915 36.8552971,56.6308589 34.2364611,53.8238596 C28.9359258,48.1424808 25.290925,42.4180903 23.3166954,36.7652704 C21.3424657,42.4180903 17.6974649,48.1424808 12.3969296,53.8238596 C9.77809358,56.6308589 5.42908007,56.7361915 2.68313601,54.0591264 C-0.0628080466,51.3820614 -0.165849506,46.9363488 2.45298647,44.1293495 C14.1939983,31.544746 14.1939983,22.2070319 2.95306009,12.3555696 C0.072297841,9.83089416 -0.260884478,5.39699597 2.20887655,2.4521808 C4.67863758,-0.492634356 9.01609369,-0.833224888 11.8968559,1.69145056 C17.4861395,6.58985093 21.2872945,11.8280234 23.3166954,17.2828308 Z" :id="'path-' + uniqueID"></path>
          <filter x="-13.2%" y="-10.7%" width="126.4%" height="121.4%" filterUnits="objectBoundingBox" :id="'filter-' + uniqueID">
              <feGaussianBlur stdDeviation="5.5" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
              <feOffset dx="0" dy="1" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
              <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
              <feColorMatrix values="0 0 0 0 0.0392156863   0 0 0 0 0.0392156863   0 0 0 0 0.0392156863  0 0 0 0.1 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
          </filter>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-683.000000, -23.000000)">
              <g transform="translate(683.000000, 23.000000)">
                  <mask :id="'mask-' + uniqueID" fill="white">
                      <use :href="'#path-' + uniqueID"></use>
                  </mask>
                  <g fill-rule="nonzero">
                      <use fill-opacity="0.1" fill="#0A0A0A" :href="'#path-' + uniqueID"></use>
                      <use fill="black" fill-opacity="1" :filter="'url(#filter-' + uniqueID + ')'" :href="'#path-' + uniqueID"></use>
                  </g>
                  <rect fill="#7ABAFF" fill-rule="nonzero" :mask="'url(#mask-' + uniqueID + ')'" x="-23.2771084" v-bind:y="fillY" width="92" v-bind:height="fillHeight"></rect>
              </g>
          </g>
      </g>
  </svg>
  `,
  });
}
