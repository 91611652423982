<template>
  <div>
    <p id="dogIntactSelect-intactQuestion" v-bind:class="textContainerClasses">
      {{ intactQuestionDisplay }}
    </p>
    <radio-select
      aria-labelledby="dogIntactSelect-intactQuestion"
      :class="buttonContainerClasses"
      v-model="value"
      @input="$emit('input', value)"
      inputIdPrefix="dogIntactSelect-radio"
      name="intact"
      :options="intactOptions"
      :buttonClasses="buttonClasses"
      :buttonStyle="buttonStyle"
    />
  </div>
</template>
<script>
import RadioSelect from './input/radio-select.vue';
import Vue from 'vue';
export default Vue.extend({
  props: [
    'value',
    'sex',
    'isBreeder',
    'petDisplayName',
    'containerClasses',
    'textContainerClasses',
    'textContainerStyles',
    'buttonContainerClasses',
    'buttonClasses',
    'buttonStyle',
  ],
  components: { RadioSelect },
  computed: {
    fixedDisplayText() {
      return this.sex === 'm' ? 'Neutered' : 'Spayed';
    },
    intactQuestionDisplay() {
      const spayedOrNeuteredText = this.sex === 'm' ? 'neutered' : 'spayed';
      const isOptionalText = this.isBreeder === 'true' ? '' : '(Optional)';
      return `Is ${this.petDisplayName} already ${spayedOrNeuteredText} or expected to be ${spayedOrNeuteredText}? ${isOptionalText}`;
    },
    intactOptions() {
      return [
        {
          value: 'i',
          label: ` Not ${this.fixedDisplayText}`,
        },
        {
          value: 'f',
          label: this.fixedDisplayText,
        },
      ];
    },
  },
});
</script>
