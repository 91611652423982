import {
  Box,
  DialogContent,
  Divider,
  IconButton,
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps,
  alpha,
  useMediaQuery,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Close } from '@mui/icons-material';
import clsx from 'clsx';

export type DialogProps = MUIDialogProps & {
  onClose: () => void;
  disableBackdropClickClose?: boolean;
  hideCloseButton?: boolean;
  childrenContainerClassName?: string;
  renderActions?: JSX.Element;
};

export const Dialog: FC<DialogProps> = (props) => {
  const {
    onClose,
    disableBackdropClickClose,
    hideCloseButton = false,
    children,
    open,
    childrenContainerClassName,
    renderActions,
    ...rest
  } = props;

  const [isCloseDisabled, setIsCloseDisabled] = useState(false);

  const theme = useTheme();
  const isSmallOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (open) {
      setIsCloseDisabled(false);
    }
  }, [open]);

  const handleDialogClose: MUIDialogProps['onClose'] = (_event, reason) => {
    if (disableBackdropClickClose && reason === 'backdropClick') {
      return;
    }

    handleCloseClicked();
  };

  function handleCloseClicked(): void {
    if (isCloseDisabled) {
      return;
    }

    setIsCloseDisabled(true);
    onClose();
  }

  return (
    <MUIDialog
      fullWidth
      {...rest}
      open={open}
      fullScreen={isSmallOrSmaller}
      className={clsx('modalDialogRoot', props.className)}
      onClose={handleDialogClose}
    >
      <DialogContent>
        {!hideCloseButton && (
          <Box className="modalDialogCloseIcon-container" display="flex" justifyContent="flex-end">
            <DialogIconButton
              size="large"
              className="modalDialogCloseIcon"
              onClick={handleCloseClicked}
              aria-label="Close modal"
            >
              <Close fontSize="large" />
            </DialogIconButton>
          </Box>
        )}

        <Box className={clsx(childrenContainerClassName)} pt={2} pb={renderActions ? 0 : 2}>
          {children}
        </Box>
      </DialogContent>
      {!!renderActions && <Divider />}
      {!!renderActions && renderActions}
    </MUIDialog>
  );
};

const DialogIconButton = styled(IconButton)(({ theme }) => {
  const smallButtonSize = 4.5;
  const largeButtonSize = 6;
  return {
    'color': theme.palette.black.main,
    'backgroundColor': theme.palette.common.white,
    '&:hover': {
      color: theme.palette.black.main,
      backgroundColor: alpha(theme.palette.black.main, 0.04),
    },
    '&:focus': {
      color: theme.palette.black.main,
      backgroundColor: alpha(theme.palette.black.main, 0.04),
    },
    '&.MuiIconButton-sizeSmall': {
      width: theme.spacing(smallButtonSize),
      height: theme.spacing(smallButtonSize),
    },
    '&.MuiIconButton-sizeSmall .MuiSvgIcon-root': {
      width: theme.spacing(smallButtonSize / 2),
      height: theme.spacing(smallButtonSize / 2),
    },
    '&.MuiIconButton-sizeLarge': {
      width: theme.spacing(largeButtonSize),
      height: theme.spacing(largeButtonSize),
    },
    '&.MuiIconButton-sizeLarge .MuiSvgIcon-root': {
      width: theme.spacing(largeButtonSize / 2),
      height: theme.spacing(largeButtonSize / 2),
    },
  };
});
