import { EditVeterinaryInformationModal } from 'projects/legacyClient/src/react/pet/veterinaryInformation/editVeterinaryInformationModal';
import { FC } from 'react';
import { PetVeterinaryInformationFragment } from '@embark/shared-components/src/generated/graphql-client-types';
import { RenderIf } from '@embark/shared-components/src/renderStates/renderIf';
import { useToggle } from 'react-use';

type EditVeterinaryInformationModalButtonProps = {
  pet: PetVeterinaryInformationFragment;
  petUuid: string;
};

export const EditVeterinaryInformationModalButton: FC<EditVeterinaryInformationModalButtonProps> = ({
  pet,
  petUuid,
}) => {
  const [modalOpen, setModalOpen] = useToggle(false);

  const handleClick = (): void => {
    setModalOpen();
  };

  return (
    <>
      <button className="button-text-link" onClick={handleClick}>
        {pet.practiceLocation ? 'Edit' : 'Add Vet Practice'}
      </button>
      <RenderIf condition={modalOpen}>
        <EditVeterinaryInformationModal
          open={modalOpen}
          setModalOpen={setModalOpen}
          embarkMayContactVet={pet.embarkMayContactVet}
          practiceLocation={pet.practiceLocation}
          petUuid={petUuid}
        />
      </RenderIf>
    </>
  );
};
