import { AccountDetails } from 'clientServerShared/types/account';
import { buildMyEvLegacyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';

export type ImpersonationProps = {
  showImpersonation: boolean;
  impersonatorName?: string;
  accountType: string;
  accountName: string;
  exitImpersonationUrl: string;
};

function getAccountType(permissions: NonNullable<AccountDetails['user']>['permissions']): string {
  if (permissions.isBreeder) {
    return 'Breeder';
  } else if (permissions.isVet) {
    return 'Vet';
  } else {
    return 'Consumer';
  }
}

export function useImpersonationProps(accountDetails: AccountDetails): ImpersonationProps {
  const impersonatorName = accountDetails?.impersonator?.name;
  const showImpersonation = !!impersonatorName;
  const user = accountDetails?.user;
  const accountName = user?.name || '';
  const accountType = !!user?.permissions ? getAccountType(user.permissions) : '';

  const exitImpersonationUrl = buildMyEvLegacyLink('/members/unimpersonate');

  return {
    showImpersonation,
    impersonatorName,
    accountType,
    accountName,
    exitImpersonationUrl,
  };
}
