import { UploadApiResponse } from 'cloudinary';

/**
 * The Cloudinary Widget has no TypeScript types, this adds minimal types for the methods we use
 *
 * See: https://cloudinary.com/documentation/upload_widget_reference
 */
export type CloudinaryWidget = {
  /**
   * See: https://cloudinary.com/documentation/upload_widget_reference#openuploadwidget
   */
  openUploadWidget: (options: CloudinaryWidgetOptions, resultsCallback: CloudinaryResultsCallback) => void;
};

// Extend the window object to have the cloudinary widget.
// This is available globally anywhere in the frontend project.
declare global {
  interface Window {
    cloudinary: CloudinaryWidget;
  }
}

export type CloudinaryResultsCallback = (error: string | null, result: CloudinaryEventResult) => Promise<void> | void;

export type CloudinaryEvent =
  | 'success'
  | 'close'
  | 'upload-added'
  | 'queues-start'
  | 'queues-end'
  | 'batch-cancelled'
  | 'source-changed'
  | 'tags'
  | 'publicid'
  | 'retry'
  | 'abort'
  | 'display-changed'
  | 'show-completed';

export type CloudinarySource = 'local' | 'url' | 'camera' | 'facebook' | 'dropbox' | 'instagram' | 'image_search';

export interface CloudinaryEventResult {
  event: CloudinaryEvent;
  info?: UploadApiResponse;
}

/**
 * See: https://cloudinary.com/documentation/upload_widget_reference#parameters
 */
export interface CloudinaryWidgetOptions {
  cloud_name: string;
  upload_preset: string;
  multiple: boolean;
  resource_type: string;
  sources?: CloudinarySource[];
  theme: string;
  button_caption: string;
  text: {
    [field: string]: string;
  };
  show_powered_by: boolean;
  raw_convert?: string;
  max_file_size?: number;
}

// Cloudinary uses 'upload presets' to control many upload settings, including allowed file types, whether to retain the filename, whether to allow unsigned uploads, whether to do face detection or calculate a perceptual hash, etc.
// cloudinary media types
export interface UploadPresets {
  image: string;
  video: string;
  document: string;
}

// Using a different setting will change these presets to a different one referencing the cloudinary config. Making direct changes to these configurations will affect production.
export const uploadPresets: UploadPresets = {
  video: 'ub1ellaq',
  image: 'pfmcboin',
  document: 'gnq1dz7o',
};
