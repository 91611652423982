import 'projects/sharedComponents/src/textField/textFieldCharacterLimit.scss';

import React, { ChangeEvent, FC, useCallback } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

import clsx from 'clsx';

type TextFieldCharacterLimitProps = TextFieldProps & {
  limit?: number;
};

export const TextFieldCharacterLimit: FC<TextFieldCharacterLimitProps> = (props) => {
  const { limit: CHARACTER_LIMIT = 20, onChange } = props;
  const [value, setValue] = React.useState<string>((props.value as string) ?? '');

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
      setValue(event.target.value);
      if (!!onChange) {
        onChange(event);
      }
    },
    [setValue, onChange]
  );

  const className = clsx('textFieldCharacterLimit', props.className);

  return (
    <TextField
      {...props}
      className={className}
      inputProps={{
        maxLength: CHARACTER_LIMIT,
      }}
      value={value}
      helperText={`${value.length}/${CHARACTER_LIMIT} characters`}
      onChange={handleChange}
    />
  );
};
