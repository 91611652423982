import { accountDetailsSlice } from '@embark/shared-components/src/state/user/accountDetailsSlice';
import { configureStore } from '@reduxjs/toolkit';

/** Reducers that can be accessed in shared components */
export const legacyClientReducers = {
  accountDetails: accountDetailsSlice.reducer,
};

export const legacyClientStore = configureStore({
  reducer: legacyClientReducers,
  preloadedState: {
    accountDetails: window['accountDetails'] != null ? window['accountDetails'] : undefined,
  },
});

export type RootState = ReturnType<typeof legacyClientStore.getState>;
export type AppDispatch = typeof legacyClientStore.dispatch;
