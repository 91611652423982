import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';

import Cookies from 'js-cookie';
import React from 'react';
import { getMyEvLegacyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';
import { trimEnd } from 'lodash';

const myEvApiURL = trimEnd(getMyEvLegacyLink(), '/');

const link = createHttpLink({
  uri: `${myEvApiURL}/api/graphql`,
  credentials: 'include',
  headers: {
    'x-csrf-token': Cookies.get('XSRF-TOKEN'),
  },
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export const ApolloClientProvider: React.FC = ({ children }) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
