<template>
  <ModalDialog class="modal-descriptive" :modalName="modalName" aria-labelledby="confirmBlock-title">
    <h2 id="confirmBlock-title" class="header-2">Are you sure you want to block this user?</h2>
    <p class="small-space-above">
      If you do, you will no longer be able to send or receive messages from them, and they will not be able to start
      new conversations with you.
    </p>

    <section class="space-above-sm-responsive space-below-xl-small-only grid-x grid-padding-y align-center">
      <button
        type="button"
        class="button-destructive cell small-12 medium-4 medium-offset-2 medium-order-2"
        v-on:click="block"
        data-close
      >
        Block
      </button>

      <button class="button-text-link cell small-12 medium-4 medium-order-1 space-above-sm-only" data-close>
        Cancel
      </button>
    </section>
  </ModalDialog>
</template>

<script>
import ModalDialog from '../modal-dialog.vue';
import Vue from 'vue';
import axios from 'axios';
import { getCsrfToken } from '../../csrf';
import { log } from '../../browserLogger';
export default Vue.extend({
  props: {
    modalName: {
      type: String,
      required: true,
    },
    conversationId: {
      type: Number,
      required: true,
    },
  },
  components: { ModalDialog },
  methods: {
    async block() {
      try {
        await axios.put('/members/chat/block', {
          conversationId: this.conversationId,
          _csrf: getCsrfToken(),
        });
        this.$emit('refresh');
      } catch (err) {
        log.info(err);
        global.alert('Failed to block user');
      }
    },
  },
});
</script>
