import 'projects/sharedComponents/src/feedback/feedbackDialog.scss';

import {
  AddFeedbackFormInput,
  FeedbackProps,
  useAddFeedbackRequestProps,
} from 'projects/sharedComponents/src/feedback/feedbackHooks';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Dialog } from 'projects/sharedComponents/src/dialog/dialog';
import { FeedbackDialogConfirmation } from 'projects/sharedComponents/src/feedback/feedbackDialogConfirmation';
import { FeedbackDialogForm } from 'projects/sharedComponents/src/feedback/feedbackDialogForm';
import { FeedbackItem } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { RenderIf } from 'projects/sharedComponents/src/renderStates/renderIf';
import { Typography } from '@mui/material';
import { buildEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';

type FeedbackDialogProps = FeedbackProps & {
  isHelpful: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export const FeedbackDialog: FC<FeedbackDialogProps> = (props) => {
  const {
    helpfulFeedbackItems = [
      FeedbackItem.Specific,
      FeedbackItem.LearnedSomething,
      FeedbackItem.EasyToUnderstand,
      FeedbackItem.WillUse,
      FeedbackItem.PutAtEase,
    ],
    unhelpfulFeedbackItems = [
      FeedbackItem.NotSpecific,
      FeedbackItem.DidNotLearnAnything,
      FeedbackItem.TooLong,
      FeedbackItem.Worried,
    ],
    heapCategory,
    isHelpful,
    isOpen,
    onClose,
    helpfulConfirmationText,
    unhelpfulConfirmationText,
  } = props;
  const headerIconUrl = isHelpful
    ? buildEmbarkCDNUrl('/img/feedback/icon-feedback-happy.svg')
    : buildEmbarkCDNUrl('/img/feedback/icon-feedback-unhappy.svg');

  const [showConfirmation, setShowConfirmation] = useState(false);

  const { addFeedback, loading: isAddFeedbackLoading } = useAddFeedbackRequestProps({
    ...props,
    showConfirmation: setShowConfirmation,
  });

  const formInformation = useForm<AddFeedbackFormInput>({
    mode: 'onChange',
  });

  const onCloseDialog = (): void => {
    formInformation.reset();
    onClose();
  };

  return (
    <Dialog
      className="feedbackDialog"
      childrenContainerClassName="feedbackDialog-container"
      open={isOpen}
      onClose={onCloseDialog}
      maxWidth="sm"
      aria-labelledby="feedbackDialog-header"
    >
      <div className="feedbackDialog-iconContainer">
        <img src={headerIconUrl} alt="" height={104} />
      </div>
      <Typography className="feedbackDialog-header" id="feedbackDialog-header" component="h3" variant="h5">
        <strong>Thanks for your feedback.</strong>
      </Typography>
      <RenderIf condition={!showConfirmation}>
        <FormProvider {...formInformation}>
          <FeedbackDialogForm
            helpfulFeedbackItems={helpfulFeedbackItems}
            unhelpfulFeedbackItems={unhelpfulFeedbackItems}
            isHelpful={isHelpful}
            addFeedback={addFeedback}
            isLoading={isAddFeedbackLoading}
            onCloseDialog={onCloseDialog}
            heapCategory={heapCategory}
          />
        </FormProvider>
      </RenderIf>
      <RenderIf condition={showConfirmation}>
        <FeedbackDialogConfirmation
          isHelpful={isHelpful}
          helpfulText={helpfulConfirmationText}
          unhelpfulText={unhelpfulConfirmationText}
        />
      </RenderIf>
    </Dialog>
  );
};
