<template>
  <section :style="cssVars" class="showMore-container" :class="{ collapsed: isCollapsed }">
    <section class="showMore-containerContent" ref="showMoreContainer">
      <slot></slot>
    </section>
    <section v-if="showButton" class="grid-x showMore-buttonContainer" :class="{ opened: !isCollapsed }">
      <button class="cell small-12 button-text-link showMore-button" @click="updateCollapsed()">
        <span class="showMore-buttonText">{{ isCollapsed ? 'Show More' : 'Show Less' }}</span>
        <img
          :alt="isCollapsed ? 'down arrow icon' : 'up arrow icon'"
          class="showMore-downArrow"
          :src="`${imgHost}/icons/down-arrow.svg`"
        />
      </button>
    </section>
  </section>
</template>

<script>
import * as _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'show-more',
  props: {
    collapsedHeight: {
      type: String,
      default: '6',
    },
  },
  data() {
    return {
      isCollapsed: true,
      nonCollapsedHeight: '',
      updateNonCollapsedHeightDebounce: null,
      SHOW_MORE_ELEMENT_HEIGHT_IN_REMS: 2,
    };
  },
  methods: {
    updateNonCollapsedHeight() {
      this.nonCollapsedHeight = this.$refs.showMoreContainer.clientHeight;
    },
    updateCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    },
    convertRemToPixels(rem) {
      return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    },
  },

  created() {
    this.updateNonCollapsedHeightDebounce = _.debounce(this.updateNonCollapsedHeight, 200);
    window.addEventListener('resize', this.updateNonCollapsedHeightDebounce);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateNonCollapsedHeightDebounce);
  },
  mounted() {
    this.$nextTick(this.updateNonCollapsedHeight);
  },
  computed: {
    showButton() {
      return (
        this.nonCollapsedHeight >
        this.convertRemToPixels(parseFloat(this.collapsedHeight) + this.SHOW_MORE_ELEMENT_HEIGHT_IN_REMS)
      );
    },
    cssVars() {
      return {
        '--max-collapsed-height': this.collapsedHeight + 'rem',
        '--max-open-height':
          this.nonCollapsedHeight + this.convertRemToPixels(this.SHOW_MORE_ELEMENT_HEIGHT_IN_REMS) + 'px',
      };
    },
  },
});
</script>

<style scoped>
.showMore-container {
  position: relative;
  overflow: hidden;
  max-height: var(--max-open-height);
  transition: max-height 0.6s ease-in;
}

.showMore-container.collapsed {
  max-height: var(--max-collapsed-height);
}

.showMore-containerContent {
  padding-bottom: 2rem;
}

.showMore-buttonContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.showMore-buttonContainer::before {
  content: '';
  background-image: linear-gradient(to bottom, transparent 50%, white);
  height: 30px;
  width: 100%;
}

.showMore-button {
  background-color: white;
  text-align: center;
  vertical-align: middle;
}

.showMore-buttonText {
  vertical-align: middle;
}

.showMore-downArrow {
  margin-left: 0.5rem;
  transform: rotate(0deg);
  transition: transform 0.1s ease-in;
}

.showMore-buttonContainer.opened::before {
  background-image: unset;
}

.showMore-buttonContainer.opened .showMore-downArrow {
  transform: rotate(-180deg);
}
</style>
