import 'projects/sharedComponents/src/dogAvatar/legacy/legacyBreedHalo.scss';

import {
  GenotypeBreedMix,
  LegacyBreedHaloFragment,
  ProductType,
} from 'projects/sharedComponents/src/generated/graphql-client-types';
import { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie';

import { DogAvatar } from 'projects/sharedComponents/src/dogAvatar/dogAvatar';
import { FC } from 'react';
import { Group } from '@visx/group';
import { Pie } from '@visx/shape';

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };

const defaultAvatarPadding = { horizontal: 15, vertical: 15 };

export type BreedHaloProps = {
  /** Margin around the breed halo, also shrinks the breed halo */
  margin?: { top: number; right: number; bottom: number; left: number };
  /** The distance between the dog's profile picture and the donut slices (the white space between them) */
  dogAvatarPadding?: { horizontal: number; vertical: number };
  /** Sets the width and height of the breed halo. Height and width are the same as this must be a square. */
  width: number;
  dog: LegacyBreedHaloFragment;
  /** False to disable the spinning animation */
  doSpinAnimation?: boolean;
  /** Controls how thick the slices of the donut are */
  donutThickness?: number;
  /** False to make the halo gray and not show breed breakdown */
  shouldShowBreeds?: boolean;
  /** Set to override the default label for the dog avatar. */
  ['aria-label']?: string;
};

/** Displays the colored breed halo around the dog's profile picture */
export const LegacyBreedHalo: FC<BreedHaloProps> = (props) => {
  const {
    margin = defaultMargin,
    dogAvatarPadding = defaultAvatarPadding,
    width,
    doSpinAnimation = true,
    donutThickness = 17,
    shouldShowBreeds = true,
  } = props;

  let { 'aria-label': ariaLabel } = props;

  let dog: LegacyBreedHaloFragment = props.dog;
  if (!shouldShowBreeds) {
    dog = {
      ...props.dog,
      genotype: null,
    };
  }

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = width - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const hasBreedResults = !!dog.genotype;
  const isPurebredKit = dog.swabSummary?.productType === ProductType.Purebred;

  const breedMix: BreedHaloArcBreedPct[] = dog.genotype?.breedMix ?? [
    {
      breedPct: 100,
    },
  ];

  const dogAvatarWidth = width - margin.left - margin.right - dogAvatarPadding.horizontal - donutThickness * 2;
  const dogAvatarHeight = width - margin.top - margin.bottom - dogAvatarPadding.vertical - donutThickness * 2;

  /** Controls what colors are displayed for the donut slices. */
  const svgClassName = !hasBreedResults
    ? 'breedHalo-noResultColors'
    : isPurebredKit
    ? 'breedHalo-purebredColors'
    : 'breedHalo-normalColors';

  if (!ariaLabel) {
    if (!shouldShowBreeds) {
      ariaLabel = `Breed halo for ${dog.name}`;
    } else {
      ariaLabel = !!dog.genotype?.breedMix
        ? `Donut chart displaying that there are ${dog.genotype.breedMix.length} breeds detected in ${dog.name}'s breed results`
        : `Empty donut chart while we are processing ${dog.name}'s breed results`;
    }
  }

  return (
    <div className="breedHalo-container" style={{ width, height: width }}>
      {/* Absolutely positioned container that overlaps with the breed halo below */}
      <div
        className="breedHalo-dogAvatarContainer"
        style={{
          width: dogAvatarWidth,
          height: dogAvatarHeight,
        }}
      >
        <DogAvatar
          className="breedHalo-dogAvatar"
          style={{
            borderRadius: dogAvatarWidth / 2,
          }}
          dog={{
            name: dog.name,
            avatarColorIndex: dog.avatarColorIndex,
            profileImageUrl: dog.profileImageUrl,
          }}
        />
      </div>

      {/* Donut chart that shows the colored breed breakdown and does a cool spin animation*/}
      <div className={doSpinAnimation ? 'breedHalo-spinContainer' : ''} style={{ width, height: width }}>
        <svg
          className={svgClassName}
          data-testid={svgClassName}
          width={width}
          height={width}
          role="img"
          aria-label={ariaLabel}
        >
          <Group top={centerY + margin.top} left={centerX + margin.left}>
            <Pie
              data={breedMix}
              pieValue={(breedMix) => breedMix.breedPct}
              outerRadius={radius}
              innerRadius={radius - donutThickness}
              cornerRadius={0}
              padAngle={0}
            >
              {(pie) => {
                return pie.arcs.map((arc, index) => {
                  return <BreedHaloArc key={index} colorIndex={index + 1} pie={pie} arc={arc} />;
                });
              }}
            </Pie>
          </Group>
        </svg>
      </div>
    </div>
  );
};

type BreedHaloArcBreedPct = Pick<GenotypeBreedMix, 'breedPct'>;

type BreedHaloArcProps = {
  // 1 = first color in breedColors.scss
  colorIndex: number;
  pie: ProvidedProps<BreedHaloArcBreedPct>;
  arc: PieArcDatum<BreedHaloArcBreedPct>;
};

/** Renders a single slice of the donut chart, colored based on the number of breeds the dog has */
const BreedHaloArc: FC<BreedHaloArcProps> = (props) => {
  const { colorIndex, pie, arc } = props;

  if (colorIndex < 1 || colorIndex > 9) {
    throw new Error('colorIndex must be between 1 and 9');
  }

  const svgPath = pie.path(arc);

  // This should never happen
  if (svgPath == null) {
    throw new Error('Received null instead of an SVG path "d" attribute');
  }

  const pathClassName = `breedHalo-breedColor-${colorIndex}`;

  return (
    <g>
      <path className={pathClassName} d={svgPath} data-testid={pathClassName} />
    </g>
  );
};
