// contains ajax util functions that may be needed in more than one module

export const httpGet = <T>(url: string, errorMsg?: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    void $.get(url).done((response: T) => {
      if (response) {
        return resolve(response);
      }
      return reject(`${errorMsg}  failed to get http response for ${url}`);
    });
  });
};

export const httpPost = <T>(url: string, jsonBody: any, errorMsg?: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    void $.post(url, jsonBody).done((response: T) => {
      if (response) {
        return resolve(response);
      }
      return reject(`${errorMsg}  failed to get http response for ${url}`);
    });
  });
};
