import { Link, LinkProps } from '@mui/material';

import { HeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';
import React from 'react';
import clsx from 'clsx';

export interface StandardLinkProps extends LinkProps, HeapDataAttributes {
  openInNewTab?: boolean;
}

const openInNewTabProps: Partial<LinkProps> = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

export const StandardLink: React.FC<StandardLinkProps> = (props) => {
  const { openInNewTab, ...linkProps } = props;
  const newLinkProps: LinkProps = {
    ...linkProps,
    ...(openInNewTab && openInNewTabProps),
    className: clsx('standardTextLink', props.className),
  };
  return (
    <>
      <Link {...newLinkProps}>{props.children}</Link>
    </>
  );
};
