import {
  GeneticStatsLifeStageFragment,
  PetLifeStage,
} from 'projects/sharedComponents/src/generated/graphql-client-types';
import { HeapAction, HeapCategory } from 'clientServerShared/types/eventTracking';
import { Stack, Typography } from '@mui/material';
import { buildEmbarkCDNUrl, buildShopifyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';

import { AccountDetails } from 'clientServerShared/types/account';
import { FC } from 'react';
import { LifeStageCalculationModalButton } from 'projects/sharedComponents/src/geneticStats/lifeStage/lifeStageCalculationModalButton';
import { getHeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';
import { hisOrHer } from 'projects/sharedComponents/src/services/formatting/sexFormatting';
import { useIsMobileScreen } from 'projects/sharedComponents/src/hooks/responsive/useIsMobileScreen';

type GeneticStatsLifeStageProps = {
  isPublicProfile: boolean;
  pet: GeneticStatsLifeStageFragment;
  accountDetails: AccountDetails;
};

export function shouldShowLifeStageSection(pet: GeneticStatsLifeStageFragment, isPublicProfile: boolean): boolean {
  // TODO: Show this section once we have age test data in the life stage algorithm.
  //       The text will just say Pending.
  if (!!pet.ageTest) {
    return false;
  }

  if (!!pet.ageTest && !pet.lifeStage) {
    return false;
  }

  if (!pet.showTraits && !pet.ageTest) {
    return false;
  }

  // Do not show Unknown or Pending life stage in the public profile.
  if (isPublicProfile && !pet.lifeStage) {
    return false;
  }

  return true;
}

export const GeneticStatsLifeStage: FC<GeneticStatsLifeStageProps> = (props) => {
  const { isPublicProfile, pet } = props;

  const isSmallScreen = useIsMobileScreen();

  if (!shouldShowLifeStageSection(pet, isPublicProfile)) {
    return null;
  }

  const lifeStageText = getLifeStateDisplayText(pet);

  return (
    <Stack direction="row" spacing={isSmallScreen ? 3 : 5}>
      <div className="geneticStats-lifeStageIconBorder">
        <div className="geneticStats-lifeStageIconContainer">
          <img
            className="geneticStats-sectionImage geneticStats-lifeStageIcon"
            src={buildEmbarkCDNUrl('/img/genetic-stats/icon-lifestage.svg')}
            alt=""
          />
        </div>
      </div>
      <Stack direction="column" spacing={0.5}>
        <div>
          <b className="geneticStatsSection-subSectionHeader">Life stage</b>
        </div>
        <div>
          <Typography variant="h6" component="span">
            {lifeStageText}
          </Typography>
        </div>
        {!isPublicProfile && (
          <div>
            <LifeStageText {...props} />
          </div>
        )}
        <div>
          <LifeStageCalculationModalButton />
        </div>
      </Stack>
    </Stack>
  );
};

const lifeStageToText: Record<PetLifeStage, string> = {
  [PetLifeStage.Puppy]: 'Puppy',
  [PetLifeStage.YoungAdult]: 'Young adult',
  [PetLifeStage.MatureAdult]: 'Mature adult',
  [PetLifeStage.Senior]: 'Senior',
  [PetLifeStage.Geriatric]: 'Geriatric',
};

function getLifeStateDisplayText(pet: GeneticStatsLifeStageFragment): string {
  if (!!pet.ageTest) {
    if (!pet.lifeStage) {
      return 'Pending';
    }
  }

  if (!pet.lifeStage) {
    return 'Help us calculate this';
  }

  return lifeStageToText[pet.lifeStage];
}

const AgeTestPurchaseLink: FC<GeneticStatsLifeStageProps> = (props) => {
  const { accountDetails, pet, children } = props;

  return (
    <a
      href={buildShopifyLink(accountDetails, 'products/dog-age')}
      {...getHeapDataAttributes({
        heapCategory: HeapCategory.GENETIC_STATS,
        heapAction: HeapAction.AGE_TEST_RECOMMENDATION,
        heapDetails: pet.lifeStage?.toString(),
      })}
      target="_blank"
      rel="noopener"
    >
      <b>{children}</b>
    </a>
  );
};

const AddABirthdayLink: FC<GeneticStatsLifeStageProps> = (props) => {
  const { pet, children } = props;

  return (
    <a
      href={`/members/pet/about/${pet.petNum}`}
      {...getHeapDataAttributes({
        heapCategory: HeapCategory.GENETIC_STATS,
        heapAction: 'Add a Birthday',
      })}
    >
      <b>{children}</b>
    </a>
  );
};

const LifeStageText: FC<GeneticStatsLifeStageProps> = (props) => {
  const { pet } = props;
  const hisOrHerText = hisOrHer(pet.sex);

  if (!!pet.ageTest) {
    if (!pet.lifeStage) {
      return (
        <Typography variant="body2">We'll update this as soon as {pet.name}'s Age Test results are ready.</Typography>
      );
    }
    if (!!pet.ageTest.releasedResult?.predictedBirthday) {
      return <Typography variant="body2">We've calculated this based on {pet.name}'s Age Test results.</Typography>;
    }

    return (
      <Typography variant="body2">We've calculated this based on the birthday you entered for {pet.name}.</Typography>
    );
  }

  if (!pet.ageTest) {
    if (!pet.lifeStage) {
      return (
        <Typography variant="body2">
          We need {pet.name}'s date of birth to figure this out.
          {pet.shouldShowAgeTestRecommendation && (
            <>
              {' '}
              <AddABirthdayLink {...props}>Add {hisOrHerText} birthday</AddABirthdayLink> if you know it. If not, try
              our <AgeTestPurchaseLink {...props}>Age Test</AgeTestPurchaseLink>.
            </>
          )}
          {!pet.shouldShowAgeTestRecommendation && (
            <>
              {' '}
              You can <AddABirthdayLink {...props}>add a birthday</AddABirthdayLink>.
            </>
          )}
        </Typography>
      );
    }

    if (!!pet.dateOfBirth) {
      if (pet.isDateOfBirthEstimated) {
        return (
          <Typography variant="body2">
            We've calculated this life stage based on the estimated birthday you entered for {pet.name}.{' '}
            {pet.shouldShowAgeTestRecommendation && (
              <>
                Not sure if it's accurate? Try our <AgeTestPurchaseLink {...props}>Age Test</AgeTestPurchaseLink>.
              </>
            )}
          </Typography>
        );
      } else {
        return (
          <Typography variant="body2">
            We've calculated this life stage based on the birthday you entered for {pet.name}.
          </Typography>
        );
      }
    }
  }

  // We should not hit this in practice once we have age test data and the shouldShowLifeStageSection function is updated.
  return null;
};
