import 'projects/legacyClient/src/react/pet/veterinaryInformation/editVeterinaryInformationModal.scss';

import { Button, Checkbox, Stack, TextField } from '@mui/material';
import { Controller, FormProvider } from 'react-hook-form';
import {
  LocationType,
  PetVeterinaryInformationFragment,
} from '@embark/shared-components/src/generated/graphql-client-types';

import { Dialog } from '@embark/shared-components/src/dialog/dialog';
import { FC } from 'react';
import { LocationAutocomplete } from '@embark/shared-components/src/locationAutocomplete/locationAutocomplete';
import { LocationAutocompleteResult } from '@embark/shared-components/src/locationAutocomplete/locationAutocompleteHooks';
import SearchIcon from '@mui/icons-material/Search';
import { parseLocationData } from 'projects/legacyClient/src/react/pet/location/petLocationModal';
import { useEditVetInformationModal } from 'projects/legacyClient/src/react/pet/veterinaryInformation/editVeterinaryInformationModalHooks';

export type EditVeterinaryInformationModalProps = {
  open: boolean;
  setModalOpen: () => void;
  embarkMayContactVet: PetVeterinaryInformationFragment['embarkMayContactVet'] | null;
  practiceLocation: PetVeterinaryInformationFragment['practiceLocation'] | null;
  petUuid: string;
};

export interface VetLocationForm {
  embarkMayContactVet: boolean | null;
  practiceLocation: LocationAutocompleteResult | null;
}

export const EditVeterinaryInformationModal: FC<EditVeterinaryInformationModalProps> = ({
  open,
  setModalOpen,
  embarkMayContactVet,
  practiceLocation,
  petUuid,
}) => {
  const { formInformation, handleSubmit, handleClose, placesServiceRef, updateVetPracticeDetails, onChangeAddress } =
    useEditVetInformationModal({ setModalOpen, embarkMayContactVet, practiceLocation });

  return (
    <Dialog aria-labelledby="add-a-patient-header" open={open} onClose={handleClose}>
      <Stack spacing={2}>
        <div className="text-center small-10 small-offset-1">
          <div className="app-top-bar-title">Edit Veterinary Information</div>
        </div>
        <FormProvider {...formInformation}>
          <form>
            <Stack spacing={2}>
              <div>
                <label className="small-caps" htmlFor="practiceLocationAutocompete">
                  Practice Name
                </label>
                <Controller
                  name="practiceLocation"
                  render={({ field }) => {
                    return (
                      <LocationAutocomplete
                        id="practiceLocationAutocompete"
                        className="locationAutocompeteInput"
                        onChange={(event, newValue, reason, details) => {
                          field.onChange(newValue);

                          if (!!onChangeAddress) {
                            onChangeAddress(event, newValue, reason, details);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} label="What is the name of your vet clinic?" />}
                        value={field?.value ?? null}
                        popupIcon={<SearchIcon />}
                      />
                    );
                  }}
                />
              </div>
              <div ref={placesServiceRef} />
              <section>
                <Controller
                  name="embarkMayContactVet"
                  render={({ field }) => (
                    <Checkbox
                      name="embarkMayContactVet"
                      aria-label="embarkMayContactVet checkbox"
                      onChange={(event) => field.onChange(event.target.checked)}
                      checked={!!field.value}
                    />
                  )}
                />
                <p className="disclose-vet-text">
                  Yes, Embark may discuss this dog’s results with my veterinarian. (Embark will not share this
                  information with third parties. See our{' '}
                  <a href="https://embarkvet.com/tos/" target="_blank" rel="noopener noreferrer">
                    Terms of Service.
                  </a>
                  )
                </p>
              </section>
              <section className="cell space-below-xl-small-only grid-x grid-padding-y align-center">
                <Button
                  className="button-primary cell small-12 medium-4 medium-offset-2 medium-order-2"
                  onClick={handleSubmit(async (data) => {
                    const parsedPracticeLocation = parseLocationData(LocationType.Practice, data.practiceLocation);
                    await updateVetPracticeDetails({
                      variables: {
                        vetPracticeDetails: {
                          petUuid: petUuid,
                          embarkMayContactVet: parsedPracticeLocation ? data.embarkMayContactVet : null,
                          practiceLocation: parsedPracticeLocation ?? null,
                        },
                      },
                    });
                  })}
                >
                  Save
                </Button>
                <Button
                  className="button-text-link cell small-12 medium-4 medium-order-1 space-above-sm-only"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </section>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Dialog>
  );
};
