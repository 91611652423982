export function getDataAttributeOrUndefined(htmlElement: HTMLElement | null, dataName: string): string | undefined {
  const htmlElementData = htmlElement?.dataset ?? {};
  return htmlElementData[dataName] ?? undefined;
}

export function getDataAttributeOrThrow(htmlElement: HTMLElement | null, dataName: string): string {
  const htmlElementData = htmlElement?.dataset ?? {};
  const data = htmlElementData[dataName];
  if (!data) {
    const attributeName = dataName.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase());
    throw new Error(`data-${attributeName} attribute required for ${htmlElement?.id}`);
  }
  return data;
}
