export enum AboutPetCardModalId {
  DETAILS = 'detailsModal',
  LOCATION = 'locationModal',
  NAME_AND_STORY = 'nameAndStoryModal',
  VETERINARY_INFORMATION = 'veterinaryInformationModal',
  VETERINARY_INFORMATION_RESET = 'veterinaryInformationResetModal',
  SOCIAL_MEDIA = 'socialMediaModal',
  PRIVACY_SINGLE = 'privacySingleModal',
  PRIVACY_DEFAULT = 'privacyDefaultModal',
  BREEDING_INFORMATION = 'breedingInformationModal',
  HIDE_PET = 'hidePetModal',
  DELETE_PET = 'deletePetModal',
  PASSED_AWAY_PET = 'passedAwayPetModal',
}
