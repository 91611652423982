import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { BackToMyDogsLink } from '@embark/shared-components/src/pet/navigation/backToMyDogsLink';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { useAppSelector } from 'projects/legacyClient/src/react/state/hooks';

export function initBackToMyDogsLink(): void {
  ReactDOM.render(
    <AppProviders>
      <BackToMyDogsLinkContainer />
    </AppProviders>,
    document.getElementById('backToMyDogsLink')
  );
}

export const BackToMyDogsLinkContainer: FC = () => {
  const petCount: number = window['petCount'];
  const accountDetails = useAppSelector((state) => state.accountDetails);
  return <BackToMyDogsLink accountDetails={accountDetails} petCount={petCount} />;
};
