<template>
  <a :href="`/dog/${pet.handle}`">{{ pet.name }}</a>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['pet'],
});
</script>
