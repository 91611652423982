import {
  GooglePlacesAutocompletePrediction,
  LocationAutocompleteResult,
} from '@embark/shared-components/src/locationAutocomplete/locationAutocompleteHooks';
import {
  PetLocationInformationFragment,
  UpdatePetLocationsMutationFn,
  useUpdatePetLocationsMutation,
} from '@embark/shared-components/src/generated/graphql-client-types';
import { UseFormHandleSubmit, UseFormReturn, useForm } from 'react-hook-form';

import { GOOGLE_MAPS_API_KEY } from 'projects/legacyClient/src/js/browser-api-keys';
import { Loader } from '@googlemaps/js-api-loader';
import { LocationAutocompleteProps } from '@embark/shared-components/src/locationAutocomplete/locationAutocomplete';
import { showCommonErrorSnackbar } from '@embark/shared-components/src/services/snackbar/snackbar';
import { useFetchGoogleLocationPrediction } from '@embark/shared-components/src/locationAutocomplete/locationAutocompleteHooks';
import { useRef } from 'react';
import { useSnackbar } from 'notistack';

type UsePetLocationFormInputReturn = {
  onChangeLiveAddress: LocationAutocompleteProps['onChange'];
  onChangeFromAddress: LocationAutocompleteProps['onChange'];
  onChangeBornAddress: LocationAutocompleteProps['onChange'];
};

type UsePetLocationProps = {
  toggleModal: () => void;
  locations: PetLocationInformationFragment['locations'];
  open: boolean;
};

export interface PetLocationForm {
  liveLocation: LocationAutocompleteResult | null;
  bornLocation: LocationAutocompleteResult | null;
  fromLocation: LocationAutocompleteResult | null;
}

type UsePetLocationReturn = {
  formInformation: UseFormReturn<PetLocationForm, object>;
  handleSubmit: UseFormHandleSubmit<PetLocationForm>;
  handleClose: () => void;
  placesServiceRef: React.RefObject<HTMLDivElement>;
  updatePetLocationsMutation: UpdatePetLocationsMutationFn;
  onChangeLiveAddress: LocationAutocompleteProps['onChange'];
  onChangeFromAddress: LocationAutocompleteProps['onChange'];
  onChangeBornAddress: LocationAutocompleteProps['onChange'];
};

type PetLocationFormKeys = keyof PetLocationForm;
export function usePetLocationModal(props: UsePetLocationProps): UsePetLocationReturn {
  const { toggleModal, locations, open } = props;
  const { enqueueSnackbar } = useSnackbar();

  const { data: liveOptions } = useFetchGoogleLocationPrediction(locations.live?.addressInput ?? undefined);
  const { data: fromOptions } = useFetchGoogleLocationPrediction(locations.from?.addressInput ?? undefined);
  const { data: bornOptions } = useFetchGoogleLocationPrediction(locations.born?.addressInput ?? undefined);

  const [updatePetLocationsMutation, {}] = useUpdatePetLocationsMutation({
    onError: () => {
      showCommonErrorSnackbar(enqueueSnackbar);
    },
    onCompleted: () => {
      toggleModal();
      location.reload();
    },
  });
  const placesServiceRef = useRef<HTMLDivElement>(null);

  const handleClose = async (): Promise<void> => {
    toggleModal();
  };
  const formInformation = useForm<PetLocationForm>({
    mode: 'onChange',
    defaultValues: {
      liveLocation: liveOptions[0],
      fromLocation: fromOptions[0],
      bornLocation: bornOptions[0],
    },
  });

  const { setValue, handleSubmit, getValues } = formInformation;
  if (open) {
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      libraries: ['places'],
      retries: 3,
    });

    loader
      .load()
      .then((google) => {
        function setLocationValueToGooglePlacesResult(
          formKey: PetLocationFormKeys,
          locationData: GooglePlacesAutocompletePrediction
        ): void {
          const PlacesService = new google.maps.places.PlacesService(placesServiceRef.current as HTMLDivElement);
          PlacesService?.getDetails({ placeId: locationData.place_id }, (result) => {
            if (!getValues(formKey)?.details) {
              setValue(formKey, { ...locationData, details: result });
            }
          });
        }

        if (!!liveOptions[0] && !getValues('liveLocation')?.details) {
          setLocationValueToGooglePlacesResult('liveLocation', liveOptions[0]);
        }
        if (!!fromOptions[0] && !getValues('fromLocation')?.details) {
          setLocationValueToGooglePlacesResult('fromLocation', fromOptions[0]);
        }
        if (!!bornOptions[0] && !getValues('bornLocation')?.details) {
          setLocationValueToGooglePlacesResult('bornLocation', bornOptions[0]);
        }
      })
      .catch(() => {
        return;
      });
  }

  function useAddressFormInput(): UsePetLocationFormInputReturn {
    const onChangeLiveAddress: LocationAutocompleteProps['onChange'] = (_event, newValue): void => {
      if (newValue) {
        setValue('liveLocation', newValue);
      }
    };

    const onChangeFromAddress: LocationAutocompleteProps['onChange'] = (_event, newValue): void => {
      if (newValue) {
        setValue('fromLocation', newValue);
      }
    };

    const onChangeBornAddress: LocationAutocompleteProps['onChange'] = (_event, newValue): void => {
      if (newValue) {
        setValue('bornLocation', newValue);
      }
    };

    return {
      onChangeLiveAddress,
      onChangeFromAddress,
      onChangeBornAddress,
    };
  }

  const { onChangeLiveAddress, onChangeFromAddress, onChangeBornAddress } = useAddressFormInput();

  return {
    formInformation,
    handleSubmit,
    handleClose,
    placesServiceRef,
    updatePetLocationsMutation,
    onChangeLiveAddress,
    onChangeFromAddress,
    onChangeBornAddress,
  };
}
