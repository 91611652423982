import { GOOGLE_MAPS_API_KEY } from './browser-api-keys';
import { HeapCustomEvent } from 'clientServerShared/types/eventTracking';
import { Loader } from '@googlemaps/js-api-loader';
import { trackHeapEvent } from '@embark/shared-components/src/services/eventTracking/eventTracking';

export async function init(): Promise<void> {
  const loader = new Loader({
    apiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
    retries: 3,
  });

  try {
    (await loader.load()).maps;
    trackHeapEvent(HeapCustomEvent.GOOGLE_MAPS_API_LOAD, {
      loadingContext: 'legacyClient JS Maps init',
    });
  } catch (e) {
    throw new Error(`Could not load Google Maps after 3 tries: ${e}`);
  }
}
