import 'projects/sharedComponents/src/feedback/feedbackDialogConfirmation.scss';

import { Link, Stack, Typography } from '@mui/material';

import { FC } from 'react';

type FeedbackDialogConfirmationProps = {
  isHelpful: boolean;
  helpfulText?: string | null;
  unhelpfulText?: string | null;
};

const defaultHelpfulText =
  'We’re glad this page is helpful for you and your dog. With your input, we’ll continue to deliver relevant, valuable content.';
const defaultUnhelpfulText =
  'We want this page to help you make the best care decisions for your dog. With your input, we’ll work to improve this page to deliver better, more relevant content.';

export const FeedbackDialogConfirmation: FC<FeedbackDialogConfirmationProps> = ({
  isHelpful,
  helpfulText,
  unhelpfulText,
}) => {
  return (
    <Stack spacing={1}>
      <Typography>{isHelpful ? helpfulText ?? defaultHelpfulText : unhelpfulText ?? defaultUnhelpfulText}</Typography>
      <Typography>
        Have any questions?{' '}
        <Link className="feedbackDialogConfirmation-link" href="https://help.embarkvet.com/hc/en-us/requests/new">
          Contact customer service
        </Link>
      </Typography>
    </Stack>
  );
};
