import {
  GTMPetDataLayer,
  GTMUserDataLayer,
  HeapAction,
  HeapCategory,
  HeapMetadata,
} from 'clientServerShared/types/eventTracking';
import { HeapCustomEvent, PetTrackingFields } from 'clientServerShared/types/eventTracking';
import { useEffect, useState } from 'react';

import { AccountDetails } from 'clientServerShared/types/account';
import { PetTrackingFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { captureException } from '@sentry/react';
import { parseISO } from 'date-fns';
import { useEffectOnce } from 'react-use';

type HeapProperties = Record<string, string | number | boolean | null | undefined>;

// Lifted from Heap's docs, may fall out of date: https://developers.heap.io/reference/client-side-apis-overview
export interface HeapClient {
  track: (event: string, properties?: HeapProperties) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: HeapProperties) => void;
  addEventProperties: (properties: HeapProperties) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

export type HeapDataAttributes = {
  'data-heap-category'?: HeapCategory;
  'data-heap-action'?: HeapAction | string;
  'data-heap-details'?: string;
  'data-heap-value'?: string;
};

export function getHeapDataAttributes(heapMetadata?: HeapMetadata): HeapDataAttributes | undefined {
  if (!heapMetadata) {
    return undefined;
  }

  const output: HeapDataAttributes = {};

  if (!!heapMetadata.heapAction) {
    output['data-heap-action'] = heapMetadata.heapAction;
  }

  if (!!heapMetadata.heapCategory) {
    output['data-heap-category'] = heapMetadata.heapCategory;
  }

  if (!!heapMetadata.heapDetails) {
    output['data-heap-details'] = heapMetadata.heapDetails;
  }

  if (heapMetadata.heapValue !== undefined) {
    output['data-heap-value'] = `${heapMetadata.heapValue}`;
  }
  return output;
}

type UseTrackPageviewReturn = {
  registerPageview: (properties?: HeapProperties) => void;
};

export function useTrackPageview(): UseTrackPageviewReturn {
  const [hasSentPageview, setHasSentPageview] = useState(false);
  const registerPageview = (properties?: HeapProperties): void => {
    if (!hasSentPageview) {
      registerHeapPageview(properties);
      setHasSentPageview(true);
    }
  };

  return { registerPageview };
}

export function registerHeapPageview(properties?: HeapProperties): void {
  trackHeapEvent(HeapCustomEvent.PAGE_VIEW, properties);
}

/**
 * Tracks a page view for a client-side rendered page.
 * This hook should be called in the page itself, not the parent route.
 */
export function useTrackReactRouterPageView(properties?: HeapProperties): void {
  // Only fire this once and we need a short timeout to avoid Heap getting the previous URL instead of the current URL.
  useEffectOnce(() => {
    const timeout = setTimeout(() => {
      registerHeapPageview(properties);
    }, 25);

    // Clean up the timer if the component is destroyed before it fires.
    return () => {
      clearTimeout(timeout);
    };
  });
}

export function setGTMUserDataLayer(user: AccountDetails['user']): void {
  if (!!user) {
    const userDataLayer: GTMUserDataLayer = {
      name: user.name,
      email: user.email,
      customerType: user.permissions.isBreeder ? 'breeders' : 'nonbreeders',
    };

    window.dataLayer.push(userDataLayer);
  }
}

export function setGTMPetDataLayer(pet: PetTrackingFields): void {
  if (!!pet) {
    const petDataLayer: GTMPetDataLayer = { pet };
    window.dataLayer.push(petDataLayer);
  }
}

export function useGTMPetDataLayer(pet?: PetTrackingFragment): void {
  useEffect(() => {
    if (!!pet) {
      setGTMPetDataLayer(extractPetTrackingFields(pet));
    }
  }, [pet]);
}

export function extractPetTrackingFields(pet?: PetTrackingFragment): PetTrackingFields {
  if (!!pet) {
    return {
      petNum: pet.petNum,
      swabState: pet.swabSummary?.swabState,
      productType: pet.swabSummary?.productType,
      productPurchaseSource: pet.swabSummary?.productPurchaseSource,
      dateActivated: getISOStringFromDateString(pet.swabSummary?.activatedDate),
      dateUpgraded: getISOStringFromDateString(pet.swabSummary?.comprehensiveUpgradePurchasedDate),
      healthResultsReleased: !!pet.swabSummary?.healthResultsReleasedDate,
      dateHealthResultsReleased: getISOStringFromDateString(pet.swabSummary?.healthResultsReleasedDate),
      breedResultsReleased: !!pet.swabSummary?.breedResultsReleasedDate,
      dateBreedResultsReleased: getISOStringFromDateString(pet.swabSummary?.breedResultsReleasedDate),
      breedType: pet.genotype?.breedType,
      primaryBreed: pet.genotype?.breed1name,
      primaryBreedPercent: pet.genotype?.breed1pct,
    };
  }
  return {};
}

function getISOStringFromDateString(dateString?: string | null): string | undefined {
  if (!!dateString) {
    const date = parseISO(dateString);
    return date.toISOString();
  }

  return;
}

export function trackHeapEvent(event: HeapCustomEvent, properties?: HeapProperties): void {
  try {
    window.heap?.track(event, properties);
  } catch (err) {
    const message = `Failed to track Heap event ${event}`;
    captureException(err, { extra: { message, properties } });
    console.error(message);
  }
}
