import 'projects/legacyClient/src/react/pet/veterinaryInformation/editVeterinaryInformationModal.scss';

import { Button, Stack, TextField } from '@mui/material';
import { Controller, FormProvider } from 'react-hook-form';
import {
  LocationDetails as GraphQLLocationDetails,
  LocationType,
  PetLocationInformationFragment,
} from '@embark/shared-components/src/generated/graphql-client-types';

import { Dialog } from '@embark/shared-components/src/dialog/dialog';
import { FC } from 'react';
import { GooglePlacesAutocompletePrediction } from '@embark/shared-components/src/locationAutocomplete/locationAutocompleteHooks';
import { LocationAutocomplete } from '@embark/shared-components/src/locationAutocomplete/locationAutocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { mapLocation } from 'projects/frontEnd/src/pages/addADog/addADogStepper/createDogHook';
import { usePetLocationModal } from 'projects/legacyClient/src/react/pet/location/petLocationModalHooks';
import { usePetUuid } from 'projects/legacyClient/src/react/hooks/usePetUuid';

export type EditPetLocationModalProps = {
  open: boolean;
  toggleModal: () => void;
  locations: PetLocationInformationFragment['locations'];
};

export const EditPetLocationModal: FC<EditPetLocationModalProps> = ({ open, toggleModal, locations }) => {
  const { petUuid } = usePetUuid();
  const {
    formInformation,
    handleSubmit,
    handleClose,
    placesServiceRef,
    updatePetLocationsMutation,
    onChangeLiveAddress,
    onChangeFromAddress,
    onChangeBornAddress,
  } = usePetLocationModal({ toggleModal, locations, open });

  return (
    <Dialog aria-labelledby="add-a-patient-header" open={open} onClose={handleClose}>
      <Stack spacing={2}>
        <div className="text-center small-10 small-offset-1">
          <div className="app-top-bar-title">Edit Location</div>
        </div>
        <p className="text-dark-gray edit-page-descriptive">
          We will not display complete addresses on your dog's public profile page. This information will help us to
          highlight other Embark dogs that may be of interest to you.
        </p>
        <FormProvider {...formInformation}>
          <form>
            <Stack spacing={2}>
              <div>
                <label htmlFor="liveLocationAutocompete">Where does this dog currently live?</label>
                <Controller
                  name="liveLocation"
                  render={({ field }) => {
                    return (
                      <LocationAutocomplete
                        id="liveLocationAutocompete"
                        className="locationAutocompeteInput"
                        onChange={(event, newValue, reason, details) => {
                          field.onChange(newValue);

                          if (!!onChangeLiveAddress) {
                            onChangeLiveAddress(event, newValue, reason, details);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        value={field?.value ?? null}
                        popupIcon={<SearchIcon />}
                      />
                    );
                  }}
                />
              </div>
              <div>
                <label htmlFor="fromLocationAutocompete">Where did you get your dog from?</label>
                <Controller
                  name="fromLocation"
                  render={({ field }) => {
                    return (
                      <LocationAutocomplete
                        id="fromLocationAutocompete"
                        className="locationAutocompeteInput"
                        onChange={(event, newValue, reason, details) => {
                          field.onChange(newValue);

                          if (!!onChangeFromAddress) {
                            onChangeFromAddress(event, newValue, reason, details);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        value={field?.value ?? null}
                        popupIcon={<SearchIcon />}
                      />
                    );
                  }}
                />
              </div>
              <div>
                <label htmlFor="bornLocationAutocompete">Where was your dog born?</label>
                <Controller
                  name="bornLocation"
                  render={({ field }) => {
                    return (
                      <LocationAutocomplete
                        id="bornLocationAutocompete"
                        className="locationAutocompeteInput"
                        onChange={(event, newValue, reason, details) => {
                          field.onChange(newValue);

                          if (!!onChangeBornAddress) {
                            onChangeBornAddress(event, newValue, reason, details);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        value={field?.value ?? null}
                        popupIcon={<SearchIcon />}
                      />
                    );
                  }}
                />
              </div>
              <div ref={placesServiceRef} />
              <section className="cell space-below-xl-small-only grid-x grid-padding-y align-center">
                <Button
                  className="button-primary cell small-12 medium-4 medium-offset-2 medium-order-2"
                  onClick={handleSubmit(async (data) => {
                    if (!petUuid) {
                      return;
                    }
                    await updatePetLocationsMutation({
                      variables: {
                        petLocations: {
                          petUuid: petUuid,
                          locations: {
                            live: parseLocationData(LocationType.Live, data.liveLocation),
                            from: parseLocationData(LocationType.From, data.fromLocation),
                            born: parseLocationData(LocationType.Born, data.bornLocation),
                          },
                        },
                      },
                    });
                  })}
                >
                  Save
                </Button>
                <Button
                  className="button-text-link cell small-12 medium-4 medium-order-1 space-above-sm-only"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </section>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Dialog>
  );
};

export function parseLocationData(
  type: LocationType,
  location: GooglePlacesAutocompletePrediction | null
): GraphQLLocationDetails | undefined | null {
  const mappedPracticeLocation = mapLocation({
    locationType: type,
    googleLocationDetails: location,
  });
  return mappedPracticeLocation;
}
