import { DOCUMENT, IMAGE, VIDEO } from '../../../../clientServerShared/cloudinary';
import {
  SimplePublicIdResponse,
  changeProfilePic,
  checkPermissions,
  deleteMedia,
  mediaUploadHandler,
  rotateMedia,
} from './cloudinary';
import { head, last } from 'lodash';

import { getCsrfToken } from './csrf';
import { httpPost } from './ajax-helper';
import { log } from './browserLogger';

declare const global: any;
declare const window: any;

// used in setActiveSlide fn. When setting the active slide for videos
// we look into the html video tag of the slide where as for images we look in img.gallery-image
const mediaLookUpElem = {
  image: 'img.gallery-image',
  video: 'video',
};

// used for assigning correct folder string to cloudinary publicId's
const mediaLookupCloudinaryFolder = {
  image: 'ui',
  video: 'uv',
};

// these are html attributes that contain cloudinary urls with public_id
const mediaLookUpAttr = {
  image: 'src',
  video: 'poster',
};

interface SlideMediaAttrs {
  publicId: string;
  url: string;
}

interface UpdateThumbnailOpts {
  mediaType: string;
  slidePublicId: string; // current selected carousel slide publicId, that should match one in gallery thumbnail
  galleryUrl?: string; // new url for gallery thumbnail
  deleteThumbnail?: boolean;
}

// make orbit slider global mutable variable so that we can re-use this same instance in other places
let orbitSlider;

// for getting media public-id i.e string before 'ui' with in image or video url
const getPublicId = (mediaType: string, url: string): string => {
  const rawPublicId = last(url.split('/'));
  if (!rawPublicId) {
    throw new Error(`supplied wrong url ${url}`);
  }
  // remove extensions on public-ids for videos
  // we use the poster src value which has an image extension for obtaining a video publicId
  const publicId = mediaType === VIDEO ? head(rawPublicId.split('.')) : rawPublicId;
  return `${mediaLookupCloudinaryFolder[mediaType]}/${publicId}`;
};

const getSlideMedia = (mediaType: string, $slide: JQuery<HTMLElement>): SlideMediaAttrs => {
  const attrName: string = mediaLookUpAttr[mediaType];
  // the slide src value for images or poster value for videos, which is a url to the media
  const mediaElem = $slide.find(mediaLookUpElem[mediaType]);
  const url = mediaElem.attr(attrName);
  if (!url) {
    throw new Error('Slide Element has no child with a media url');
  }
  const publicId = getPublicId(mediaType, url);
  return { publicId, url };
};

const matchThumbnailToSlide = (
  thumbnailPublicId: string,
  mediaType: string,
  slides: JQuery<HTMLElement>
): JQuery<HTMLElement> | undefined => {
  for (let i = 0; i < slides.length; i++) {
    const $slide = $(slides[i]);
    const { publicId } = getSlideMedia(mediaType, $slide);
    if (publicId === thumbnailPublicId) {
      return $slide as JQuery<HTMLElement>;
    }
  }

  return undefined;
};

// once you click an image thumbnail we want the orbit slider to open up with it as first slide
const setActiveSlide = (): void => {
  // click handler on gallery thumbnails
  $('#gallery-container').on('click', '.gallery-dog-card', function (this: JQuery<HTMLElement>) {
    // the selected clicked on picture thumbnail
    const galleryThumbnailSrc: string | undefined = $(this).attr('src');
    if (!galleryThumbnailSrc) {
      return;
    }
    // we want to obtain the cloudinary public_Id's of both gallery thumbnails
    // and the slides. This enables later to match selected thumbnail to its equivalent slide
    // such that we can open the slide with clicked on image thumbnail
    const mediaType = galleryThumbnailSrc.includes('video') ? VIDEO : IMAGE;
    const thumbnailPublicId = getPublicId(mediaType, galleryThumbnailSrc);
    // we will iterate through carousel slides so that we match with a thumbnail
    // slides in the carousels are li's, they keep changing, so we need a fresh list
    const slides = mediaType === IMAGE ? $('.gallery-carousel .image-scroll') : $('.gallery-carousel .video-scroll');
    // slider instance
    orbitSlider = new global.Foundation.Orbit($(`#orbit-ul-${mediaType}`), {
      autoPlay: false,
    });

    if (!thumbnailPublicId) {
      return;
    }
    // matchThumbnail to equivalent slide in orbit slider
    const matchingSlide = matchThumbnailToSlide(thumbnailPublicId, mediaType, slides);
    // move orbitSlider to the thumbnail matching slide
    orbitSlider.changeSlide(false, matchingSlide);
  });
};

export const updateThumbnail = (opts: UpdateThumbnailOpts): void => {
  const { deleteThumbnail, slidePublicId, mediaType, galleryUrl } = opts;
  $(`.my-dogs-${mediaType}s .gallery-dog-card`).each((_index, elm) => {
    const currentThumbnailUrl = $(elm).attr('src');
    if (!currentThumbnailUrl) {
      return;
    }
    const publicId = getPublicId(mediaType, currentThumbnailUrl);
    if (!publicId) {
      throw new Error('galleryThumbnail missing src attribute');
    }
    if (publicId !== slidePublicId) {
      return;
    }
    if (galleryUrl) {
      $(elm).attr('src', galleryUrl);
    }
    if (deleteThumbnail) {
      $(elm).remove();
    }
  });
};

const deleteGalleryMedia = async (mediaType: string): Promise<void> => {
  $(`.${mediaType}_delete`).click(async function () {
    // will redirect to a page with error flash card
    const hasPermissions = checkPermissions();
    if (!hasPermissions) {
      return;
    }
    // get current active slide
    const $currentSlide = $(this).closest('.orbit-slide');
    // obtain media public-id and url from current slide
    const { publicId } = getSlideMedia(mediaType, $currentSlide);
    // show spinner
    $('.gallery-modal-spinner').show();
    // ajax request
    const { success } = await deleteMedia(mediaType, publicId);
    $('.gallery-modal-spinner').hide();
    if (!success) {
      throw new Error('failed to delete media');
    } // maybe we should be logging such errors somewhere
    // delete corresponding thumbnail
    updateThumbnail({
      mediaType,
      slidePublicId: publicId,
      deleteThumbnail: true,
    });
    // delete the slide
    $currentSlide.remove();
    // re-initializing orbit to get rid of the blank void / space left by deleting a slider element
    // has been a pointless chase. Reloading the browser for now works as a quick fix
    window.location.reload(false);
  });
};

const rotateGalleryMedia = async (mediaType: string): Promise<void> => {
  $(`.${mediaType}_rotate`).click(async function () {
    // will redirect to a page with error flash card
    const hasPermissions = checkPermissions();
    if (!hasPermissions) {
      return;
    }
    // get current active slide
    const $currentSlide = $(this).closest('.orbit-slide');
    // obtain media public-id and url from current slide
    const { publicId } = getSlideMedia(mediaType, $currentSlide);
    // show spinner
    $('.gallery-modal-spinner').show();
    // update image or video rotate angle
    const { tag, carouselUrl, galleryUrl } = await rotateMedia(mediaType, publicId);
    $('.gallery-modal-spinner').hide();
    // updates media thumbnail of current slide
    updateThumbnail({ mediaType, slidePublicId: publicId, galleryUrl });
    // change rotation angle in slide media url
    // update media url with new one with in the slide
    if (mediaType === IMAGE) {
      // if rotated image is also profile picture, reload all page
      const $currentProfilePic = $('img.dog-pic-md-round');
      if ($currentProfilePic.length) {
        // make sure we have an image tag
        const picSrc = $currentProfilePic.attr('src');
        if (!picSrc) {
          return;
        }
        const profilePicPublicId = getPublicId(IMAGE, picSrc);
        // reload all page such that profile pic also is rendered as rotated
        if (profilePicPublicId === publicId) {
          return window.location.reload(false);
        }
      }
      return $currentSlide.find('img.gallery-image').attr('src', carouselUrl);
    }
    // change video slide html such that its the new one with updated rotation angle
    return tag && $currentSlide.children('section').html(tag);
  });
};

// when you click on a gallery thumbnail, a light box containing the thumbnail shows up.
// with in this thumbnail, you have an option for setting that current image as profile picture
// this function basically calls an API that sets an image as profile picture
const addSetProfilePictureHandler = (): void => {
  $('.gallery_set_profile_picture').click(async function () {
    // will redirect to a page with error flash card
    const hasPermissions = checkPermissions();
    if (!hasPermissions) {
      return;
    }
    // get current active slide, which corresponds to clicked thumbnail we want to set as profile pic
    const $currentSlide = $(this).closest('.orbit-slide');
    // obtain media public-id and url from current slide
    const { publicId } = getSlideMedia(IMAGE, $currentSlide);
    $('.gallery-modal-spinner').show();
    // set new profile picture server side and wait for new profile picture urls
    const { success } = await changeProfilePic(publicId, 'EDIT');
    if (!success) {
      $('.gallery-modal-spinner').hide();
      return alert('Failed to set picture as profile picture');
    }
    // leave spinner in place until page reloads
    window.location.reload();
  });
};

const addUnsetProfilePictureHandlerHandler = (): void => {
  $('.gallery_un_set_profile_picture').click(async function () {
    // will redirect to a page with error flash card if user doesn't have appropriate permissions
    const hasPermissions = checkPermissions();
    if (!hasPermissions) {
      return;
    }
    // get current active slide, which corresponds to clicked thumbnail we want to set as profile pic
    const $currentSlide = $(this).closest('.orbit-slide');
    // obtain media public-id and url from current slide
    const { publicId } = getSlideMedia(IMAGE, $currentSlide);
    $('.gallery-modal-spinner').show();
    // set new profile picture server side and wait for new profile picture urls
    const { success } = await changeProfilePic(publicId, 'DELETE');
    if (!success) {
      $('.gallery-modal-spinner').hide();
      return alert('Failed to unset picture as profile picture');
    }
    // leave spinner in place until page reloads
    window.location.reload();
  });
};

// each pet profile picture is also a gallery image
// for the gallery image that is the profile picture we want to add an unset button
// such that we can be able to un-set it as current profile picture when required
const addUnSetButton = (): void => {
  // get current profile picture public_id
  const $currentProfilePicture = $('.dog-pic-md-round');
  const url = $currentProfilePicture.attr('src');
  if (!url) {
    return;
  } // possibly we dont have a profile pic
  const publicId = getPublicId(IMAGE, url);
  const $slides = $('.gallery-carousel .image-scroll');
  const $matchingSlide = matchThumbnailToSlide(publicId, IMAGE, $slides);
  if (!$matchingSlide) {
    log.error(`Cannot find slide for profile pic "${publicId}"`);
    return;
  }
  $matchingSlide.find('.gallery_un_set_profile_picture').show();
  $matchingSlide.find('.gallery_set_profile_picture').hide();
};

// Replaces p-tag with caption with a text-area element with same caption
// hides caption edit button
const toggleCaptionUpdateState = ($currentSlide: JQuery<HTMLElement>): void => {
  $currentSlide.find('.caption-text-area').toggle();
  $currentSlide.find('.caption-display-container').toggle();
};

const updateCaption = (publicId: string, caption: string, mediaType: string): Promise<SimplePublicIdResponse> => {
  if (!window.petNum) {
    throw new Error('petNum missing on window object');
  }
  const reqPayload = {
    dataType: 'json',
    _csrf: getCsrfToken(),
    data: JSON.stringify({
      caption,
      petNum: +window.petNum,
      publicId,
      mediaType,
    }),
  };
  return httpPost<SimplePublicIdResponse>('/members/update-caption', reqPayload);
};

const addUpdateCaptionHandlers = (): void => {
  // click anywhere on the existing caption or the edit button to begin editing the caption
  $('.caption-display-container').click(function () {
    const $currentSlide = $(this).closest('.orbit-slide');
    toggleCaptionUpdateState($currentSlide);
  });
  $('.submit-caption').click(async function () {
    const $currentSlide = $(this).closest('.orbit-slide');
    const newCaptionContent = $currentSlide.find('textarea.caption-entry').val() as string;
    // for getting public_id of the image that the caption update is for
    const mediaType = $currentSlide.data('media-type');
    if (!mediaType) {
      throw Error('media-type attribute missing on slide');
    }
    const { publicId } = getSlideMedia(mediaType, $currentSlide);
    // will redirect to a page with error flash card
    const hasPermissions = checkPermissions();
    if (!hasPermissions) {
      return;
    }
    // if we didn't update caption on the server send show an error
    $('.gallery-modal-spinner').show();
    const { success } = await updateCaption(publicId, newCaptionContent, mediaType);
    $('.gallery-modal-spinner').hide();
    if (!success) {
      return alert('Failed to update caption, please try again');
    }
    // if succeeded, make ui updates
    $currentSlide.find('.caption-display').text(newCaptionContent || 'Add a caption');
    toggleCaptionUpdateState($currentSlide);
  });
};

function addStopVideoPlayingHandlers(): void {
  // without this, video keeps playing when you scroll or close the carousel
  function stopAllVideos(event): void {
    if (event.target === event.currentTarget) {
      // In some browsers (e.g. Safari) clicks on the video bubble up to the reveal-overlay element, so we need to make sure
      // we only respond to clicks on the specific elements with the click handlers, and not to events bubbled up from their descendants
      $('video').each(function () {
        const video = this as HTMLMediaElement;
        video.pause();
      });
    }
  }
  // need to target the specific elements that will be clicked, including the span that has the 'X' within .close-button
  $('.orbit-previous, .orbit-next, .close-button, .close-button span, .reveal-overlay').click(stopAllVideos);
}

export const init = (): void => {
  // handles uploading images to gallery
  mediaUploadHandler({
    mediaType: IMAGE,
    uploadBtnDomStr: '#image-upload',
  });

  // handles uploading videos to gallery
  mediaUploadHandler({
    mediaType: VIDEO,
    uploadBtnDomStr: '#video-upload',
  });

  // handles uploading documents to gallery
  mediaUploadHandler({
    mediaType: DOCUMENT,
    uploadBtnDomStr: '#document-upload',
  });

  // set active slide in orbit carousel slider following clicking on a gallery image
  setActiveSlide();

  // delete Gallery media
  void deleteGalleryMedia(VIDEO);
  void deleteGalleryMedia(IMAGE);

  // rotate gallery media
  void rotateGalleryMedia(IMAGE);
  void rotateGalleryMedia(VIDEO);

  // set new profile picture
  addSetProfilePictureHandler();

  // adds an unset button to gallery image which is currently profile picture.
  addUnSetButton();
  // unset current profile picture
  addUnsetProfilePictureHandlerHandler();
  // for updating captions
  addUpdateCaptionHandlers();

  addStopVideoPlayingHandlers();
};
