import { ConfigTypes, getConfig } from 'projects/sharedComponents/src/services/getConfig/getConfig';
import { UrlPathPrefix, buildMyEvLegacyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';

export function redirectToLegacySite(path: string): void {
  const baseURL = getConfig(ConfigTypes.MY_EV_LEGACY_URL);
  window.location.replace(`${baseURL}${path}`);
}

export function navigateToLegacySite(path: UrlPathPrefix): void {
  window.location.href = buildMyEvLegacyLink(path);
}

export function redirectTo(url: string): void {
  window.location.href = url;
}
