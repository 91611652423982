import { AccountDetails } from 'clientServerShared/types/account';
import { buildMyEvLegacyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';

type HeaderLinksProps = {
  chatBubbleAriaLabel: string;
  accountDetails: AccountDetails;
};

export const HeaderLinks: React.FC<HeaderLinksProps> = (props) => {
  const { chatBubbleAriaLabel, accountDetails } = props;

  const numUnreadMessages = accountDetails.user?.numUnreadMessages ?? 0;

  const isPetParent = !accountDetails.user?.permissions.isBreeder && !accountDetails.user?.permissions.isVet;

  return (
    <ul className="desktopHeader-menu">
      <li>
        <a
          href={buildMyEvLegacyLink('/members/messages')}
          className="desktopHeader-chatLink"
          aria-label={chatBubbleAriaLabel}
        >
          <span role="img" aria-label="">
            💬&nbsp;
          </span>
          Messages
          {numUnreadMessages > 0 && (
            <span className="header-chatBubble" data-testid="header-chatBubble">
              {numUnreadMessages}
            </span>
          )}
        </a>
      </li>
      {isPetParent && (
        <li>
          <a href={buildMyEvLegacyLink(`/members/referrals?countryCode=${accountDetails.user?.countryCode}`)}>
            <span role="img" aria-label="">
              💰&nbsp;
            </span>
            Give a Friend $50
          </a>
        </li>
      )}
      <li>
        <a href={buildMyEvLegacyLink('/members/profile')}>Settings</a>
      </li>
      <li>
        <a href={buildMyEvLegacyLink('/logout')}>Sign Out</a>
      </li>
    </ul>
  );
};
