import {
  CareResourcesReasonCategory,
  useGetCareResourcesResultsBannerDataQuery,
} from '@embark/shared-components/src/generated/graphql-client-types';

import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { CareResourcesResultsBanner } from '@embark/shared-components/src/pet/careResources/resultsBanner/careResourcesResultsBanner';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { getPetNumFromQueryString } from 'projects/legacyClient/src/react/pet/getPetNumFromQueryString';
import { handleGraphQLError } from '@embark/shared-components/src/services/errors/handleGraphQLError';

export function initCareResourcesTraitsResultsBanner(): void {
  ReactDOM.render(
    <AppProviders>
      <CareResourcesResultsBannerContainer category={CareResourcesReasonCategory.Trait} />
    </AppProviders>,
    document.getElementById('care-resources-traits-results-banner')
  );
}

type CareResourcesResultsBannerContainerProps = {
  category: CareResourcesReasonCategory;
};

export const CareResourcesResultsBannerContainer: FC<CareResourcesResultsBannerContainerProps> = ({ category }) => {
  const petNum = getPetNumFromQueryString();

  const { data, loading, error } = useGetCareResourcesResultsBannerDataQuery({
    variables: {
      petNum: petNum,
    },
  });

  if (error) {
    handleGraphQLError(error);
    return <></>;
  }

  if (loading || !data) {
    return <></>;
  }

  const pet = data.me.petByPetNum;

  if (!pet) {
    return <></>;
  }

  return <CareResourcesResultsBanner pet={pet} category={category} />;
};
