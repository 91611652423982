import 'projects/sharedComponents/src/pet/navigation/backToLink.scss';

import { Button, ButtonProps } from '@mui/material';
import { UrlPathPrefix, buildMyEvLegacyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';

import { ArrowBack } from '@mui/icons-material';
import { FC } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';

export type BackToLinkProps = {
  linkText: string;
  urlPath: UrlPathPrefix;
  /** True if this should use client-side rendering, false to use server-side rendering. */
  isReactRouterLink: boolean;
  className?: string;
};

export const BackToLink: FC<BackToLinkProps> = (props) => {
  const { linkText, urlPath, isReactRouterLink, className } = props;

  const url = isReactRouterLink ? urlPath : buildMyEvLegacyLink(urlPath);
  const history = useHistory();

  const onNavigation: ButtonProps['onClick'] = (event) => {
    if (isReactRouterLink) {
      if (!!event) {
        event.preventDefault();
      }
      history.push(url);
    }
    return;
  };

  return (
    <div className={clsx('backToLink-container', className)}>
      <Button variant="text" href={url} className="backToLink-link" onClick={onNavigation}>
        <ArrowBack className="backToLink-arrowIcon" /> {linkText}
      </Button>
    </div>
  );
};
