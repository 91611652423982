import './petResultsNav.scss';

import { Box, Typography } from '@mui/material';
import { UserType, getUserType } from 'projects/sharedComponents/src/services/user/userTypes';
import {
  extractPetTrackingFields,
  getHeapDataAttributes,
  useGTMPetDataLayer,
  useTrackReactRouterPageView,
} from 'projects/sharedComponents/src/services/eventTracking/eventTracking';

import { AccountDetails } from 'clientServerShared/types/account';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ConfigTypes } from 'projects/sharedComponents/src/services/getConfig/getConfig';
import { Header } from 'projects/sharedComponents/src/header/header';
import { HeapCategory } from 'clientServerShared/types/eventTracking';
import { PetResultsNavFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { PetResultsNavItems } from 'projects/sharedComponents/src/pet/navigation/petResultsNavItems';
import { RenderIf } from 'projects/sharedComponents/src/renderStates/renderIf';
import { StandardLink } from 'projects/sharedComponents/src/links/standardLink';
import { VFC } from 'react';
import { buildUrlFromConfig } from 'projects/sharedComponents/src/services/links/linkBuilder';
import { useHeapEventProperties } from 'projects/sharedComponents/src/hooks/useHeapEventProperties';

export type PetResultsNavProps = {
  accountDetails: AccountDetails;
  pet: PetResultsNavFragment;
  /**
   * Controls how routing works for pages in React versus pages in Handlebars.
   * If a page is in React, we use React Router to link to other React pages.
   * If a page is in Handlebars, we always use a full server round trip.
   */
  isReactPage: boolean;
};

export const PetResultsNav: VFC<PetResultsNavProps> = (props) => {
  const { accountDetails, pet, isReactPage } = props;
  useGTMPetDataLayer(pet);
  useHeapEventProperties(extractPetTrackingFields(pet));
  useTrackReactRouterPageView(extractPetTrackingFields(pet));
  const userType = getUserType(accountDetails);
  const aboutPageUrl = buildUrlFromConfig(ConfigTypes.EMBARKVET_APP, `/pet/${pet.id}/about`);

  return (
    <>
      <div className="petResultsNav">
        <Header accountDetails={accountDetails} logoLinksToLegacyMyEV />
        <div className="petResultsNav-scrollContainer">
          <nav className="petResultsNav-nav" aria-label="Pet Results">
            <PetResultsNavItems pet={pet} accountDetails={accountDetails} isReactRouterLinks={isReactPage} />
          </nav>
        </div>
      </div>
      <RenderIf condition={userType == UserType.PetParent}>
        <Box className="petResultsNav-banner">
          <StandardLink
            className="no-text-decoration"
            href={aboutPageUrl}
            {...getHeapDataAttributes({
              heapCategory: HeapCategory.PET_RESULTS_NAV,
              heapAction: 'Go to ev-app',
            })}
          >
            <Typography className="petResultsNav-bannerText">
              <span role="img" aria-hidden>
                🎉
              </span>{' '}
              Check out our new experience!
              <ArrowForwardIcon className="petResultsNav-bannerIcon" />
            </Typography>
          </StandardLink>
        </Box>
      </RenderIf>
    </>
  );
};
