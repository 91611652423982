import { Sex } from 'projects/sharedComponents/src/generated/graphql-client-types';

export function hisOrHer(sex: Sex): string {
  return sex === 'm' ? 'his' : 'her';
}

export function himOrHer(sex: Sex): string {
  return sex === 'm' ? 'him' : 'her';
}

export function heOrShe(sex: Sex): string {
  return sex === 'm' ? 'he' : 'she';
}

export function neuteredOrSpayed(sex: Sex): string {
  return sex === 'm' ? 'neutered' : 'spayed';
}

export function boyOrGirl(sex: Sex): string {
  return sex === 'm' ? 'boy' : 'girl';
}

export function maleOrFemale(sex: Sex): string {
  return sex === 'm' ? 'male' : 'female';
}

export interface PronounVariations {
  himOrHer: string;
  hisOrHer: string;
  heOrShe: string;
}

export function getPronouns(sex: Sex): PronounVariations {
  return {
    hisOrHer: hisOrHer(sex),
    himOrHer: himOrHer(sex),
    heOrShe: heOrShe(sex),
  };
}
