import { Box, Typography } from '@mui/material';

import { Dialog } from 'projects/sharedComponents/src/dialog/dialog';
import { FC } from 'react';
import { buildEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';

export type WolfinessModalProps = {
  open: boolean;
  onClose: () => void;
};

export const WolfinessModal: FC<WolfinessModalProps> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" aria-label="Wolfiness">
      <div>
        <Box display="flex" justifyContent="center" className="geneticStatsModal-icon">
          <img src={buildEmbarkCDNUrl('/img/genetic-stats/icon-wolfiness-blue.svg')} alt="" height={104} />
        </Box>

        <Typography variant="h5" component="h2" className="geneticStatsModal-header geneticStatsModal-textBlock">
          <b>Wolfiness Score</b>
        </Typography>

        <Typography variant="body1">
          <b>How wolfy is my dog?</b>
        </Typography>

        <Typography variant="body2" className="geneticStatsModal-textBlock">
          Most dogs have wolfiness scores of 1% or less. We find populations and breeds with higher scores of 2-4%
          occasionally, and unique dogs with scores of 5% or above more rarely.
        </Typography>

        <Typography variant="body1">
          <b>What does it mean for my dog</b>
        </Typography>

        <Typography variant="body2" className="geneticStatsModal-textBlock">
          Your dog’s Wolfiness Score is not a measure of recent dog-wolf hybridization and does not necessarily indicate
          that your dog has some recent wolf ancestors. (If your dog has recent wolf ancestors, you will see that in the
          breed mix report.) Instead, the Wolfiness Score is based on the number of ancient genetic variants your dog
          has in our unique Wolfiness marker panel. Wolfiness scores up to 10% are almost always due to ancient wolf
          genes that survived many generations, rather than any recent wolf ancestors. These ancient genes may be a few
          thousand years old, or may even date back to the original domestication event 15,000 years ago. They are bits
          of a wild past that survive in your dog!
        </Typography>

        <Typography variant="body1">
          <b>The science</b>
        </Typography>

        <Typography variant="body2">
          Your dog’s Wolfiness Score is based on hundreds of markers across the genome where dogs (or almost all of
          them) are the same, but wolves tend to be different. These markers are thought to be related to "domestication
          gene sweeps" where early dogs were selected for some trait. Scientists have known about “domestication gene
          sweeps” for years, but do not yet know why each sweep occurred. By finding rare dogs carrying an ancient
          variant at a certain marker, we can make associations with behavior, size, metabolism, and development that
          likely caused these unique signatures of “doggyness” in the genome.
        </Typography>
      </div>
    </Dialog>
  );
};
