import { Alert, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from 'projects/sharedComponents/src/buttons/loadingButton';
import {
  ShareResultsWithVetFormProps,
  useShareResultsWithVetForm,
} from 'projects/sharedComponents/src/shareResultsWithVet/shareResultsWithVetFormHooks';

import CloseIcon from '@mui/icons-material/Close';
import { FormInput } from 'projects/sharedComponents/src/forms/formInput';
import { FormProvider } from 'react-hook-form';
import { HeapAction } from 'clientServerShared/types/eventTracking';
import { VFC } from 'react';
import { getHeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';
import { styled } from '@mui/material/styles';
import { useShortPetName } from 'projects/sharedComponents/src/hooks/useShortPetName';

export const ShareResultsWithVetForm: VFC<ShareResultsWithVetFormProps> = (props) => {
  const {
    pet,
    heapCategory,
    shareResultsWithVetForm,
    vetNameInput,
    vetEmailInput,
    onClickSendReport,
    isShareResultsWithVetLoading,
    previousShareResultsInput,
    isSuccessAlertShown,
    onDismissSuccessAlert,
    isErrorAlertShown,
    onDismissErrorAlert,
  } = useShareResultsWithVetForm(props);
  const { isValid } = shareResultsWithVetForm.formState;
  const shortPetName = useShortPetName(pet.name);

  return (
    <div>
      <Collapse in={isSuccessAlertShown}>
        <StyledAlert
          severity="success"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={onDismissSuccessAlert}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Report sent to {previousShareResultsInput?.vetName} at {previousShareResultsInput?.vetEmail}!
        </StyledAlert>
      </Collapse>
      <Collapse in={isErrorAlertShown}>
        <StyledAlert
          severity="error"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={onDismissErrorAlert}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Something went wrong. Please try again.
        </StyledAlert>
      </Collapse>
      <Stack spacing={2}>
        <Typography variant="body2">
          Provide your veterinarian with the information they need to understand {shortPetName}’s genetic health.
        </Typography>
        <FormProvider {...shareResultsWithVetForm}>
          <form>
            <Stack spacing={3} alignItems="flex-start">
              <Stack spacing={{ xs: 3, sm: 2 }} direction={{ xs: 'column', sm: 'row' }} width="100%">
                {/* Replace the type to work around Foundation types on the legacy site */}
                <StyledOutlinedFormInput
                  variant="outlined"
                  {...vetNameInput}
                  type="text-mui"
                  InputLabelProps={{ required: false }}
                />
                <StyledOutlinedFormInput
                  variant="outlined"
                  {...vetEmailInput}
                  type="text-mui"
                  InputLabelProps={{ required: false }}
                />
              </Stack>
              <div>
                {/* Apply styles directly to override Foundation styles on the legacy site */}
                <StyledSendReportButton
                  color="primary"
                  variant="contained"
                  loading={isShareResultsWithVetLoading}
                  disabled={!isValid}
                  onClick={onClickSendReport}
                  style={{ borderRadius: '24px', boxShadow: 'none' }}
                  {...getHeapDataAttributes({
                    heapCategory,
                    heapAction: HeapAction.SHARE_RESULTS_WITH_VET,
                  })}
                >
                  Send report
                </StyledSendReportButton>
              </div>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </div>
  );
};

const StyledOutlinedFormInput = styled(FormInput)(({ theme }) => ({
  'flexGrow': 1,
  '& .MuiOutlinedInput-root': {
    background: 'white',
  },
  '& .MuiOutlinedInput-root.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
    color: theme.palette.helix.blueDark,
    borderColor: theme.palette.helix.blueDark,
  },
  '& .MuiInputLabel-outlined.Mui-error': {
    color: theme.palette.error.main,
  },
}));

const StyledSendReportButton = styled(LoadingButton)<LoadingButtonProps>(({ theme }) => ({
  textTransform: 'none',
  letterSpacing: '0.15px',
  height: theme.spacing(6),
  borderRadius: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
