<template>
  <div class="grid-x collapse">
    <div class="large-3 cell">
      <select
        :aria-label="`${dateTypePrefix} is known or estimated?`"
        :name="`${dateTypePrefix}IsEstimated`"
        :id="`${dateTypePrefix}IsEstimated`"
        v-model="dateIsEstimatedForSelectBoxComputed"
        v-if="dateParts.year && dateParts.year !== '0'"
        @change="$emit('change-is-estimated', dateIsEstimatedForSelectBoxComputed)"
      >
        <option value="true">Estimated</option>
        <option value="false">Known</option>
      </select>
      <div v-else>Select:</div>
    </div>
    <DateSelectDropdown
      class="large-2 large-offset-1 small-4 cell"
      :name="`${dateTypePrefix}Day`"
      v-model="dateParts.day"
      label="Day"
      :options="dobRanges.days.map((value) => ({ value, label: value }))"
      @input="setDateParts('day', $event)"
    />
    <DateSelectDropdown
      class="large-3 small-4 cell"
      :name="`${dateTypePrefix}Month`"
      v-model="dateParts.month"
      label="Month"
      :options="dobRanges.months"
      @input="setDateParts('month', $event)"
    />
    <DateSelectDropdown
      class="large-3 small-4 cell"
      :name="`${dateTypePrefix}Year`"
      v-model="dateParts.year"
      label="Year"
      :options="dobRanges.years.map((value) => ({ value, label: value }))"
      @input="setDateParts('year', $event)"
    />
  </div>
</template>

<script>
import { EventBus, Events } from '../event-bus';
import DateSelectDropdown from './date-select-dropdown.vue';
import Vue from 'vue';
import { dobRanges } from '../../../../../../clientServerShared/aboutPetData';

export default Vue.extend({
  props: ['dateTypePrefix', 'dateIsEstimatedForSelectBox', 'dateParts'],
  components: { DateSelectDropdown },
  data() {
    return {
      dobRanges,
      manuallySetEstimated:
        this.dateIsEstimatedForSelectBox || (this.dateParts.year !== 0 && this.dateParts.year !== '0'),
      manualEstimatedValue: this.dateIsEstimatedForSelectBox,
    };
  },
  computed: {
    dateIsEstimatedForSelectBoxComputed: {
      set: function (newValue) {
        this.manuallySetEstimated = true;
        this.manualEstimatedValue = newValue;
        return this.manualEstimatedValue;
      },
      get: function () {
        if (this.manuallySetEstimated) {
          return this.manualEstimatedValue;
        }
        if (this.dateParts.day !== 0 && this.dateParts.day !== '0') {
          return 'false';
        } else if (
          (this.dateParts.month !== 0 && this.dateParts.month !== '0') ||
          (this.dateParts.year !== 0 && this.dateParts.year !== '0')
        ) {
          return 'true';
        }
      },
    },
  },
  watch: {
    dateIsEstimatedForSelectBoxComputed: function (newValue) {
      // TODO: This will be problematic with multiple components on the page
      // as there will be no way to distinguish events of each. Update this
      // component to be able to emit arbitrary events so we can differentiate
      // them.
      EventBus.emit(Events.DateIsEstimatedChanged, newValue);
    },
  },
  methods: {
    setDateParts(type, value) {
      const newDateParts = Object.assign({}, this.dateParts);

      if (type === 'day') {
        newDateParts.day = value;
      } else if (type === 'month') {
        newDateParts.month = value;
      } else if (type === 'year') {
        newDateParts.year = value;
      }

      this.$emit('input', newDateParts, type);
    },
  },
});
</script>
