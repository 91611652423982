import 'projects/sharedComponents/src/header/impersonation.scss';

import { AccountDetails } from 'clientServerShared/types/account';
import React from 'react';
import { useImpersonationProps } from 'projects/sharedComponents/src/header/impersonationHooks';

export type ImpersonationProps = {
  accountDetails: AccountDetails;
};

export const Impersonation: React.FC<ImpersonationProps> = ({ accountDetails }) => {
  const { showImpersonation, accountType, accountName, impersonatorName, exitImpersonationUrl } =
    useImpersonationProps(accountDetails);

  if (!showImpersonation) {
    return <></>;
  }

  return (
    <div className="impersonatorHeader">
      <strong>{impersonatorName}</strong> impersonating {accountType} <strong>{accountName}</strong>
      <a className="impersonatorHeader-returnLink" href={exitImpersonationUrl}>
        Return to your account
      </a>
    </div>
  );
};
