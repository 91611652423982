<template>
  <div v-if="!error">
    <section v-if="!conversations" class="app-width app-module" style="padding-bottom: 600px">
      <p class="text-medium-gray">Loading...</p>
    </section>
    <section v-if="!!conversations && !conversations.length">
      <div class="resultsSection-headerContainer">
        <div class="resultsSection-headerItem">
          <img
            class="resultsSection-headerImage"
            :src="`${imgHost}/icons/chat-bubble-blue-shades.svg`"
            alt="Chat Icon"
          />
        </div>
      </div>

      <div class="stats-block grid-x grid-padding-x align-center">
        <div class="cell small-10 medium-5">
          <p class="text-center text-small chatHeader">No Messages</p>
          <p class="text-center">You have not begun any conversations yet.</p>
          <p class="text-center">
            View the profiles of other dogs and click the
            <img alt="Chat" class="inlineChatIcon" :src="`${imgHost}/icons/chat-bubble.svg`" /> icon to start a chat.
          </p>
        </div>
      </div>
    </section>
    <div v-else class="grid-x medium-collapse chat-module" v-cloak>
      <div class="cell medium-5 large-space-below">
        <div class="conversations-container" role="tablist">
          <p class="text-small chatHeader" style="padding: 5px 0 5px 30px; margin: 0">Choose a Conversation</p>
          <p v-if="!conversations" style="padding: 5px 15px; margin: 0">Loading...</p>
          <button
            v-else
            v-for="conversation of conversations"
            type="button"
            role="tab"
            class="conversation-card pointer"
            aria-controls="currentConversationContainer"
            :aria-selected="conversation === activeConversation ? 'true' : 'false'"
            :class="{ 'conversation-card-active': conversation === activeConversation }"
            @click="switchToConversation(conversation.conversation_id)"
            @keydown.right="focusNextConversation"
            @keydown.down="focusNextConversation"
            @keydown.left="focusPreviousConversation"
            @keydown.up="focusPreviousConversation"
            v-cloak
          >
            <span v-if="isNewConversation(conversation.conversation_id)" class="small-gray-text"> NEW </span>
            <strong
              v-bind:class="[
                'conversationList-username text-black',
                { 'text-dark-gray': conversation !== activeConversation },
              ]"
              v-if="conversation.subject.length > 0"
            >
              {{ conversation.toUserName }}
              <br />
            </strong>
            <small class="text-dark-gray">{{ conversation.subject }}</small>
            <div v-if="conversation.num_unread > 0" class="read-conversation-notice">
              {{ conversation.num_unread }} unread
            </div>
          </button>
        </div>

        <section class="conversationList-footer">
          <p
            v-if="conversations && conversations.length < 3"
            class="text-medium-gray space-above-md-responsive text-center text-xsmall"
          >
            <strong>How to start a new conversation?</strong>
            <br />
            Visit other dogs’ profiles and look for this symbol:
            <br />
            <img class="inlineChatIcon" :src="`${imgHost}/icons/chat-bubble.svg`" alt="Chat bubble" />
          </p>
        </section>
      </div>

      <div class="cell medium-7" id="active-conversation" style="border-left: 1px solid #d4d4d4">
        <section
          v-if="showMessagesAndComposeWindow"
          id="currentConversationContainer"
          role="tabpanel"
          class="app-width"
          v-cloak
        >
          <header class="currentConversationHeader grid-x align-top" v-if="showActiveConversationHeader">
            <div class="cell grid-x">
              <a
                class="cell shrink"
                v-if="!!activeConversationPetImageUrl"
                :href="`/dog/${activeConversationPets[0].handle}`"
              >
                <img
                  class="currentConversationHeader-dogPicture"
                  v-bind:src="activeConversationPetImageUrl"
                  :alt="activeConversationPets[0].name"
                />
              </a>
              <section class="cell auto grid-y">
                <strong class="cell currentConversationHeader-title" v-if="hasActiveConversationUserName">
                  {{ activeConversation.toUserName }}
                </strong>

                <small v-if="hasActiveConversationPets" class="cell text-dark-gray">
                  <template v-if="hasActiveConversationUserName">They are</template>
                  <template v-if="activeConversationPets.length === 1">
                    <pet-name-link :pet="activeConversationPets[0]"></pet-name-link>’s human
                  </template>
                  <template v-else-if="activeConversationPets.length === 2">
                    <pet-name-link :pet="activeConversationPets[0]"></pet-name-link> and
                    <pet-name-link :pet="activeConversationPets[1]"></pet-name-link>’s human
                  </template>
                  <template v-else>
                    <pet-name-link :pet="activeConversationPets[0]"></pet-name-link>,
                    <pet-name-link :pet="activeConversationPets[1]"></pet-name-link>, and
                    <pet-name-link :pet="activeConversationPets[2]"></pet-name-link>’s human
                  </template>
                </small>
              </section>

              <div v-if="!isNewConversation(activeConversation.conversation_id)">
                <template v-if="!activeConversation.blocked">
                  <ConfirmBlock
                    modalName="chat-confirmBlock"
                    @refresh="refresh"
                    :conversationId="activeConversation.conversation_id"
                  />
                  <button data-open="chat-confirmBlock" class="cell shrink button-secondary">Block</button>
                </template>
                <button v-else type="button" v-on:click="unblock" class="cell shrink button-secondary">Unblock</button>
              </div>
            </div>
            <section v-if="dogHasGeneticComparisonEntryPoint" class="cell extra-small-space-above text-small">
              {{ activeConversation.activeUserPet.name }} and {{ activeConversationPets[0].name }} are
              {{ activeConversationPets[0].likenessScore }}% alike.
              <a :href="likenessLink">See how <span>&rsaquo;</span></a>
            </section>
          </header>

          <template v-if="!activeConversation.blocked">
            <div class="space-above-sm-responsive">
              <div v-for="message of messages" v-cloak>
                <div
                  v-text="message.body"
                  class="message-bubble"
                  v-bind:class="{
                    'message-bubble-by-me': message.createdByMe,
                    'message-bubble-by-them': !message.createdByMe,
                  }"
                ></div>
                <div class="clearfix"></div>
                <p v-if="message.createdByMe" class="message-read-status">
                  <span
                    v-if="
                      message &&
                      message.allReadStatuses &&
                      message.allReadStatuses.length &&
                      message.allReadStatuses[0].readAt
                    "
                  >
                    Read: {{ new Date(message.allReadStatuses[0].readAt).toLocaleString() }}
                  </span>
                  <span v-else>Unread</span>
                </p>
                <p v-else class="message-sent-status">
                  Sent:
                  <span v-text="new Date(message.createdAt).toLocaleString()"></span>
                </p>
                <div class="clearfix" style="margin-bottom: 10px"></div>
              </div>
            </div>
            <div v-if="previousConversationsToNewConversationUser.length > 0" style="padding: 10px">
              <small class="text-medium-gray">
                You have
                <span v-if="previousConversationsToNewConversationUser.length === 1">an existing conversation</span>
                <span v-else="previousConversationsToNewConversationUser.length === 1">existing conversations</span>
                with this dog’s owner. Choose an existing conversation to continue it:
              </small>
              <ul style="margin-top: 10px">
                <li v-for="conversation of previousConversationsToNewConversationUser" v-cloak>
                  <button
                    type="button"
                    class="pointer text-link text-black"
                    v-on:click="switchToConversation(conversation.conversation_id)"
                  >
                    <strong v-if="conversation.subject.length > 0">
                      {{ conversation.subject }}
                    </strong>
                    <strong v-else>(No subject)</strong>
                  </button>
                </li>
              </ul>
              <small class="text-medium-gray"> Or write a new message below to start a new conversation: </small>
            </div>
            <form v-if="showComposeWindow" v-cloak>
              <div class="grid-x space-above-small">
                <div class="cell small-10">
                  <textarea
                    ref="compose"
                    class="message-compose-body"
                    name="body"
                    v-model="composeMessageBody"
                    placeholder="Your Message"
                    aria-label="Compose message"
                  ></textarea>
                </div>
                <div class="cell small-2">
                  <button
                    type="button"
                    class="button-medium pointer capitalize chat-sendButton"
                    v-bind:class="{ 'button-yellow': !sending }"
                    v-on:click="send"
                  >
                    Send
                  </button>
                </div>
              </div>
              <p
                class="text-medium-gray"
                style="padding: 20px"
                v-if="
                  isNewConversation(activeConversation.conversation_id) &&
                  previousConversationsToNewConversationUser.length === 0
                "
              >
                Type your message and hit
                <button type="button" v-on:click="send" class="capitalize pointer">Send</button> to begin. <br /><br />
                If {{ startNewConversationTo.toPetName }}’s people are offline we will email them to let them know.
              </p>
            </form>
            <div v-if="showComposeWindow" class="text-center grid-x align-center space-below" style="margin-top: 80px">
              <button
                type="button"
                class="cell small-11 medium-6 button-medium"
                v-bind:class="{ 'button-red': !sending }"
                v-on:click="deleteMessagesInConversation"
              >
                Delete This Conversation
              </button>
            </div>
          </template>
          <p v-else class="small-space-above text-dark-gray text-center">You have blocked this user.</p>
        </section>
        <section v-else class="app-width grid-x align-center space-above">
          <img
            class="cell resultsSection-headerImage"
            :src="`${imgHost}/icons/chat-bubble-blue-shades.svg`"
            alt="Chat Icon"
          />
          <p class="cell text-center text-small chatHeader" style="padding: 5px 15px">
            Choose a conversation to view it here.
          </p>
        </section>
      </div>
    </div>
  </div>
  <section v-else class="app-width app-module">
    <p role="alert" class="text-medium-gray">Error getting conversations.</p>
  </section>
</template>

<script>
import ConfirmBlock from './chat/confirm-block-modal.vue';
import PetNameLink from './chat/pet-name-link.vue';
import Vue from 'vue';
import axios from 'axios';
import { getCsrfToken } from '../csrf';
import { log } from '../browserLogger';

export default Vue.extend({
  data() {
    return {
      error: false,
      conversations: null,
      activeConversation: {},
      messages: [],
      composeMessageBody: '',
      sending: false,
      startNewConversationTo: {},
      toHandle: new URLSearchParams(window.location.search).get('to'),
      previousConversationsToNewConversationUser: [],
      tempNewConversationId: -1,
    };
  },
  components: {
    PetNameLink,
    ConfirmBlock,
  },
  computed: {
    showMessagesAndComposeWindow() {
      // show the right-hand message and compose pane when a conversation is selected
      return this.activeConversation && this.activeConversation.conversation_id;
    },
    activeConversationPetImageUrl() {
      if (this.activeConversationPets.length === 1) {
        return this.activeConversationPets[0].imageUrl;
      }
      return null;
    },
    activeConversationPets() {
      return this.activeConversation.toUserPets;
    },
    showComposeWindow() {
      return (
        this.messages.length > 0 ||
        (this.activeConversation && this.isNewConversation(this.activeConversation.conversation_id))
      );
    },
    likenessLink() {
      return `/dogs-like-mine/${this.activeConversation.activeUserPet.handle}/${this.activeConversationPets[0].handle}`;
    },
    dogHasGeneticComparisonEntryPoint() {
      return (
        this.activeConversationPets[0] &&
        this.activeConversationPets[0].dogHasGeneticComparisonEntryPoint &&
        this.activeConversationPets[0].likenessScore > 0
      );
    },
    hasActiveConversationUserName() {
      return this.activeConversation && this.activeConversation.toUserName;
    },
    hasActiveConversationPets() {
      return this.activeConversation && this.activeConversationPets.length;
    },
    showActiveConversationHeader() {
      return this.hasActiveConversationUserName || this.hasActiveConversationPets;
    },
  },
  methods: {
    async fetchMessages(conversationId) {
      if (!conversationId) {
        throw new Error('Missing conversationId in fetchMessages');
      }
      this.activeConversation = !this.conversations
        ? null
        : this.conversations.find((c) => c.conversation_id === conversationId);
      if (!this.activeConversation) {
        // activeConversation could have disappeared if it was a temp unsaved conversation, and we just refreshed to get only saved server-side conversations
        this.messages = [];
        this.focusOnComposeBox();
      } else if (this.isNewConversation(conversationId)) {
        // if activeConversation exists but is a temp unsaved conversation, then it does not yet have any messages
        this.messages = [];
        this.focusOnComposeBox();
      } else {
        // empty the messages list while waiting for the messages for this conversation to load
        const getMessagesResponse = (await axios.get(`/members/chat/get-messages/${conversationId}`)).data;
        this.messages = getMessagesResponse.messages;
        this.activeConversation.petImageUrl = getMessagesResponse.petImageUrl;
        this.focusOnComposeBox();
      }
    },
    async refresh() {
      const data = await this.tryGetConversations();

      if (!data) {
        throw new Error('Missing get-conversations response');
      }
      this.conversations = data.conversations;
      if (this.activeConversation && this.activeConversation.conversation_id) {
        void this.fetchMessages(this.activeConversation.conversation_id);
      }
    },
    switchToConversation(conversationId) {
      if (this.isNewConversation(conversationId)) {
        // check whether we already have any conversations with the owner of this dog
        this.previousConversationsToNewConversationUser = this.conversations.filter((c) =>
          c.participant_user_ids.includes(this.startNewConversationTo.toUserId)
        );
      } else {
        this.previousConversationsToNewConversationUser = [];
      }
      void this.fetchMessages(conversationId);
      global.document.getElementById('active-conversation').scrollIntoView({ behavior: 'smooth' });
    },
    focusNextConversation() {
      // Change which conversation tab is in focus when arrow keys are pressed.
      // This is an aria requirement for role="tab" elements.
      const tabs = Array.from(document.querySelectorAll('.conversation-card'));
      let currentIndex = tabs.indexOf(document.activeElement);
      currentIndex++;
      if (currentIndex >= tabs.length) {
        currentIndex = 0;
      }
      tabs[currentIndex].focus();
    },
    focusPreviousConversation() {
      const tabs = Array.from(document.querySelectorAll('.conversation-card'));
      let currentIndex = tabs.indexOf(document.activeElement);
      currentIndex--;
      if (currentIndex < 0) {
        currentIndex = tabs.length - 1;
      }
      tabs[currentIndex].focus();
    },
    async send() {
      if (this.sending) {
        return;
      }
      if (!this.composeMessageBody || this.composeMessageBody.trim() === '') {
        window.alert('Please add a message body before sending');
        return;
      }
      this.sending = true;
      try {
        const res = await axios.post('/members/chat/send-message', {
          body: this.composeMessageBody,
          conversationId: this.activeConversation.conversation_id,
          startNewConversationTo: this.startNewConversationTo,
          _csrf: getCsrfToken(),
        });
        this.addSentMessage(res.data);
      } catch (err) {
        this.onSendFailed(null, err);
      }
    },
    addSentMessage(sentMessageResponse) {
      this.sending = false;
      if (sentMessageResponse.error) {
        window.alert(sentMessageResponse.error);
      } else if (sentMessageResponse.sentMessage) {
        this.messages.push(sentMessageResponse.sentMessage);
        this.composeMessageBody = '';
      } else if (
        sentMessageResponse.startedConversation &&
        sentMessageResponse.startedConversation.conversationId &&
        this.isNewConversation(this.activeConversation.conversation_id)
      ) {
        this.activeConversation.conversation_id = sentMessageResponse.startedConversation.conversationId;
        this.composeMessageBody = '';
        void this.refresh();
      } else {
        throw new Error(`Unknown sent-message response "${JSON.stringify(sentMessageResponse)}"`);
      }
    },
    onSendFailed(jqXHR, textStatus) {
      log.info(textStatus);
      global.alert('Failed to send message');
      this.sending = false;
    },
    async deleteMessagesInConversation() {
      if (this.sending) {
        return;
      }
      if (this.isNewConversation(this.activeConversation.conversation_id)) {
        // this was a temp conversation, so just refresh and it will go away
        void this.refresh();
        return;
      }
      this.sending = true;
      await axios.get('/members/chat/delete-messages-in-conversation/' + this.activeConversation.conversation_id);
      this.sending = false;
      void this.refresh();
    },
    focusOnComposeBox() {
      Vue.nextTick(() => {
        if (this.$refs.compose) {
          this.$refs.compose.focus();
        }
      });
    },
    isNewConversation(conversationId) {
      return conversationId === this.tempNewConversationId;
    },
    async unblock() {
      try {
        await axios.delete('/members/chat/block', {
          data: {
            conversationId: this.activeConversation.conversation_id,
            _csrf: getCsrfToken(),
          },
        });
        void this.refresh();
      } catch (err) {
        log.info(err);
        global.alert('Failed to unblock user');
      }
    },
    async tryGetConversations() {
      try {
        const { data } = await axios.get('/members/chat/get-conversations', {
          params: {
            to: this.toHandle,
          },
        });
        return data;
      } catch (err) {
        this.error = true;
        log.info(err);
      }
    },
  },
  async mounted() {
    const data = await this.tryGetConversations();

    if (!data) {
      throw new Error('Missing get-conversations response');
    }
    this.conversations = data.conversations;
    this.startNewConversationTo = data.toPetDetails;
    if (!!this.startNewConversationTo) {
      const newTempConversation = {
        conversation_id: -1,
        participant_user_ids: [],
        most_recent_message_snippet: null,
        most_recent_message_created_at: null,
        toUserPets: [
          {
            name: this.startNewConversationTo.toPetName,
            imageUrl: this.startNewConversationTo.toPetImageUrl,
            handle: this.startNewConversationTo.toHandle,
            likenessScore: this.startNewConversationTo.likenessScore,
          },
        ],
        toUserName: this.startNewConversationTo.toPetOwnerName,
        created_at: null,
        created_by: null,
        subject: `About ${this.startNewConversationTo.toPetName}`,
        pet_id: this.startNewConversationTo.toPetId,
        created_by_user_name: 'You',
        num_messages: null,
        num_unread: null,
        petImageUrl: this.startNewConversationTo.toPetImageUrl,
        blocked: false,
      };

      this.conversations = [...this.conversations, newTempConversation];
      this.switchToConversation(-1);
    }
  },
});
</script>

<style scoped>
.conversation-card {
  width: 100%;
  display: block;
  text-align: left;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px 0 12px 30px;
}
.conversation-card:hover {
  background-color: #f0f0f0;
}

.conversation-card-active {
  background-color: #eee;
}

.conversation-participant-name {
  font-weight: bold;
}

.read-conversation {
  color: gray;
}

.unread-conversation {
  color: black;
}

.message-bubble {
  margin: 5px 0;
  padding: 10px 15px;
  border-radius: 5px;
  max-width: 70%;
  word-wrap: break-word;
  color: black;
}

.message-bubble-by-me {
  background-color: #ddf2fa;
  float: right;
}

.message-read-status {
  color: #5f5f5f;
  font-size: 10px;
  float: right;
  margin: 0;
}

.read-conversation-notice {
  font-weight: bold;
  color: blue;
}

.message-sent-status {
  color: #5f5f5f;
  font-size: 10px;
  float: left;
  margin: 0;
}

.message-bubble-by-them {
  background-color: #f3f3f3;
  float: left;
}

.chat-module {
  padding-left: 0;
  padding-right: 0;
  background-color: white;
}

.inlineChatIcon {
  height: 20px;
}

.chatHeader {
  color: #767676;
  text-transform: uppercase;
}

.conversationList-username {
  display: block;
  margin: 5px 0px;
}

.conversationList-footer {
  padding: 0 20px 80px;
}

#currentConversationContainer {
  padding: 20px;
}

.currentConversationHeader {
  background-color: #f7f7f7;
  padding: 10px;
}

.currentConversationHeader-dogPicture {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-right: 8px;
}

.currentConversationHeader-title {
  font-size: 18px;
  font-weight: bold;
}

.chat-sendButton {
  display: block;
  width: 100%;
  height: 50px;
}
</style>
