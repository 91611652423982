<template>
  <section>
    <slot name="always-visible"></slot>
    <show-more collapsedHeight="2">
      <slot></slot>
    </show-more>
  </section>
</template>
<script>
import ShowMore from './show-more.vue';
import Vue from 'vue';
export default Vue.extend({
  components: { ShowMore },
});
</script>
