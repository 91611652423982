import * as intlTelInput from 'intl-tel-input';

export function init(): void {
  const preferredCountries = ['us', 'gb', 'au', 'de'];

  // Initialize international telephone number input
  const telInput = document.getElementById('phoneSMS');

  const telInputInstance = intlTelInput(telInput, {
    utilsScript: '//front.embarkvet.com/js/lib/intl-tel-input/utils.js',
    preferredCountries,
    hiddenInput: 'phoneSMSIntlFormat',
  });

  // Ensure that it's a valid telephone number
  document.querySelector('form.notification-container')?.addEventListener('submit', (event) => {
    const telNumber: string = telInputInstance.getNumber();

    if (telNumber.length > 0 && !telInputInstance.isValidNumber()) {
      window.alert('That phone number appears to be invalid, please check and try again');
      event.preventDefault();
    }
  });

  // Validate numbers on keyup
  document.querySelector('input[name=phoneSMS]')?.addEventListener('keyup', () => {
    const telNumber: string = telInputInstance.getNumber();
    const submitButton = document.querySelector<HTMLButtonElement>('button.submitPhone');

    // This is to make typescript happy, we will always have a 'button.submitPhone'
    if (!!submitButton) {
      const validationElement = document.querySelector<HTMLElement>('.phoneSMSValidationMessage');
      if (telNumber.length > 0 && !telInputInstance.isValidNumber()) {
        submitButton.disabled = true;
        submitButton.classList.add('disabled');
        // Same as above, we will always have a validation section message
        if (!!validationElement) {
          validationElement.setAttribute('role', 'alert');
          validationElement.innerHTML = `Not a valid number for ${telInputInstance.getSelectedCountryData().name}`;
        }
      } else {
        submitButton.disabled = false;
        submitButton.classList.remove('disabled');

        if (!!validationElement) {
          validationElement.removeAttribute('role');
          validationElement.innerHTML = '';
        }
      }
    }
  });
}
