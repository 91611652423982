import { AboutPetCardModalId } from 'clientServerShared/types/aboutPet';
import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { EditPetLocationModalButton } from 'projects/legacyClient/src/react/pet/location/petLocationModalButton';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { RenderIf } from '@embark/shared-components/src/renderStates/renderIf';
import { getPetNumFromQueryString } from 'projects/legacyClient/src/react/pet/getPetNumFromQueryString';
import { handleGraphQLError } from '@embark/shared-components/src/services/errors/handleGraphQLError';
import { useGetLocationsForPetQuery } from '@embark/shared-components/src/generated/graphql-client-types';

export function initPetLocationModal(): void {
  ReactDOM.render(
    <AppProviders>
      <PetLocationContainer />
    </AppProviders>,
    document.getElementById(AboutPetCardModalId.LOCATION)
  );
}

const PetLocationContainer: FC = () => {
  const petNum = getPetNumFromQueryString();

  const { data, error } = useGetLocationsForPetQuery({
    variables: {
      petNum: petNum,
    },
  });

  if (error) {
    handleGraphQLError(error);
    return <></>;
  }

  if (!data?.me || !data.me.petByPetNum) {
    return <></>;
  }

  return (
    <RenderIf condition={!!data.me.petByPetNum}>
      <EditPetLocationModalButton pet={data.me.petByPetNum} />
    </RenderIf>
  );
};
