<template>
  <div class="form-group">
    <slot>
      <div id="dateOfBirth" class="small-gray-text birthdatePicker-headerText">
        Date of birth, known or estimated (Optional)
      </div>
    </slot>
    <EstimatedDatePicker
      date-type-prefix="dateOfBirth"
      :date-is-estimated-for-select-box="dateOfBirthIsEstimatedForSelectBox"
      :date-parts="value"
      v-on="$listeners"
    />
    <div class="small-12 cell">
      <p v-if="dateOfBirthYear" class="text-dark-gray edit-page-descriptive">
        If you don't know the exact birthdate, you can just enter the year, or the nearest year and month.
      </p>
    </div>
  </div>
</template>

<script>
import EstimatedDatePicker from './date/estimated-date-picker.vue';
import Vue from 'vue';
export default Vue.extend({
  components: { EstimatedDatePicker },
  props: ['dateOfBirthIsEstimatedForSelectBox', 'value'],
  computed: {
    dateOfBirthYear() {
      return !!this.value ? this.value.year : null;
    }
  }
});
</script>

<style scoped>
.birthdatePicker-headerText {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
