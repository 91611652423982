import { Button, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { GeneticStatsPredictedAdultWeightFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { PredictedWeightModal } from 'projects/sharedComponents/src/geneticStats/predictedAdultWeight/predictedWeightModal';
import { buildEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';
import { useIsMobileScreen } from 'projects/sharedComponents/src/hooks/responsive/useIsMobileScreen';

type GeneticStatsPredictedAdultWeightProps = {
  pet: GeneticStatsPredictedAdultWeightFragment;
};

// Make predicted weight required, we do not render the component without it.
type RequiredPredictedAdultWeightFragment = GeneticStatsPredictedAdultWeightFragment & {
  genotype: {
    predictedWeight: number;
  };
};

export function shouldShowPredictedAdultWeightSection(
  pet: GeneticStatsPredictedAdultWeightFragment
): pet is RequiredPredictedAdultWeightFragment {
  if (!pet.genotype) {
    return false;
  }

  if (!pet.genotype.predictedWeight) {
    return false;
  }

  return true;
}

export const GeneticStatsPredictedAdultWeight: FC<GeneticStatsPredictedAdultWeightProps> = (props) => {
  const { pet } = props;

  const [isDialogOpen, setDialogOpen] = useState(false);

  const isSmallScreen = useIsMobileScreen();

  if (!shouldShowPredictedAdultWeightSection(pet)) {
    return null;
  }

  const predictedAdultWeight = pet.genotype.predictedWeight.toFixed(0);

  return (
    <>
      <PredictedWeightModal open={isDialogOpen} onClose={() => setDialogOpen(false)} />
      <Stack direction="row" spacing={isSmallScreen ? 3 : 5}>
        <img
          className="geneticStats-sectionImage"
          src={buildEmbarkCDNUrl('/img/genetic-stats/predicted-adult-weight-icon.svg')}
          alt=""
        />
        <Stack direction="column" spacing={0.5}>
          <div>
            <b className="geneticStatsSection-subSectionHeader">Predicted adult weight</b>
          </div>
          <div>
            <Typography variant="h6" component="span">
              {predictedAdultWeight} lbs
            </Typography>
          </div>
          <div>
            <Button variant="text" className="geneticStatsModal-button" onClick={() => setDialogOpen(true)}>
              <b>What does predicted weight mean?</b>
            </Button>
          </div>
        </Stack>
      </Stack>
    </>
  );
};
