import * as SentryBrowser from '@sentry/browser';
import * as SentryTracing from '@sentry/tracing';

import {
  HealthMonitor,
  bindAndAdaptSentryToMonitor,
  customerSitePrefixesToTrace,
} from '../../../../clientServerShared/services/healthMonitor';
import { Integration, SamplingContext } from '@sentry/types';

import { getEnvironment } from '@embark/shared-components/src/services/environment/getEnvironment';
import { getSentryFrontendAllowUrls } from '@embark/shared-components/src/services/sentry/sentryFrontEndAllowUrls';
import { getSentryFrontendDenyUrls } from '@embark/shared-components/src/services/sentry/sentryFrontendDenyUrls';
import { getSentryRelease } from '@embark/shared-components/src/services/sentry/getSentryRelease';

export function bindClientHealthMonitorBeforeAppStart(): HealthMonitor {
  return {
    ...bindAndAdaptSentryToMonitor(SentryBrowser, {
      environment: getEnvironment(),
      deserializer: errorDeserialization,
      dsn: getSentryFrontEndDSN(),
      integrations: getSentryFrontendIntegrations(),
      tracesSampler: getClientTracesSampler,
      denyUrls: getSentryFrontendDenyUrls(),
      release: getSentryRelease(),
      allowUrls: getSentryFrontendAllowUrls(),
    }),
  };
}

function getSentryFrontendIntegrations(): Integration[] {
  return [new SentryTracing.Integrations.BrowserTracing()];
}

function getSentryFrontEndDSN(): string | undefined {
  return 'https://74cedbe7ad48404b8a0e89157cd4ad0c@o405765.ingest.sentry.io/5786362';
}

// Below function is a workaround to support IE 11 because it won't work with the serialize-error package
export function errorDeserialization(err: string | Record<string, any>): Error {
  const encodedError = new Error();
  Object.assign(encodedError, typeof err === 'string' ? JSON.parse(err) : err);
  return encodedError as Error;
}

function getClientTracesSampler(samplingContext: SamplingContext): number {
  // Filter out anything from admin
  if (samplingContext.location?.pathname.startsWith('/admin')) {
    return 0.0;
  } else if (customerSitePrefixesToTrace.some((prefix) => samplingContext.location?.pathname.startsWith(prefix))) {
    return 0.007;
  }
  return 0.0;
}
