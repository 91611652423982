const $ = window.$ || require('jquery');

export function drawBreedWheel() {
  const d3 = window.d3;
  if (!d3) {
    throw new Error('drawBreedWheel: d3 library not available');
  }
  if (!$) {
    throw new Error('drawBreedWheel: jquery $ library not available');
  }

  // the outer and inner widths of the 'donut'. The outer width comes from the HTML, the inner widths are specified here
  const outerToInnerWidth = {
    200: 145,
    140: 106, // results summary small-screen
    145: 130,
    100: 80,
  };

  d3.selectAll('.breedwheel').each(function () {
    const $breedwheel = d3.select(this);
    const data = $(this).data('breedmix'); // e.g. [50,25,12.5,12.5 ]

    // sometimes browsers don't set the width exactly, e.g. the width may be 139.98 instead of 140, so read it as float then round to get to 140
    const width = Math.round(parseFloat($breedwheel.style('width'), 10));
    const innerWidth = outerToInnerWidth[width];
    const height = width;
    const radius = width / 2;

    const innerRadius = innerWidth / 2;

    const pie = d3.layout
      .pie()
      .value(function (d) {
        return +d;
      })
      .sort(null);

    const arc = d3.svg.arc().innerRadius(innerRadius).outerRadius(radius);

    const svg = $breedwheel
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('role', 'img')
      .attr('aria-label', 'breed wheel')
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    if (data && data.length) {
      svg
        .datum(data)
        .selectAll('path')
        .data(pie)
        .enter()
        .append('path')
        .attr('class', function (d, i) {
          return 'breed-color-fill-' + [i + 1];
        })
        .attr('d', arc);
    } else {
      svg
        .datum([100])
        .selectAll('path')
        .data(pie)
        .enter()
        .append('path')
        .attr('class', function () {
          return 'breed-color-fill-unknown';
        })
        .attr('d', arc);
    }

    svg.append('circle').attr('r', innerRadius).attr('fill', 'white');
  });
}
