import { Link, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/system';

import type { LinkProps } from '@mui/material';
import React from 'react';

/**
 * These props are inherited from MUI’s Link component, and
 * their use is discouraged in the footer as they can compromise
 * the intended layout and visual consistency when applied.
 *
 *
 * They are defined as `never` as opposed to removed with the
 *`Omit<>` utility type because the TypeScript compiler is not
 * able to make sense of the use of the `Omit<>` or similar on
 * extended generics.
 *
 * @see {@link https://github.com/microsoft/TypeScript/issues/33484 TS Issue #33484 }
 * @see {@link https://github.com/microsoft/TypeScript/issues/24791 TS Issue #24791 }
 */
type DisallowedFooterLinkProps = {
  style?: never;
  children?: never;
  color?: never;
  textTransform?: never;
  variant?: never;
  underline?: never;
  TypographyClasses?: never;
  paragraph?: never;
  gutterBottom?: never;
  variantMapping?: never;
};

type FooterLinkBaseProps = {
  label: string;
  isNew?: boolean;
};

/**
 *Mark `href` required if a custom component is not passed.
 *
 *This discriminated union will allow custom component to dictate if
 * an `href` is required or make it required if no custom component is supplied.
 *The href attribute is required for an anchor to be keyboard accessible.
 *If you cannot provide an href, but still need the element to resemble a link, use a button.
 *
 *{@link https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/4abc751d87a8491219a9a3d2dacd80ea8adcb79b/docs/rules/anchor-is-valid.md Learn more from eslint-plugin-jsx-a11y }
 *
 * @see {@link https://www.w3.org/WAI/WCAG21/Understanding/keyboard WCAG 2.1 - SC 2.1.1}
 */
type HrefRequiredIfNotCustomComponent<C extends React.ElementType> =
  | { component: C }
  | { component?: never; href: string };

type HelixFooterLinkProps<C extends React.ElementType> = LinkProps<
  C,
  HrefRequiredIfNotCustomComponent<C> & FooterLinkBaseProps & DisallowedFooterLinkProps
>;

const StyledFooterLink = styled(Link)(({ theme }) => ({
  'color': theme.palette.text.primary,
  '&:hover': { color: theme.palette.helix.blueDark },
  'fontWeight': 400,
  'fontSize': '14px',
  'lineHeight': '20px',
}));

/** Inherits {@link https://mui.com/api/link/ | MUI Link API} */
function HelixFooterLink<C extends React.ElementType>(props: HelixFooterLinkProps<C>): JSX.Element {
  const theme = useTheme();
  const { label, isNew, ...rest } = props;
  return (
    <>
      <StyledFooterLink {...rest} variant="caption" underline="hover">
        {label}
      </StyledFooterLink>
      {isNew && (
        <Typography
          textTransform="uppercase"
          variant="caption"
          sx={{ color: theme.palette.helix.blueDark, marginLeft: 1.5 }}
          aria-hidden="true"
        >
          <b>new!</b>
        </Typography>
      )}
    </>
  );
}

export { HelixFooterLink };
export type { HelixFooterLinkProps };
