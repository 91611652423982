export type GTMUserDataLayer = {
  name?: string | null;
  email?: string | null;
  // Legacy customer type
  customerType?: 'breeders' | 'nonbreeders' | null;
  isImpersonator?: boolean;
  impersonatorName?: string;
  impersonatorEmail?: string;
};

export type GTMPetDataLayer = {
  pet?: PetTrackingFields;
};

export type GTMDataLayer = GTMUserDataLayer & GTMPetDataLayer;

export type PetTrackingFields = {
  petNum?: number | null;
  productType?: string | null;
  productPurchaseSource?: string | null;
  swabState?: string | null;
  dateActivated?: string | null;
  dateUpgraded?: string | null;
  breedResultsReleased?: boolean;
  dateBreedResultsReleased?: string | null;
  healthResultsReleased?: boolean;
  dateHealthResultsReleased?: string | null;
  breedType?: string | null;
  primaryBreed?: string | null;
  primaryBreedPercent?: number | null;
};

export type HeapMetadata = {
  heapCategory?: HeapCategory;
  heapAction?: HeapAction | string;
  heapDetails?: string;
  heapValue?: number | string;
};

export type Heap = {
  heapMetadata?: HeapMetadata;
};

export enum HeapCategory {
  ADD_PATIENT = 'Add Patient',
  ADD_PET = 'Add Pet',
  AGE_TEST = 'Age Test',
  BREEDER_DASHBOARD = 'Breeder Dashboard',
  BREED_REVEAL = 'Breed Reveal',
  BREED_AND_ANCESTRY = 'Breed and Ancestry',
  CARE_RESOURCES = 'Care Resources',
  CARE_RESOURCES_EMPTY = 'Care Resources Empty',
  DOGS_LIKE_MINE = 'Dogs Like Mine',
  EDIT_PATIENT = 'Edit Patient',
  FOOD_RECOMMENDATION = 'Food Recommendation',
  GENETIC_STATS = 'Genetic Stats',
  HEALTH_SUMMARY = 'Health Summary',
  HEALTH_NEEDS_UPGRADE = 'Health Needs Upgrade',
  HEALTH_CONDITION = 'Health Condition',
  HEALTH_RESULT = 'Health Result',
  KIT_ACTIVATION = 'Kit Activation',
  MEMBERS_DASHBOARD = 'Members Dashboard',
  NUTRITION = 'Nutrition Recommendation',
  PET_PARENT_DASHBOARD = 'Pet Parent Dashboard',
  PET_RESULTS_NAV = 'Pet Results Nav',
  FOOTER_NAV = 'Footer Nav',
  PAIR_PREDICTOR = 'Pair Predictor',
  POP_UP = 'Pop Up',
  PUBLIC_PROFILE = 'Public Profile',
  RELATED_PAIR = 'Related Pair',
  RESEARCH = 'Research',
  RESULTS_BREED = 'Results Breed',
  RESULTS_HEALTH = 'Results Health',
  RESULTS_PROFILE = 'Results Profile',
  RESULTS_SUMMARY = 'Results Summary',
  RESULTS_TRAITS = 'Results Traits',
  SHARE_CENTER = 'Share Center',
  SHARE_ACCORDION = 'Share Accordion',
  USER_SETTINGS = 'User Settings',
  VET_DASHBOARD = 'Vet Dashboard',
  VET_GETTING_STARTED_MODAL = 'Vet Getting Started Modal',
  VET_SIGNUP = 'Vet Signup',
  VET_ONBOARDING_CHECKLIST = 'Vet Onboarding Checklist',
  VET_WORKFLOW_DRAWER = 'Vet Workflow Drawer',
}

export enum HeapAction {
  ACTIVATE_ANOTHER_KIT = 'Activate Another Kit',
  ACTIVATE_KIT = 'Activate Kit',
  ACTIVATE_WITH_BOX_KEY = 'Box Key Activation',
  ACTIVATE_WITH_SWAB_CODE = 'Swab Code Activation',
  ADDITIONAL_GENETIC_CONDITIONS = 'Additional Genetic Conditions',
  ADD_PATIENT = 'Add Patient',
  ADD_NEW_PATIENT_NOTE = 'Add New Patient Note',
  ADD_PET = 'Add Pet',
  AGE_TEST_RECOMMENDATION = 'Age Test Recommendation',
  AGE_TEST_ACTIVATION = 'Age Test Activation',
  BREED = 'Breed',
  BREED_RELEVANT_HEALTH_CONDITIONS = 'Breed Relevant Health Conditions',
  BREEDER_PROFILE = 'Breeder Profile',
  CARE_RESOURCES = 'Care Resources',
  CHANGE_RESULTS_PER_PAGE_NUMBER = 'Change Results Per Page Number',
  CHANGE_SORT_BY = 'Change Sort By',
  CHANGE_TEST_ORDERED_BY = 'Change Test Ordered By',
  CHAT = 'CHAT',
  CHIP = 'Chip',
  CLEAR_SEARCH_PARAMETERS = 'Clear Search Parameters',
  CLICK_THROUGH_PAGINATION = 'Click Through Pagination',
  CLINICAL_TOOLS = 'Clinical Tools',
  CLINICAL_VET_REPORT = 'Clinical Vet Report',
  CLOSE = 'Close',
  CLOSE_DRAWER = 'Close Drawer',
  CLOSE_MODAL = 'Close Modal',
  COLLAPSE_DOG_CARD = 'Collapse Dog Card',
  COMMUNICATION_SETTINGS = 'Communication Settings',
  CONTACT_US = 'Contact Us',
  CONTINUE = 'Continue',
  CONTINUE_EDITING_NOTE = 'Continue Editing Note',
  CONTINUE_TO_ACTIVATION = 'Continue to Activation',
  CONTINUE_TO_SELECTED_SIGNUP = 'Continue to Selected Signup',
  COPY_VET_REPORT_LINK = 'Copy Vet Report Link',
  DASHBOARD = 'Dashboard',
  DELETE_PATIENT_NOTE = 'Delete Patient Note',
  DELETE_PATIENT_NOTE_CONFIRM = 'Confirm Delete Patient Note',
  DISCARD_NEW_PATIENT_NOTE_CHANGES = 'Discard New Patient Note Changes',
  DOWNLOAD_CERTIFICATE = 'Download Certificate',
  DOWNLOAD_DNA_HEALTH_SUMMARY = 'Download DNA Health Summary',
  DOWNLOAD_FULL_REPORT = 'Download Full Report',
  DOWNLOAD_OFA_REPORT = 'Download OFA Report',
  DOWNLOAD_RAW_DATA = 'Download Raw Data',
  DOWNLOAD_VETERINARY_REPORT = 'Download Veterinary Report',
  EDIT_CLIENT_PATIENT_DETAILS = 'Edit Client Patient Details',
  EDIT_PROFILE = 'Edit Profile',
  MICROBIOME = 'Microbiome',
  EMAIL_EMBARK_REP = 'Email an Embark CSM',
  EMBARK_LOGO_HOME = 'Embark Logo Home Link',
  EMBARK_LOGO_VETS_PAGE = 'Embark Logo Vets Landing Page Link',
  EMBARK_WEBSITE = 'Embark Website',
  EXAM_ROOM_TALKING_POINTS_LINK = 'Link to Exam Room talking Points',
  FAQ = 'FAQ',
  FILTER_BY_ALL = 'Filter By All',
  FILTER_BY_NO_SWAB_ASSOCIATED = 'Filter By No Swab Associated',
  FILTER_BY_PROCESSING = 'Filter By Test Processing',
  FILTER_BY_TEST_RESULTS = 'Filter By Test Results',
  FINISH = 'Finish',
  FOOD_RECOMMENDATION_VIEWED = 'Viewed a Food Recommendation',
  GENETIC_CONDITIONS_HANDOUTS_LINK = 'Link to Genetic Conditions Handouts',
  GENETIC_TESTING_INTEGRATION_VIDEO_LINK = 'Link to Integrating Genetic Testing into Wellness Video',
  GENOTYPE_TEST_RECOMMENDATION = 'Genotype Test Recommendation',
  GUESS_THE_BREED = 'Guess the Breed',
  HEALTH = 'Health',
  HEALTH_RECORDS = 'Health Records',
  HEALTH_UPGRADE = 'Health Upgrade',
  HOW_TO_SHARE_RESULTS_WITH_CLIENT_LINK = 'Link to How to Share Results With Client',
  HOW_TO_SWAB_AND_ACTIVATE_LINK = 'Link to How to Swab and Activate',
  INBREEDING_AND_DIVERSITY = 'Inbreeding And Diversity',
  INSURANCE = 'Insurance',
  INVITE_CLIENT_ACCEPTED = 'Invite Client Accepted',
  INVITE_CLIENT_PENDING = 'Invite Client Pending',
  INVITE_CLIENT_TO_ACCOUNT = 'Invite Client To Account',
  LEARN_MORE = 'Learn More',
  LOG_OUT = 'Log Out',
  MATCHMAKER = 'Matchmaker',
  NAVIGATE = 'Navigate',
  NAVIGATE_BACK = 'Navigate Back',
  NEXT_PAGE = 'Next Page',
  NEXT_STEP = 'Next Step',
  NO_ACTIVATION_CODE = 'No Activation Code',
  NUTRITION = 'Nutrition',
  NUTRITION_DISCOVERY = 'Nutrition Discovery',
  NUTRITION_FOOD = 'Nutrition Food',
  NUTRITION_SUBMIT = 'Nutrition Submit',
  NUTRITION_WEIGHT = 'Nutrition Weight',
  OFA_REPORT = 'OFA Report',
  OPEN_WORKFLOW_DRAWER = 'Open Workflow Drawer',
  OPEN_VET_ONBOARDING_CHECKLIST = 'Open Vet Onboarding Checklist',
  ORDER_KITS = 'Order Kits',
  PAIR_PREDICTOR = 'Pair Predictor',
  PREVIEW_RESULTS = 'Preview Results',
  PREVIEW_VET_REPORT = 'Preview Vet Report',
  PREVIOUS_PAGE = 'Previous Page',
  PROFILE_DROPDOWN = 'Profile Dropdown',
  REFER_A_BREEDER = 'Refer a Breeder',
  REFER_A_FRIEND = 'Refer a Friend',
  REPLAY_BREED_REVEAL = 'Replay breed reveal',
  RELATIVES = 'Relatives',
  RESEARCH = 'Research',
  RESOURCE_CENTER = 'Resource Center',
  SAVE = 'Save',
  SAVE_AND_EXIT = 'Save and Exit',
  SAVE_DEFAULT_PRIVACY_SETTINGS = 'Save Default Privacy Settings',
  SCHEDULE_CONSULTATION = 'Schedule Consultation',
  SCHEDULE_GENETIC_HELP = 'Schedule Consultation With Geneticist',
  SCHEDULE_EMBARK_TRAINING = 'Email to Schedule Embark Training',
  SELECT_ACCOUNT_TYPE_BREEDER = 'Select Account Type Breeder',
  SELECT_ACCOUNT_TYPE_DOG_OWNER = 'Select Account Type Dog Owner',
  SELECT_ACCOUNT_TYPE_VETERINARIAN = 'Select Account Type Veterinarian',
  SHARE_CENTER = 'Share Center',
  SHARE_DOGS_LIKE_MINE = 'Share Dogs Like Mine',
  SHARE_RELATED_PAIR = 'Share Related Pair',
  SHARE_RESULTS_COPY_LINK = 'Share Results Copy Link',
  SHARE_RESULTS_DOWNLOAD = 'Share Results Download',
  SHARE_RESULTS_EMAIL = 'Share Results Email',
  SHARE_RESULTS_FACEBOOK = 'Share Results Facebook',
  SHARE_RESULTS_MODAL = 'Share Results Modal',
  SHARE_RESULTS_MODAL_BANNER = 'Share Results Modal Banner',
  SHARE_RESULTS_WITH_VET = 'Share Results With Vet',
  SHARE_RESULTS_WITH_VET_ACCORDION = 'Share Results With Vet Accordion',
  SHARE_TEASER_VIDEO_COPY_LINK = 'Share Teaser Video Copy Link',
  SHARE_TEASER_VIDEO_DOWNLOAD = 'Share Teaser Video Download',
  SHARE_TEASER_VIDEO_EMAIL = 'Share Teaser Video Email',
  SHARE_TEASER_VIDEO_FACEBOOK = 'Share Teaser Video Facebook',
  SHARE_TEASER_VIDEO_MODAL = 'Share Teaser Video Modal',
  SKIP_AND_FINISH = 'Skip and Finish',
  SKIP_ACTIVATION = 'Skip Activation',
  SKIP_STEP = 'Skip Step',
  START_NEW_PATIENT_NOTE = 'Start New Patient Note',
  START_SURVEY = 'Start Survey',
  START_SYNC_PATIENT = 'Start Sync Patient',
  STEPS_TO_TEST_DOGS_VIDEO = 'Link to swab video',
  SUBMIT = 'Submit',
  SUBMIT_FEEDBACK = 'Submit Feedback',
  SUMMARY = 'Summary',
  SUPPORT_EMAIL = 'Support Email',
  SUPPORT_HELP_CENTER = 'Support Help Center',
  SUPPORT_MODAL = 'Support Modal',
  SUPPORT_PHONE = 'Support Phone',
  SURVEYS = 'Surveys',
  TOGGLE_CASE_COMPLETE = 'Toggle Practice Pet Case Complete Status',
  TOGGLE_ACCORDION = 'Toggle Accordion',
  TRACKING_LINK = 'Kit Return Tracking Link',
  TRAITS = 'Traits',
  USER_SETTINGS = 'User Settings',
  VET_DASHBOARD_TUTORIAL_VIDEO_LINK = 'Link to the Vet Dashboard Tutorial video',
  VET_LANDING_PAGE_lINK = 'Link to Vet Landing Page',
  VET_MARKETING_MATERIALS_LINK = 'Link to Vet Marketing Materials',
  VET_PRODUCTS_PAGE_LINK = 'Link to Vet PDP',
  VET_REPORT = 'Vet Report',
  VIEW_AGE_RESULTS = 'View Age Results',
  VIEW_MY_EMBARK = 'View My Embark',
  VIEW_PROFILE = 'View Profile',
  VIEW_PROFILE_VIA_AVATAR = 'View Profile Via Avatar',
  VIEW_RESULTS = 'View Results',
  VISIT_EMBARK_ON_CALL_LINK = 'Visit Embark On Call Link',
}

export enum HeapCustomEvent {
  ACTIVATE_SWAB_ERROR = 'Activate Swab Error',
  ADD_PATIENT_ERROR = 'Add Patient Error',
  AGE_TEST_RESULT_FEEDBACK_ERROR = 'Age Test Result Feedback Error',
  CHANGE_SORT_BY = 'Change Sort By',
  CLOSE_MODAL = 'Close Modal',
  COMPLETED_KIT_ACTIVATION = 'Completed Kit Activation',
  DELETE_PATIENT_NOTE_ERROR = 'Delete Patient Note Error',
  EDIT_PATIENT_ERROR = 'Edit Patient Error',
  EXPAND_DOG_CARD = 'Expand Dog Card',
  GOOGLE_MAPS_API_PLACES_REQUEST = 'Google Maps API Places Request',
  GOOGLE_MAPS_API_LOAD = 'Google Maps API Load',
  INVITE_CLIENT_ERROR = 'Release pet to client error',
  PAGE_VIEW = 'Page View',
  START_KIT_ACTIVATION = 'Start Kit Activation',
  SUBMIT_ERROR = 'Submit Error',
  UPDATE_PET_ERROR = 'Update Pet Error',
  VET_ONBOARDING_CHECKLIST_FIELD_TOGGLE = 'Toggle Field in the Vet Onboarding Checklist',
  VIEW_CARE_RESOURCES_SUMMARY = 'View Care Resources Summary',
  VIEW_HEALTH_RESULT_PAGE = 'View Health Result Page',
}
