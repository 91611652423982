import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import { ImgHTMLAttributes, VFC } from 'react';

type ShareResultsWithVetLinkButtonProps = {
  label: string;
  slotProps: {
    root: ButtonProps;
    icon: ImgHTMLAttributes<HTMLImageElement> & {
      alt: NonNullable<ImgHTMLAttributes<HTMLImageElement>['alt']>;
      src: NonNullable<ImgHTMLAttributes<HTMLImageElement>['src']>;
    };
  };
};

export const ShareResultsWithVetLinkButton: VFC<ShareResultsWithVetLinkButtonProps> = (props) => {
  const { label, slotProps } = props;
  return (
    <Button
      variant="text"
      sx={{ flexGrow: 1, flexBasis: 0, textTransform: 'inherit', padding: 0, width: 'auto' }}
      {...slotProps.root}
    >
      <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center" sx={{ width: '100%' }}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img width={56} height={56} {...slotProps.icon} />
        <Typography
          variant="body2"
          whiteSpace="normal"
          textAlign="left"
          color={(theme) => theme.palette.helix.blueDark}
        >
          <b>{label}</b>
        </Typography>
      </Stack>
    </Button>
  );
};
