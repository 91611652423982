import 'projects/sharedComponents/src/geneticStats/lifeStage/lifeStageCalculationModal.scss';
import 'projects/sharedComponents/src/geneticStats/geneticStatsSection.scss';

import { FC, ReactElement } from 'react';
import {
  LifeStageCalculationAccordion,
  LifeStageCalculationAccordionProps,
} from 'projects/sharedComponents/src/geneticStats/lifeStage/lifeStageCalculationAccordion';

import { Typography } from '@mui/material';
import { buildEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';

const lifeStageAccordionContent: LifeStageCalculationAccordionProps[] = [
  {
    size: 'small',
    header: 'Small dogs',
    subtext: 'Up to 20 lbs',
    lifeStageCalculation: [
      { lifeStage: 'Puppy', text: 'Up to 6 months' },
      { lifeStage: 'Young adult', text: '6 months - 5 years' },
      { lifeStage: 'Mature adult', text: '5 - 11 years' },
      { lifeStage: 'Senior', text: '11 - 14 years' },
      { lifeStage: 'Geriatric', text: '14 years and over' },
    ],
  },
  {
    size: 'medium',
    header: 'Medium dogs',
    subtext: '21 to 50 lbs',
    lifeStageCalculation: [
      { lifeStage: 'Puppy', text: 'Up to 9 months' },
      { lifeStage: 'Young adult', text: '9 months - 4 years' },
      { lifeStage: 'Mature adult', text: '4 - 9 years' },
      { lifeStage: 'Senior', text: '9 - 12 years' },
      { lifeStage: 'Geriatric', text: '12 years and over' },
    ],
  },
  {
    size: 'large',
    header: 'Large dogs',
    subtext: '51-100 lbs',
    lifeStageCalculation: [
      { lifeStage: 'Puppy', text: 'Up to 15 months' },
      { lifeStage: 'Young adult', text: '15 months - 3 years' },
      { lifeStage: 'Mature adult', text: '3 - 8 years' },
      { lifeStage: 'Senior', text: '8 - 11 years' },
      { lifeStage: 'Geriatric', text: '11 years and over' },
    ],
  },
  {
    size: 'giant',
    header: 'Giant dogs',
    subtext: 'Over 100 lbs',
    lifeStageCalculation: [
      { lifeStage: 'Puppy', text: 'Up to 24 months' },
      { lifeStage: 'Young adult', text: '24 months - 3 years' },
      { lifeStage: 'Mature adult', text: '3 - 6 years' },
      { lifeStage: 'Senior', text: '6 - 8 years' },
      { lifeStage: 'Geriatric', text: '8 years and over' },
    ],
  },
];

export const LifeStageCalculationModalContent: FC = (): ReactElement => {
  return (
    <div className="lifeStageModal-container">
      <div className="lifeStageModal-iconContainer">
        <img className="lifeStageModal-icon" src={buildEmbarkCDNUrl('/img/age-test/lifeStagesIcon.png')} alt="" />
      </div>
      <Typography variant="h5" component="h2" className="lifeStageModal-header">
        <strong>The five life stages of a dog</strong>
      </Typography>
      <div className="lifeStageModal-textBlock">
        <Typography>
          A dog’s life stage depends not only on age, but on size. Select your dog’s{' '}
          <b>estimated full-grown weight range </b>
          to find out their life stage.
        </Typography>
      </div>
      {lifeStageAccordionContent.map((accordionProps, i) => {
        return <LifeStageCalculationAccordion key={i} {...accordionProps} />;
      })}
    </div>
  );
};
