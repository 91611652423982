import { categories, recordTypes } from '../../../../models/documents';

import Vue from 'vue';
import { range } from 'lodash';

declare const global: any;
declare const window: any;

interface DocumentProps {
  showRecordTypesAndYears: boolean;
  selectedCategory: string;
  years: number[];
  categories: string[];
  recordTypes: string[];
  selectedYear: number | string;
  description: string;
}

export function init(): void {
  (global as any).vueModel = new Vue({
    el: '#form-document-info',
    data: {
      description: window.description,
      categories,
      recordTypes,
      selectedCategory: window.selectedCategory,
      selectedRecordType: window.selectedRecordType,
      selectedYear: window.selectedYear,
      years: range(new Date().getFullYear(), 1990 - 1),
    },
    computed: {
      showRecordTypesAndYears: function (this: DocumentProps) {
        return this.selectedCategory === 'Health & Veterinary';
      },
      showPrivacyDisclaimer: function (this: DocumentProps) {
        return this.selectedCategory === 'Veterinary Verification Form';
      },
    },
  });

  const thumbnailImageSelector = $('.document-page .document-preview img');
  const thumbnailDefaultSelector = $('.document-page #document-thumbnail-default');

  thumbnailImageSelector
    .on('error', async function () {
      thumbnailImageSelector.hide();
      thumbnailDefaultSelector.show();

      await new Promise((resolve) => setTimeout(resolve, 5000));

      retryImgDownload(thumbnailImageSelector);
    })
    .on('load', async function () {
      thumbnailImageSelector.show();
      thumbnailDefaultSelector.hide();
    });
}

function retryImgDownload(imgSelector): void {
  imgSelector.attr('src', imgSelector.attr('src'));
}
