<template>
  <radio-select
    aria-label="Sex"
    v-model="value"
    @input="$emit('input', value)"
    inputIdPrefix="dogSexSelect-radio"
    name="sex"
    :options="sexOptions"
    :buttonClasses="buttonClasses"
  />
</template>
<script>
import Vue from 'vue';
import radioSelect from './input/radio-select.vue';
export default Vue.extend({
  components: { radioSelect },
  props: ['value', 'buttonClasses'],
  data() {
    return {
      sexOptions: [
        {
          value: 'm',
          label: 'Male',
        },
        {
          value: 'f',
          label: 'Female',
        },
      ],
    };
  },
});
</script>
