import { FC, useCallback, useLayoutEffect } from 'react';

import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { PetResultsNav } from '@embark/shared-components/src/pet/navigation/petResultsNav';
import ReactDOM from 'react-dom';
import { getPetNumFromQueryString } from 'projects/legacyClient/src/react/pet/getPetNumFromQueryString';
import { handleGraphQLError } from '@embark/shared-components/src/services/errors/handleGraphQLError';
import { useAppSelector } from 'projects/legacyClient/src/react/state/hooks';
import { useGetPetResultsNavDataQuery } from '@embark/shared-components/src/generated/graphql-client-types';

export function initPetResultsNav2022(): void {
  ReactDOM.render(
    <AppProviders>
      <PetResultsNavContainer />
    </AppProviders>,
    document.getElementById('petResultsNav2022')
  );
}

export const PetResultsNavContainer: FC = () => {
  const petNum = getPetNumFromQueryString();
  const accountDetails = useAppSelector((state) => state.accountDetails);
  const temporaryNav = document.getElementById('petResultsNav-temp');
  const hideTemporaryNav = useCallback(() => temporaryNav?.classList.add('petResultsNav-temp-hidden'), [temporaryNav]);

  const { data, error, loading } = useGetPetResultsNavDataQuery({
    variables: {
      petNum,
    },
  });

  useLayoutEffect(() => {
    if (!loading && !!data) {
      hideTemporaryNav();
    }
  }, [loading, data, hideTemporaryNav]);

  if (error) {
    handleGraphQLError(error);
    return <></>;
  }

  if (loading || !data) {
    return <></>;
  }

  if (!data.me || !data.me.petByPetNum) {
    return <></>;
  }

  return <PetResultsNav accountDetails={accountDetails} pet={data.me.petByPetNum} isReactPage={false} />;
};
