import 'projects/sharedComponents/src/publicProfile/breedSummary/publicProfileBreedSummaryBars.scss';

import {
  ProductType,
  PublicProfileBreedSummaryBarsFragment,
} from 'projects/sharedComponents/src/generated/graphql-client-types';

import { FC } from 'react';
import { PublicProfileBreedSummaryBar } from 'projects/sharedComponents/src/publicProfile/breedSummary/publicProfileBreedSummaryBar';
import { Typography } from '@mui/material';

type PublicProfileBreedSummaryBarsProps = {
  dog: PublicProfileBreedSummaryBarsFragment;
};

export const PublicProfileBreedSummaryBars: FC<PublicProfileBreedSummaryBarsProps> = ({ dog }) => {
  const isPurebredKit = dog.swabSummary.productType === ProductType.Purebred;
  return (
    <>
      {dog.genotype?.breedMix.map((breed) => {
        return (
          <div key={breed.colorIndex}>
            <Typography>
              {breed.displayBreedPercent} {breed.breedName}
            </Typography>
            <PublicProfileBreedSummaryBar
              colorIndex={breed.colorIndex}
              width={breed.barWidthPercentage}
              isPurebredKit={isPurebredKit}
            />
          </div>
        );
      })}
    </>
  );
};
