import { useBoolean, useEffectOnce } from 'react-use';

import { toNumber } from 'lodash';
import { useGetPetUuidQuery } from '@embark/shared-components/src/generated/graphql-client-types';
import { useLocation } from 'react-router-dom';

type UsePetUuidReturn = {
  petUuid?: string;
  hasTriedLoadingPetUuid: boolean;
};

export function usePetUuid(): UsePetUuidReturn {
  const [hasTriedLoadingPetUuid, setHasTriedLoadingPetUuid] = useBoolean(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const petNum = params.get('i');

  useEffectOnce(() => {
    setHasTriedLoadingPetUuid(!petNum);
  });

  const { data } = useGetPetUuidQuery({
    skip: !petNum,
    variables: {
      petNum: toNumber(petNum),
    },
    onCompleted: () => {
      setHasTriedLoadingPetUuid(true);
    },
  });

  return {
    petUuid: data?.me.petByPetNum?.id,
    hasTriedLoadingPetUuid,
  };
}
