import PasswordShowHide from './vue-components/password-show-hide.vue';
import Vue from 'vue';

export function init(): void {
  new Vue({
    el: '#signin-signup-password',
    components: {
      PasswordShowHide,
    },
  });
}
