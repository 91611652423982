import { EditPetLocationModal } from 'projects/legacyClient/src/react/pet/location/petLocationModal';
import { FC } from 'react';
import { PetLocationInformationFragment } from '@embark/shared-components/src/generated/graphql-client-types';
import { RenderIf } from '@embark/shared-components/src/renderStates/renderIf';
import { useToggle } from 'react-use';

type EditPetLocationModalButtonProps = {
  pet: PetLocationInformationFragment;
};

export const EditPetLocationModalButton: FC<EditPetLocationModalButtonProps> = ({ pet }) => {
  const [isModalOpen, toggleModalOpen] = useToggle(false);

  const handleClick = (): void => {
    toggleModalOpen();
  };

  return (
    <>
      <button className="button-text-link" onClick={handleClick}>
        Edit
      </button>
      <RenderIf condition={isModalOpen}>
        <EditPetLocationModal open={isModalOpen} toggleModal={toggleModalOpen} locations={pet.locations} />
      </RenderIf>
    </>
  );
};
