import 'projects/sharedComponents/src/footer/rafBanner.scss';

import { Button, Grid, Typography } from '@mui/material';
import { buildEmbarkCDNUrl, buildMyEvLegacyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';

import { FC } from 'react';
import { FooterUserFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';

type RafBannerProps = {
  user: FooterUserFragment;
};
export const RafBanner: FC<RafBannerProps> = ({ user }) => {
  return (
    <div className="rafBanner-container">
      <Grid className="rafBanner-grid">
        <Grid item>
          <img className="rafBanner-img" src={buildEmbarkCDNUrl('/img/pet/raf-banner-pet.png')} alt="" width={80} />
        </Grid>
        <Grid item>
          <Typography className="rafBanner-header">Give $50, Get $10</Typography>
          <Typography className="rafBanner-text">
            Give friends $50 off their first order, and you'll get $10 to Amazon when they make their first purchase.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            className="rafBanner-button"
            href={buildMyEvLegacyLink(`/members/referrals?countryCode=${user.countryCode}`)}
            variant="contained"
          >
            Refer a Friend
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
