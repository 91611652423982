import * as EmbarkUrlUtilsVue from './vue-components/plugins/url';

import BehaviorSection from './vue-components/genetic-comparison/behavior-section.vue';
import ShowMore from './vue-components/show-more.vue';
import ShowMoreByCount from './vue-components/show-more-by-count.vue';
import Vue from 'vue';

export function init(): void {
  Vue.use(EmbarkUrlUtilsVue);
  new Vue({
    el: '#geneticComparisonContainer',
    data: {},
    components: {
      BehaviorSection,
      ShowMore,
      ShowMoreByCount,
    },
  });
}
