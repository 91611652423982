<template>
  <div :class="[buttonContainerClasses]">
    <input hidden name="areBothParentsRegistered" :value="selectedValue" />
    <a :class="['button', buttonClasses, selectedValue === 'yes' ? 'selected' : '']" @click="selectYes" href="#">Yes</a>
    <a :class="['button', buttonClasses, selectedValue === 'no' ? 'selected' : '']" @click="selectNo" href="#">No</a>
  </div>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['buttonClasses', 'buttonContainerClasses'],
  mounted() {
    const areBothParentsRegistered = this.$el.getAttribute('data-areBothParentsRegistered');
    if (areBothParentsRegistered === 'true') {
      this.selectedValue = 'yes';
      return;
    }
    if (areBothParentsRegistered === 'false') {
      this.selectedValue = 'no';
      return;
    }
    this.selectedValue = null;
  },
  data() {
    return {
      selectedValue: null,
    };
  },
  methods: {
    selectYes() {
      if (this.selectedValue === 'yes') {
        this.selectedValue = null;
        return;
      }
      this.selectedValue = 'yes';
    },
    selectNo() {
      if (this.selectedValue === 'no') {
        this.selectedValue = null;
        return;
      }
      this.selectedValue = 'no';
    },
  },
});
</script>
<style scoped>
.button {
  background-color: transparent;
  color: black;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  border: 1px solid var(--embark-light-grey);
}

.selected {
  border: 4px solid var(--embark-yellow);
}
</style>
