export function getCsrfToken(): string {
  const csrfToken = document.head.querySelector<HTMLMetaElement>('meta[name="_csrf"]')?.content;
  return csrfToken || '';
}

export function addHiddenCsrfInputFieldToEveryForm(): void {
  const csrfToken = getCsrfToken();
  if (csrfToken === '') {
    return;
  }

  for (const form of Array.from(document.forms)) {
    const hiddenCsrfInputField = document.createElement('input');
    hiddenCsrfInputField.type = 'hidden';
    hiddenCsrfInputField.name = '_csrf';
    hiddenCsrfInputField.value = csrfToken;
    form.appendChild(hiddenCsrfInputField);
  }
}
