<template>
  <section aria-label="behavior" class="stats-block">
    <h2 class="header-2 small-space-below">Behavior</h2>
    <p v-if="dogsHaveSharedBehaviors">
      According to their owners, {{ dogLeftName }} and {{ dogRightName }} both share some of the same behavioral
      patterns, including:
    </p>

    <section>
      <show-more v-if="dogsHaveSharedBehaviors" collapsed-height="8.6">
        <ul class="behaviorSection-behaviorList grid-x">
          <li
            v-for="behaviorName in sharedBehaviorNames"
            :key="behaviorName"
            class="behaviorSection-behaviorListItem embarkPill embarkPill-active cell shrink"
          >
            {{ behaviorName }}
          </li>
        </ul>
      </show-more>

      <template v-else>
        <div class="grid-x grid-margin-x align-top">
          <p class="behaviorSection-noBehaviorsCallout cell auto">
            Based on the information we have from their owners, {{ dogLeftName }} and {{ dogRightName }} don’t share any
            of the same behaviors.
          </p>
          <img
            v-if="isEitherMyDog"
            class="cell shrink"
            :src="`${imgHost}/icons/puzzle-piece.svg`"
            alt="Puzzle Piece Icon"
          />
          <p v-if="isEitherMyDog" class="cell small-12 medium-11">
            Owners provide us with this information by taking our research surveys, which also contribute to our ongoing
            research efforts. Make sure {{ myDog.dogName }}’s information is up-to-date by completing
            {{ myDog.dogHisOrHer }} surveys today!
          </p>
        </div>

        <div v-if="isEitherMyDog" class="grid-x small-space-above align-left">
          <a
            class="button-secondary small-12 medium-shrink"
            :href="`/members/results/research-surveys?i=${myDog.petNum}`"
          >
            Take Our Research Surveys
          </a>
        </div>
      </template>
    </section>
  </section>
</template>

<script>
import OverlayLoader from '../loader/overlay-loader.vue';
import ShowMore from '../show-more.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'behavior-section',
  components: {
    OverlayLoader,
    ShowMore,
  },
  props: {
    dogLeftHandle: String,
    dogRightHandle: String,
    dogLeftName: String,
    dogLeftPetNum: String,
    dogRightName: String,
    dogRightPetNum: String,
    dogLeftHisOrHer: String,
    dogRightHisOrHer: String,
    leftIsMyDog: Boolean,
    rightIsMyDog: Boolean,
    sharedBehaviorNames: Array,
  },
  computed: {
    myDog() {
      if (this.leftIsMyDog) {
        return { dogName: this.dogLeftName, petNum: this.dogLeftPetNum, dogHisOrHer: this.dogLeftHisOrHer };
      }
      if (this.rightIsMyDog) {
        return { dogName: this.dogRightName, petNum: this.dogRightPetNum, dogHisOrHer: this.dogRightHisOrHer };
      }
      return null;
    },
    isEitherMyDog() {
      return !!this.myDog;
    },
    dogsHaveSharedBehaviors() {
      return !!this.sharedBehaviorNames && this.sharedBehaviorNames.length > 0;
    },
  },
});
</script>

<style scoped>
.behaviorSection-behaviorList {
  list-style: none;
  margin-left: 0;
}

.behaviorSection-behaviorListItem {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.behaviorSection-noBehaviorsCallout {
  font-weight: bold;
}
</style>
