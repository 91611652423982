const axios = require('axios');

import Vue from 'vue';
const gauge = require('./vue-components/relatedness-gauge');
const { svgdogavatar } = require('./svgdogavatar');
gauge.register(Vue);

Vue.component('related-dog-card', {
  props: ['pet', 'dogAHandle'],
  computed: {
    relatedUrl() {
      return `/related/${this.dogAHandle}/${this.pet.handle}`;
    },
    imageSrc() {
      return `/getpublicpetimg/${this.pet.handle}`;
    },
    showBreed1Pct() {
      return (
        (!this.pet.breedDisplayName || this.pet.breedDisplayName.toLowerCase() === 'mixed breed') && this.pet.breed1pct
      );
    },
    missingImageAvatar() {
      return this.pet.hasPublicImage ? '' : svgdogavatar(1, 125);
    },
  },
  template: `
  <li>
    <a :href="this.relatedUrl" class="relatives-dogCard grid-x grid-padding-x align-center text-black">
      <figure class="cell small-3 text-center">
        <img v-if="pet.hasPublicImage" class="dog-face-img dog-pic-md-round" :src="imageSrc" :alt="pet.displayName" />
        <div v-else v-html="missingImageAvatar"></div>
      </figure>
      <div class="cell auto">
        <p style="line-height: 1.2;" class="pointer">
          <strong v-bind:class="[ 'text-' + this.pet.relatednessCategory.color ]">{{pet.relatednessCategory.label}}</strong>
          <br/>
          <span class="text-medium-gray"><small style="line-height: 1.4; display: inline-block;">{{pet.relatednessCategory.explanation}}</small></span>
        </p>
        <div class="dog-name-md" style="text-align: left; line-height: 1.1;">
          {{pet.displayName}}
        </div>
        <p v-if="pet.breedDisplayName" class="text-black no-space-below" style="line-height: 1.4;">
          {{pet.breedDisplayName}}
        </p>
        <p v-if="showBreed1Pct" class="text-dark-gray text-small" style="line-height: 1.2;">
          {{pet.breed1pct}}% {{pet.breed1displayName}}
        </p>
      </div>
      <div class="cell small-3 medium-shrink text-right">
        <div class="grid-x align-right relatives-sharedDnaPercentContainer">
          <div class="cell small-12 medium-shrink small-order-2 medium-order-1 text-black" style="line-height: 1;">
            <div class="relatives-sharedDnaPercent" style="font-weight: 600;">
              {{pet.displayRelatedness}}%
            </div>
            <span class="relatives-sharedDnaLabel small-caps">
              Shared DNA
            </span>
          </div>
          <figure class="cell small-12 medium-shrink small-order-1 medium-order-2" style="margin-left: 10px;">
            <relatedness-gauge v-bind:fill-level="pet.displayRelatedness"></relatedness-gauge>
          </figure>
        </div>
        <p class="relatives-testDate text-medium-gray show-for-medium">Embark dog since<br/>{{pet.nonhealthReadyAt}}</p>
      </div>
    </a>
  </li>`,
});

const NUM_DOGS_PER_LOAD = 5;

export function init() {
  const data = {
    relatives: [],
    relativesShown: [],
    // when the user hits the 'show more' button we will increase these values
    howManyToShow: NUM_DOGS_PER_LOAD,
    initialLoadComplete: false,
    loadingMore: false,
    newRelatedPets: [],
    error: '',
    showCloseRelativesWarning: false,
  };
  const url = `/api/relatives/${global.petNumToGetRelativesFor}`;

  // call showRelatives after changing howManyToShow
  function showRelatives() {
    data.relativesShown = data.relatives.slice(0, data.howManyToShow);
    data.loadingMore = false;
  }

  global.vueModel = new Vue({
    el: '#relatives-container',
    data,
    methods: {
      showMore() {
        data.loadingMore = true;
        data.howManyToShow += NUM_DOGS_PER_LOAD;
        // wait 1sec for data to 'load'
        setTimeout(showRelatives, 1000);
      },
    },
    async created() {
      const { relatedPets, newRelatedPets } = (await axios.get(url)).data;
      data.relatives = relatedPets;
      data.newRelatedPets = newRelatedPets;
      data.initialLoadComplete = true;
      const numCloseRelatives = relatedPets.filter(
        (p) => p.relatednessCategory.label.toLowerCase() === 'close family'
      ).length;
      data.showCloseRelativesWarning = numCloseRelatives > 4;
      showRelatives();
    },
  });
}
