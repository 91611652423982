import { useCallback, useState } from 'react';

import { AccountDetails } from 'clientServerShared/types/account';
import { getEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';

export function getBreederHeaderTitle(accountDetails: AccountDetails): string {
  const kennelName = accountDetails.user?.breeder?.name || '';

  const hasKennelName = kennelName.length > 0;
  if (hasKennelName) {
    return kennelName;
  }
  return 'My Embark for Breeders';
}

type HeaderReturn = {
  chatBubbleAriaLabel: string;
  desktopLogoSrc: string;
  mobileLogoSrc: string;
  title: string;
};

export function useHeader(accountDetails: AccountDetails, title?: string): HeaderReturn {
  const embarkCdnUrl = getEmbarkCDNUrl();

  const numUnreadMessages = accountDetails.user?.numUnreadMessages ?? 0;

  const mobileLogoSrc = `${embarkCdnUrl}/img/logo/embark-logo-dog-yellow.svg`;
  const desktopLogoSrc = `${embarkCdnUrl}/img/logo/embark-logo-rectangle-yellow-dog-black-text.svg`;

  const unreadMessagesText =
    numUnreadMessages > 0 ? `${numUnreadMessages} new message${numUnreadMessages > 1 ? 's' : ''}` : 'No new messages';
  const chatBubbleAriaLabel = `Open Chat - ${unreadMessagesText}`;

  const defaultTitle = accountDetails.user?.permissions.isBreeder ? getBreederHeaderTitle(accountDetails) : 'My Embark';

  return {
    chatBubbleAriaLabel,
    desktopLogoSrc,
    mobileLogoSrc,
    title: title ?? defaultTitle,
  };
}

type MobileHeaderReturn = {
  drawerIsOpen: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
};

export function useDrawerOpenClose(): MobileHeaderReturn {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const handleDrawerOpen = useCallback(() => setDrawerIsOpen(() => true), []);
  const handleDrawerClose = useCallback(() => setDrawerIsOpen(() => false), []);

  return {
    drawerIsOpen,
    handleDrawerClose,
    handleDrawerOpen,
  };
}
