import 'projects/sharedComponents/src/profileDataSetting/profileDataSettingsCard.scss';

import * as React from 'react';

import { FormControlLabel, Grid, Link, Switch, Typography } from '@mui/material';
import {
  PetPreferencesInput,
  ProfileDataSettingsCardFragment,
  ProfileDataSettingsCardUserFragment,
  useUpdateProfileDataSettingsMutation,
} from 'projects/sharedComponents/src/generated/graphql-client-types';

import { FC } from 'react';

type ProfileDataSettingsCardProps = {
  user: ProfileDataSettingsCardUserFragment;
  pet: ProfileDataSettingsCardFragment;
};

export const ProfileDataSettingsCard: FC<ProfileDataSettingsCardProps> = ({ user, pet }) => {
  const toggleLabel = (value: boolean): string => {
    if (value) {
      return 'Yes';
    }
    return 'No';
  };

  const [updateProfileDataSettings, {}] = useUpdateProfileDataSettingsMutation();

  const onChangeUpdateResultsSettings = async (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ): Promise<void> => {
    const preferences: PetPreferencesInput = {
      shareCareResourcesProfileAndResults: value,
    };

    await updateProfileDataSettings({
      variables: { petNum: pet.petNum, preferences },
    });
  };

  const onChangeUpdateSurveySettings = async (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ): Promise<void> => {
    const preferences: PetPreferencesInput = {
      shareCareResourcesSurveys: value,
    };

    await updateProfileDataSettings({
      variables: { petNum: pet.petNum, preferences },
    });
  };

  if (!pet.shouldShowCareResources) {
    return <></>;
  }

  return (
    <>
      <div className="gray-spacer"></div>
      <div className="profileDataSettingsCard-container">
        <Typography variant="h3" component="h2" className="profileDataSettingsCard-header">
          Data Settings
        </Typography>
        <Typography>
          Embark may use your dog’s data in order to deliver care resources that are relevant and personalized for your
          pet. These care resources will include articles and product recommendations based on specific test results and
          Embark’s expertise. Our{' '}
          <Link className="profileDataSettingsCard-link" href={user.ecommLink + '/tos'} rel="noopener" target="_blank">
            Terms of Service
          </Link>{' '}
          explain what we will (and won't) do with your dog's data.
        </Typography>
        <Typography className="profileDataSettingsCard-newLine">
          What data would you like us to use for personalized recommendations?
        </Typography>
        <Grid container direction="column">
          <Grid container direction="row">
            <Grid item xs={7} className="profileDataSettingsCard-toggleText">
              <Typography className="profileDataSettingsCard-toggleName">My dog’s profile and test results</Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={pet.preferences.shareCareResourcesProfileAndResults}
                    onChange={onChangeUpdateResultsSettings}
                    name="petResultsDataSetting"
                    color="secondary"
                  />
                }
                label={toggleLabel(pet.preferences.shareCareResourcesProfileAndResults)}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={7} className="profileDataSettingsCard-toggleText">
              <Typography className="profileDataSettingsCard-toggleName">Survey answers</Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={pet.preferences.shareCareResourcesSurveys}
                    onChange={onChangeUpdateSurveySettings}
                    name="petSurveyDataSetting"
                    color="secondary"
                  />
                }
                label={toggleLabel(pet.preferences.shareCareResourcesSurveys)}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
