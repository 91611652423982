import {
  getDataAttributeOrThrow,
  getDataAttributeOrUndefined,
} from 'projects/legacyClient/src/react/services/attributeHelpers';

import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { FC } from 'react';
import { FeedbackBannerContents } from '@embark/shared-components/src/feedback/feedbackBannerContents';
import { FeedbackItem } from 'graphql/generated/graphql-base-types';
import { HeapCategory } from 'clientServerShared/types/eventTracking';
import ReactDOM from 'react-dom';
import { RenderIf } from '@embark/shared-components/src/renderStates/renderIf';
import { usePetUuid } from 'projects/legacyClient/src/react/hooks/usePetUuid';

export function initFeedbackBanner(): void {
  ReactDOM.render(
    <AppProviders>
      <FeedbackBannerContainer />
    </AppProviders>,
    document.getElementById('feedback-banner')
  );
}

const FeedbackBannerContainer: FC = () => {
  const feedbackElement = document.getElementById('feedback-banner');
  const page = getDataAttributeOrThrow(feedbackElement, 'page');
  const variant = getDataAttributeOrUndefined(feedbackElement, 'variant');
  const helpfulFeedbackItems = getFeedbackItems(feedbackElement, 'helpfulFeedbackItems');
  const unhelpfulFeedbackItems = getFeedbackItems(feedbackElement, 'unhelpfulFeedbackItems');
  const heapCategory = getDataAttributeOrThrow(feedbackElement, 'heapCategory') as HeapCategory;
  const helpfulConfirmationText = getDataAttributeOrUndefined(feedbackElement, 'helpfulConfirmationText');
  const unhelpfulConfirmationText = getDataAttributeOrUndefined(feedbackElement, 'unhelpfulConfirmationText');

  const { petUuid, hasTriedLoadingPetUuid } = usePetUuid();

  return (
    <RenderIf condition={hasTriedLoadingPetUuid}>
      <FeedbackBannerContents
        petUuid={petUuid}
        page={page}
        variant={variant}
        helpfulFeedbackItems={helpfulFeedbackItems}
        unhelpfulFeedbackItems={unhelpfulFeedbackItems}
        heapCategory={heapCategory}
        helpfulConfirmationText={helpfulConfirmationText}
        unhelpfulConfirmationText={unhelpfulConfirmationText}
      />
    </RenderIf>
  );
};

function getFeedbackItems(feedbackElement: HTMLElement | null, dataName: string): FeedbackItem[] {
  return getDataAttributeOrThrow(feedbackElement, dataName).split(',') as FeedbackItem[];
}
