import Vue from 'vue';

const bus: Vue = new Vue();
const listeners: Record<string, number> = {};

/**
 * A global event bus for sending and receiving
 * events from Vue components.
 * @see https://www.digitalocean.com/community/tutorials/vuejs-global-event-bus
 */
export const EventBus = {
  emit: (eventName: string, ...payload: any[]): void => {
    if (listeners[eventName]) {
      bus.$emit(eventName, ...payload);
    }
  },
  addListener: (eventName: string, listener: () => void): void => {
    if (!listeners[eventName]) {
      listeners[eventName] = 0;
    }
    listeners[eventName]++;
    bus.$on(eventName, listener);
  },
  removeListener: (eventName: string, listener: () => void): void => {
    if (listeners[eventName] && listeners[eventName] > 0) {
      listeners[eventName]--;
    }
    bus.$off(eventName, listener);
  },
};

export enum Events {
  DateIsEstimatedChanged = 'date-is-estimated-changed',
}
