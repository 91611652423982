import { FC } from 'react';
import { Footer } from 'projects/sharedComponents/src/footer/footer';
import { handleGraphQLError } from 'projects/sharedComponents/src/services/errors/handleGraphQLError';
import { toNumber } from 'lodash';
import { useGetFooterDataQuery } from 'projects/sharedComponents/src/generated/graphql-client-types';

export const FooterContainer: FC = () => {
  const params = new URLSearchParams(location?.search ?? '');

  let shouldIncludePetLinks = true;

  let petNum = toNumber(params.get('i'));

  // If we have no pet num, we disable the loading of the pet info.
  if (isNaN(petNum) || petNum <= 0) {
    petNum = 0;
    shouldIncludePetLinks = false;
  }

  const { data, loading, error } = useGetFooterDataQuery({
    variables: {
      petNum: petNum,
      shouldLoadPet: shouldIncludePetLinks,
    },
  });

  if (error) {
    handleGraphQLError(error);
    return <></>;
  }

  if (loading || !data) {
    return <></>;
  }

  return <Footer isSpaPage={false} pet={data.me.petByPetNum ?? null} user={data.me} />;
};
