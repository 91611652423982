import { Dialog } from 'projects/sharedComponents/src/dialog/dialog';
import { FC } from 'react';
import { LifeStageCalculationModalContent } from 'projects/sharedComponents/src/geneticStats/lifeStage/lifeStagesCalculationModalContent';

type LifeStageCalculationModalProps = {
  open: boolean;
  onClose: () => void;
};

export const LifeStageCalculationModal: FC<LifeStageCalculationModalProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" aria-labelledby="lifeStageCalculationModal-header">
      <LifeStageCalculationModalContent />
    </Dialog>
  );
};
