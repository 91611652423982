import { VFC } from 'react';

export const DefaultProfilePictureIcon: VFC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 125 125"
      role="img"
      aria-label="Default dog avatar"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Default dog avatar</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-125.000000, -79.000000)">
          <g transform="translate(125.000000, 79.000000)">
            <path
              d="M80.431359,57.1818011 C80.5224038,57.0305614 80.6480606,56.9071619 80.7955381,56.8221367 C80.9430155,56.7371114 81.1123136,56.6897079 81.2891361,56.6859457 L97,56.6859457 L97,48.1014033 L81.2891361,48.1014033 C81.1123136,48.0976411 80.9430155,48.0502376 80.7955381,47.9652124 C80.6480606,47.8794347 80.5224038,47.7567876 80.431359,47.605548 L72.5759271,34 L65.1411068,38.2926474 L72.9965388,51.8981954 C73.0823165,52.0524448 73.1252054,52.2232478 73.1252054,52.3932983 C73.1252054,52.5641013 73.0823165,52.7341518 72.9965388,52.8884012 L66.6655422,63.8536512 C66.5744974,64.0041384 66.4488406,64.1275379 66.3013632,64.2125632 C66.1538857,64.2975884 65.9845876,64.3449919 65.8077651,64.3487541 L53.1472768,64.3487541 C52.9712068,64.3449919 52.8019087,64.2975884 52.6544312,64.2125632 C52.5069538,64.1267855 52.3805445,64.0041384 52.2894998,63.8528988 L44.4348202,50.2473508 L37,54.5399982 L44.8546795,68.1455462 C44.9404572,68.2997956 44.9840985,68.4705986 44.9840985,68.6406491 C44.9840985,68.8114521 44.9404572,68.9815026 44.855432,69.1365044 L37,82.7420524 L44.4348202,87.0346998 L52.2894998,73.4291519 C52.3805445,73.2779122 52.5069538,73.1552652 52.6544312,73.0694875 C52.8019087,72.9844622 52.9712068,72.9370587 53.1472768,72.9332965 L65.8077651,72.9332965 C65.9845876,72.9370587 66.1538857,72.9844622 66.3013632,73.0694875 C66.4495931,73.1545127 66.5752499,73.2771598 66.6662946,73.4283994 L74.5217266,87.0346998 L81.9565468,82.7420524 L74.1011149,69.1365044 C74.0153372,68.9815026 73.9716959,68.8114521 73.9716959,68.6406491 C73.9716959,68.4705986 74.0153372,68.2997956 74.1011149,68.1455462 L80.431359,57.1818011 Z"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
