import 'projects/sharedComponents/src/feedback/feedbackDialogChip.scss';

import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { AddFeedbackFormInput } from 'projects/sharedComponents/src/feedback/feedbackHooks';
import { FC } from 'react';
import { FeedbackItem } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { HeapCategory } from 'clientServerShared/types/eventTracking';
import { getHeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';

const feedbackItemToChipText: Record<FeedbackItem, string> = {
  [FeedbackItem.Specific]: 'It was specific to my dog',
  [FeedbackItem.ProductRecommendations]: 'Product recommendations',
  [FeedbackItem.LearnedSomething]: 'I learned something new',
  [FeedbackItem.EasyToUnderstand]: 'Easy to understand',
  [FeedbackItem.WillUse]: 'I will use this information',
  [FeedbackItem.PutAtEase]: 'Put me at ease',
  [FeedbackItem.NotSpecific]: 'Not specific to my dog',
  [FeedbackItem.Advertising]: 'Feels like advertising',
  [FeedbackItem.DidNotLearnAnything]: "I didn't learn anything new",
  [FeedbackItem.TooLong]: 'Too long',
  [FeedbackItem.AlreadyOwn]: 'I already own these products',
  [FeedbackItem.Worried]: 'Makes me worried',
  [FeedbackItem.Party]: 'Ready to plan a party',
  [FeedbackItem.Happy]: 'Surprised but happy',
  [FeedbackItem.Relieved]: 'Relieved',
  [FeedbackItem.Sad]: 'Sad or concerned',
  [FeedbackItem.Doubtful]: 'Doubtful',
  [FeedbackItem.InformedBreedingDecisions]: 'I can make more informed breeding decisions',
  [FeedbackItem.ExpectedLitter]: 'I know what to expect in my litter',
  [FeedbackItem.NotAccurate]: 'This information is not accurate',
  [FeedbackItem.Confused]: "I'm confused by this information",
  [FeedbackItem.NoResults]: 'I did not receive one or more results',
  [FeedbackItem.InformationNotSpecific]: "This information isn't specific to my dog(s)",
};

type FeedbackDialogChipProps = {
  heapCategory: HeapCategory;
  feedbackItem: FeedbackItem;
};

export const FeedbackDialogChip: FC<FeedbackDialogChipProps> = ({ feedbackItem, heapCategory }) => {
  const { control } = useFormContext<AddFeedbackFormInput>();
  const label = feedbackItemToChipText[feedbackItem];

  return (
    <Controller
      key={feedbackItem}
      name={`selectedFeedback.${feedbackItem}`}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          className={`feedbackDialogChip-label ${!!field.value ? 'checked' : ''}`}
          label={label}
          key={feedbackItem}
          control={
            <Checkbox
              className="feedbackDialogChip-checkbox"
              onChange={(event) => field.onChange(event.target.checked ? feedbackItem : undefined)}
              checked={!!field.value}
              value={feedbackItem}
              {...getHeapDataAttributes({
                heapCategory,
                heapAction: 'Select Feedback Chip',
                heapDetails: feedbackItem,
              })}
            />
          }
        />
      )}
    />
  );
};
