import 'babel-core/register';
import 'babel-polyfill';
import 'foundation-sites';
import 'foundation-sites/scss/foundation.scss';
import 'intl-tel-input/build/css/intlTelInput.css';
import './scss/app.scss';

import * as $ from 'jquery';
import * as AboutPetDetails from './js/aboutPetDetails';
import * as AboutPetHidePet from './js/aboutPetHidePet';
import * as AboutPetNameAndStory from './js/aboutPetNameAndStory';
import * as AboutPetPassedAway from './js/aboutPetPassedAway';
import * as AboutPetPrivacy from './js/aboutPetPrivacy';
import * as AboutPetProfilePicture from './js/aboutPetProfilePicture';
import * as AdminShippingSerialScanning from './js/admin/shipping/serial-scanning';
import * as AdminShippingSerialScanningSearch from './js/admin/shipping/serial-scanning-search';
import * as CopyLinkButtons from './js/copyLinkButtons';
import * as Csrf from './js/csrf';
import * as DetectedInBreedCircle from './js/detectedInBreedCircle';
import * as DisableFormSubmitOnEnterKey from './js/disableFormSubmitOnEnterKey';
import * as GeneticComparison from './js/geneticComparison';
import * as PetLocation from './js/petLocation';
import * as ProfileViewTrendChart from './js/profile-view-trend-chart';
import * as ShareCenter from './js/shareCenter';
import * as adminDataTables from './js/admin-datatables';
import * as breederDogSettings from './js/breeder-dog-settings';
import * as breedresults from './js/breedresults';
// import Foundation from 'foundation-sites'  // don't import, since that causes us to compile Foundation which is not only slow, but causes Foundation to import a separate copy of jquery from the node_modules/foundation-sites folder, and having two instances of the jquery code messes everything up. Instead we do a <script> tag include in the page
import * as breedwheel from './js/breedwheel';
import * as chat from './js/chat'; // convert to breeder account
import * as documents from './js/document';
import * as galleryLikeButtons from './js/gallery-like-buttons';
import * as matchmaker from './js/matchmaker';
import * as notificationPreferences from './js/profile/notificationPreferences';
import * as pairRelativeFinder from './js/pairRelativeFinder';
import * as relativeFinder from './js/relative-finder';
import * as results from './js/results';
import * as signinup from './js/signinup';
import * as youtubeViewTracking from './js/youtube-view-tracking';

import {
  initShareResultsWithVetCard,
  shareResultsWithVetCardId,
} from 'projects/legacyClient/src/react/shareResultsWithVet/shareResultsWithVet';

import { AboutPetCardModalId } from 'clientServerShared/types/aboutPet';
import { Pet } from 'clientServerShared/types/pets';
import { bindClientHealthMonitorBeforeAppStart } from './js/clientHealthMonitor';
import { createBunyanErrorMonitorStream } from 'clientServerShared/services/healthMonitor';
import { initBackToLink } from 'projects/legacyClient/src/react/pet/navigation/backToLinkContainer';
import { initBackToMyDogsLink } from 'projects/legacyClient/src/react/pet/navigation/backToMyDogsLinkContainer';
import { initCareResourcesTraitsResultsBanner } from 'projects/legacyClient/src/react/pet/traits/careResourcesResultsBannerContainer';
import { initFeedbackBanner } from 'projects/legacyClient/src/react/feedback/feedback';
import { initFooter } from 'projects/legacyClient/src/react/footer/footerContainer';
import { initGeneticStatsSection } from 'projects/legacyClient/src/react/pet/geneticStats/geneticStatsSectionContainer';
import { initPetLocationModal } from 'projects/legacyClient/src/react/pet/location/petLocationContainer';
import { initPetResultsNav2022 } from 'projects/legacyClient/src/react/pet/navigation/petResultsNavContainer';
import { initProfileDataSettingsCard } from 'projects/legacyClient/src/react/pet/profileDataSettings/profileDataSettingsContainer';
import { initPublicProfileBreedRevealBannerContainer } from 'projects/legacyClient/src/react/publicProfile/publicProfileBreedRevealBannerContainer';
import { initPublicProfileBreedSummaryCard } from 'projects/legacyClient/src/react/publicProfile/publicProfileBreedSummaryCardContainer';
import { initPublicProfileDigInBanner } from 'projects/legacyClient/src/react/publicProfile/publicProfileDigInBannerContainer';
import { initPublicProfileGeneticStatsSection } from 'projects/legacyClient/src/react/publicProfile/publicProfileGeneticStatsSectionContainer';
import { initSignupBackButton } from './js/signupBackButton';
import { initVeterinaryInformationModal } from 'projects/legacyClient/src/react/pet/veterinaryInformation/veterinaryInformationContainer';
import { log } from './js/browserLogger';
import { registerHeapPageview } from '@embark/shared-components/src/services/eventTracking/eventTracking';

// Make jQuery globally available for DataTables and Foundation.
(global as any).$ = (global as any).jQuery = $;
window.$ = window.jQuery = $;

const clientErrorMonitor = bindClientHealthMonitorBeforeAppStart();
log.addStream({
  name: 'errorMonitor',
  level: 'error',
  stream: createBunyanErrorMonitorStream(clientErrorMonitor),
});

function getQueryStringVariable(haystack: string, needle: string): string | null {
  return haystack
    .substr(1)
    .split('&')
    .reduce((p: string | null, c: string) => {
      const [name, value] = c.split('=');
      if (decodeURIComponent(name) === needle) {
        p = decodeURIComponent(value);
      }
      return p;
    }, null);
}

function onRetryableDocumentReady(attempt): void {
  const missing = (window.d3 ? '' : 'd3 ') + (window.Foundation ? '' : 'Foundation');
  if (missing) {
    log.info(attempt + ': Waiting, missing ' + missing);
    // sometimes these libraries haven't loaded when onRetryableDocumentReady was being called; this could be because CORS is preventing them to run if CloudFront/S3 doesn't send the correct Headers :(
    setTimeout(function () {
      onRetryableDocumentReady(attempt + 1);
    }, 2000);
    if (attempt === 10) {
      log.info(window.onloadChecks); // for debugging / breadcrumbs
      throw new Error('Waited more than 10 attempts. Missing ' + missing);
    }
    return;
  }

  // We only record pageviews for logged-in users
  if (!!window.embarkUserIdObject?.email && !!window.heap) {
    try {
      window.heap.identify(window.embarkUserIdObject.email);
      window.heap.clearEventProperties();

      const eventProperties = {
        isImpersonator: window.embarkUserIdObject.isImpersonator ?? false,
        impersonatorEmail: window.embarkUserIdObject.impersonatorEmail ?? undefined,
        impersonatorName: window.embarkUserIdObject.impersonatorName ?? undefined,
      };

      window.heap.addEventProperties(eventProperties);
    } catch (err) {
      log.error({ user: window.embarkUserIdObject }, 'An error occurred identifying a user in Heap');
    }
  }

  registerHeapPageview();

  // Add Foundation to the global jQuery object, then initialize it
  // The cast to any is here because this is a function on Foundation that we need to call but it is not present in their typescript types.
  (window.Foundation as any)
    .addToJquery($)($(document) as any)
    .foundation();

  stickyClass('.sticky-class', '', 0, true);
  stickyClass('.sticky-class', '-submenu', 64, true);
  stickyClass('.sticky-class', '-sometimes', 0, false);

  $(document).on('click', '.breed-results-bottom-nav', function () {
    const id = $(this).val();
    $('#breed-results-tabs').foundation('selectTab', id, false);
    scrollToTab();
  });

  $(document).on('click', '.breedinfo-bottom-nav', function () {
    const id = $(this).val();
    $('#breedinfo-tabs').foundation('selectTab', id, false);
    scrollToTab();
  });

  $(document).on('click', '.breedinfo-link-description', function () {
    const id = 'description';
    $('#breedinfo-tabs').foundation('selectTab', id, false);
    scrollToTab();
  });

  $(document).on('click', '.breedinfo-link-embark-dogs', function () {
    const id = 'embark-dogs';
    $('#breedinfo-tabs').foundation('selectTab', id, false);
    scrollToTab();
  });

  $(document).on('click', '.breedinfo-link-stats', function () {
    const id = 'stats';
    $('#breedinfo-tabs').foundation('selectTab', id, false);
    scrollToTab();
  });

  $(document).on('click', '.public-profile-explore-link', function () {
    const id = $(this).attr('value');
    $('#public-profile-tabs').foundation('selectTab', id, false);
    scrollToTab();
  });

  // The Foundation Orbit isn't shown until the page is resized without these two checks
  // https://github.com/foundation/foundation-sites/issues/2982
  $('#breed-results-tabs').on('change.zf.tabs', function () {
    const orbit: any = $('.orbit');
    Foundation.reInit(orbit);
    scrollToTab();
  });

  if ($('#breed-results-tabs').length) {
    const orbit: any = $('.orbit');
    Foundation.reInit(orbit);
    scrollToTab();
  }

  $('#public-profile-tabs').on('change.zf.tabs', function () {
    const orbit: any = $('.orbit');
    Foundation.reInit(orbit);
    scrollToTab();
  });

  if ($('#public-profile-tabs').length) {
    const orbit: any = $('.orbit');
    Foundation.reInit(orbit);
    scrollToTab();
  }

  $('#breedinfo-tabs').on('change.zf.tabs', function () {
    scrollToTab();
  });

  if ($('#breedinfo-tabs').length) {
    scrollToTab();
  }

  function scrollToTab(): void {
    window.scrollTo(0, 0);
    const urlHash = location.hash;
    const elementId = urlHash?.replace('#', '') ?? '';
    const element = document.getElementById(`${elementId}-label`);
    element?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
  }

  $('#home-video').on('closed.zf.reveal', function () {
    // re-source the iframe, to stop and reset the video  http://stackoverflow.com/questions/15164942/stop-embedded-youtube-iframe
    const src = $('.iframe-video').attr('src') as string;
    $('.iframe-video').attr('src', src);
  });

  if ($('.breedwheel').length) {
    breedwheel.drawBreedWheel();
  }

  const breedCircleClassName = 'detectedInBreedCircle-circle';
  if (document.getElementsByClassName(breedCircleClassName).length) {
    DetectedInBreedCircle.drawDetectedInBreedCirclesInEachElementBySelector(`.${breedCircleClassName}`);
  }

  const inputElements = document.getElementsByTagName('input');
  if (inputElements.length) {
    const inputsToDisable = Array.from(inputElements).filter(
      (input) => input.dataset.disablesubmitonenterkey === 'true'
    );
    DisableFormSubmitOnEnterKey.disableSubmitOnEnterKeyForInputs(inputsToDisable);
  }

  if (!!document.getElementById('phoneSMS')) {
    notificationPreferences.init();
  }
  if (document.getElementById('signin-signup-password')) {
    signinup.init();
  }
  if ($('#form-document-info').length) {
    documents.init();
  }
  if ($('#gallery-container').length) {
    results.init();
  }
  if ($('#chat-inbox').length) {
    chat.init();
  }
  if ($('#public-pet-gallery').length) {
    galleryLikeButtons.init();
  }
  if ($('#relatives-container').length) {
    relativeFinder.init();
  }
  if ($('#pair-relatives-container').length) {
    pairRelativeFinder.init();
  }
  if (window.publicProfileViewsByDay) {
    ProfileViewTrendChart.init();
  }
  if ($('#adminPetsSearchFields').length) {
    adminDataTables.init('pets');
  }
  if ($('#adminUsersSearchFields').length) {
    adminDataTables.init('users');
  }
  if ($('table.data-table-server-side.data-table-matchmaker').length) {
    matchmaker.init($);
  }
  if ($('#breeder-dog-settings').length) {
    breederDogSettings.init();
  }
  if ($('#serial-scanning-search-container').length) {
    AdminShippingSerialScanningSearch.init();
  }
  if ($('#serial-scanning-container').length) {
    AdminShippingSerialScanning.init();
  }
  if ($('.copyLink-button').length) {
    CopyLinkButtons.init();
  }
  if ($('.shareModal-openShareWindow').length) {
    ShareCenter.init();
  }
  if ($('.petLocation').length) {
    void PetLocation.init();
  }
  if ($('#aboutPetContainer').length) {
    AboutPetProfilePicture.init();
    void $.getJSON(`/members/pet/about/${window.petNum}/json`, function (petData: Pet) {
      AboutPetNameAndStory.init(petData);
      AboutPetDetails.init(petData);
      AboutPetPrivacy.init(petData);
      AboutPetHidePet.init(petData);
      AboutPetPassedAway.init({
        ...petData,
        // set to true for pets that are not deceased so that "opt out" is selected by default
        optOutOfCommunications: !petData.isDeceased ? true : petData.optOutOfCommunications,
      });
    }).fail(function (jqxhr, textStatus, error) {
      log.error(
        {
          jqxhr,
          textStatus,
          error,
        },
        'About pet ajax failed'
      );
    });
  }
  if (document.getElementById('geneticComparisonContainer')) {
    GeneticComparison.init();
  }

  if (document.getElementById('petResultsNav2022')) {
    initPetResultsNav2022();
  }

  if (document.getElementById('backToMyDogsLink')) {
    initBackToMyDogsLink();
  }

  if (document.getElementById('public-profile-breed-summary-card')) {
    initPublicProfileBreedSummaryCard();
  }

  if (document.getElementById('public-profile-breed-reveal-banner')) {
    initPublicProfileBreedRevealBannerContainer();
  }

  if (!!document.getElementById('backToLink')) {
    initBackToLink();
  }

  if (document.getElementById('signUpBackButton')) {
    initSignupBackButton();
  }

  if (document.getElementById('geneticStatsSection')) {
    initGeneticStatsSection();
  }

  if (document.getElementById('public-profile-genetic-stats')) {
    initPublicProfileGeneticStatsSection();
  }

  if (document.getElementById('data-settings-card')) {
    initProfileDataSettingsCard();
  }

  if (document.getElementById('footer-container')) {
    initFooter();
  }

  if (document.getElementById('care-resources-traits-results-banner')) {
    initCareResourcesTraitsResultsBanner();
  }

  if (document.getElementById('feedback-banner')) {
    initFeedbackBanner();
  }

  if (document.getElementById(shareResultsWithVetCardId)) {
    initShareResultsWithVetCard();
  }

  if (document.getElementById(AboutPetCardModalId.VETERINARY_INFORMATION)) {
    initVeterinaryInformationModal();
  }

  if (document.getElementById(AboutPetCardModalId.LOCATION)) {
    initPetLocationModal();
  }

  if (document.getElementById('public-profile-dig-in-banner')) {
    initPublicProfileDigInBanner();
  }

  youtubeViewTracking.init();

  breedresults.init();

  // for public profile pages, we cache the entire page, and then use AJAX to update things that a visitor might change, ie the view count and the number of wags and any mix match comparison table
  const $totalNumberOfLikesAjax = $('.total-number-of-likes-ajax');
  if ($totalNumberOfLikesAjax.length) {
    void $.get(`/api/total-number-of-likes/${window.embark.petHandle}`, function (result) {
      $totalNumberOfLikesAjax.html(result.totalNumberOfLikes);
    });
  }
  const $publicProfileViewCountAjax = $('.public-profile-view-count-ajax');
  if ($publicProfileViewCountAjax.length) {
    void $.get(`/api/public-profile-view-count/${window.embark.petHandle}`, function (result) {
      $publicProfileViewCountAjax.html(result.publicProfileViewCount);
    });
  }

  const $mixMatchComparison = $('.mix-match-comparison');
  const compareMix = getQueryStringVariable(location.search, 'compareMix');
  if ($mixMatchComparison.length && compareMix !== null) {
    $mixMatchComparison.show();
    $mixMatchComparison.html('<h3>Dogs Like Mine breakdown</h3><small>Loading...</small>');
    void $.get(
      `/api/mix-match-comparison-fragment/${window.embark.petHandle}?compareMix=${compareMix}`,
      function (result) {
        if (result) {
          $mixMatchComparison.html(result);
        } else {
          $mixMatchComparison.html('<div>Error loading Dogs Like Mine breakdown.</div>');
        }
      }
    );
  }

  $('.small-horiz-menu-buttons a').on('click', function (e) {
    e.preventDefault();
    const toShow = $(e.currentTarget).data('target');
    $('.small-tab').hide();
    $('#' + toShow).show();
    $('.small-horiz-menu-buttons a').removeClass('selected');
    $(e.currentTarget).addClass('selected');
  });

  $('.reveal-label input').each(function () {
    const label = $('label[for="' + $(this).attr('name') + '"]');
    if (($(this).val() as string).length) {
      label.css('visibility', 'visible');
    } else {
      label.css('visibility', 'hidden');
    }
  });

  // make "/contact" links open the zendesk widget instead if it is available
  // https://developer.zendesk.com/embeddables/docs/widget/api
  $('a[href$="/contact"]').click(function (el) {
    // do this check inside the click handler instead of around the click handler, since may get created or destroyed as time goes by after document ready
    if (window.zE && window.zE.activate) {
      el.preventDefault();
      window.zE.activate();
    }
  });

  // on 'fake modal' pages like pet-shared-owners and the edit pet profile, use the body background as a clickable link back to My Embark page (as if 'closing' the 'fake modal')
  const redirectElem = document.getElementById('use-html-body-as-redirect-to-my-embark');
  if (redirectElem) {
    $('body').click(function (e) {
      if (e.target === this) {
        const redirectHref = redirectElem.getAttribute('data-redirect-href') || '/members';
        location.href = redirectHref;
      }
    });
  }
  $('.editdocument-container').click(function (e) {
    if (e.target === this) {
      const params = new URLSearchParams(window.location.search);
      const petNum = params.get('i');
      location.href = `/members/results/profile?i=${petNum}`;
    }
  });

  const oneTimeSubmitForms = Array.from(document.getElementsByClassName('oneTimeSubmit'));
  for (const form of oneTimeSubmitForms) {
    form.addEventListener('submit', function (this: HTMLFormElement) {
      const submitButton = this.querySelector<HTMLButtonElement>('button[type=submit]');
      if (!!submitButton) {
        submitButton.disabled = true;
        submitButton.textContent = 'Submitting...';
      }
    });
  }

  // when the document has finished loading, we add the 'document-ready' class to the body. This prevents any CSS animations from trying to start too early, and juddering while the DOM is still rendering
  setTimeout(function () {
    $('body').addClass('document-ready');
  }, 500);
}

$(document).ready(function () {
  Csrf.addHiddenCsrfInputFieldToEveryForm();

  onRetryableDocumentReady(1);
});

if (window.ScrollReveal) {
  window.ScrollReveal = window.ScrollReveal();
  if ($('.scroll-reveal').length) {
    window.ScrollReveal.reveal('.scroll-reveal', {
      viewFactor: 0.2,
      origin: 'top',
    });
  }
  for (let i = 1; i < 10; i++) {
    if ($('.sr-' + i).length) {
      window.ScrollReveal.reveal('.sr-' + i, { delay: i * 200 });
    }
  }

  if ($('.sr-left').length) {
    window.ScrollReveal.reveal('.sr-left', { origin: 'left' });
  }
  if ($('.sr-right').length) {
    window.ScrollReveal.reveal('.sr-right', { origin: 'right' });
  }
} else {
  $('.scroll-reveal').show();
}

function stickyClass(cls, suffix, topOffset, alwaysSticky): void {
  // Create a clone of the menu, right next to original.
  cls = cls + suffix;
  const $el = $(cls);
  if (!$el.length) {
    return;
  }
  const orig = 'original-stickit' + suffix;
  const cloned = 'cloned-stickit' + suffix;
  $el
    .addClass(orig)
    .clone()
    .insertAfter(cls)
    .addClass(cloned)
    .css('position', 'fixed')
    .css('top', '' + topOffset)
    .css('margin-top', '0')
    .css('z-index', '500')
    .removeClass(orig)
    .hide();

  const stickIt = (): void => {
    // don't stick anything on mobile
    const winWidth = $(window).width() as number;

    const orgElementPos = $('.' + orig).offset();

    if (!orgElementPos) {
      throw new Error('orgElementPos is undefined');
    } // make typescript happy

    const orgElementTop = orgElementPos.top;

    // EDIT: Always show the cloned, fixed element. Otherwise, you get annoying lag on ipad when you first scroll down
    if (winWidth > 920 && (alwaysSticky || ($(window).scrollTop() as number) >= orgElementTop - topOffset - 1)) {
      // scrolled past the original position; now only show the cloned, sticky element.

      // Cloned element should always have same left position and width as original element.
      const orgElement = $('.' + orig);
      // const coordsOrgElement = orgElement.offset()
      // const leftOrgElement = coordsOrgElement.left
      const widthOrgElement = orgElement.css('width');
      // $(cloned).css('left', leftOrgElement + 'px')
      $('.' + cloned)
        .css('top', topOffset)
        .css('width', widthOrgElement)
        .show();
      $('.' + orig).css('visibility', 'hidden');
    } else {
      // not scrolled past the menu; only show theorigmenu.
      $('.' + cloned).hide();
      $('.' + orig).css('visibility', 'visible');
    }
  };
  $(window).scroll(stickIt);
  stickIt();
}

const resize = function (): void {
  const winHeight = $(window).height() as number;
  const winWidth = $(window).width() as number;
  const navHeight = $('.top-bar-spacer').height() || 0;

  const heroBgHeight = winHeight - navHeight;
  const heroImgSize = (winHeight > winWidth ? winWidth : winHeight) * 0.6;
  const researchCarouselHeight = $('#research-carousel ul').height() as number;
  $('.hero-home').css('height', heroBgHeight);
  $('.hero-home img').css('height', heroImgSize);

  const centeredWithJsSelector = $('.centered-with-js');
  let verticalOffset: number = ($(window).height() as number) - (centeredWithJsSelector.height() as number);
  verticalOffset = verticalOffset < 0 ? 0 : verticalOffset;
  centeredWithJsSelector.css('transform', 'translateY(' + verticalOffset / 2 + 'px)');
  centeredWithJsSelector.fadeIn();

  if (winWidth > 768) {
    $('.slider-photo').css('height', researchCarouselHeight);
  }
};

$(window).on('resize', resize);
$(resize);

// mini form labels that appear when you start typing into an input causing the placeholder text to disappear
$(document).on('keyup', '.reveal-label input', function () {
  const label = $('label[for="' + $(this).attr('name') + '"]');
  if (($(this).val() as string).length) {
    label.css('visibility', 'visible');
  } else {
    label.css('visibility', 'hidden');
  }
});

window.embarkJsBundleHasLoaded = true;
