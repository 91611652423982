import 'projects/sharedComponents/src/dogAvatar/dogAvatar.scss';

import { Avatar, AvatarProps } from '@mui/material';

import { DefaultProfilePictureIcon } from 'projects/sharedComponents/src/icons/defaultProfilePictureIcon';
import React from 'react';
import clsx from 'clsx';
import { useDogAvatarBackgroundColorClass } from 'projects/sharedComponents/src/dogAvatar/dogAvatarHooks';

export type AvatarDog = {
  name: string;
  profileImageUrl: string | null;
  avatarColorIndex: number;
};

export type DogAvatarProps = AvatarProps & {
  dog: AvatarDog;
  useNeutralAvatarColor?: boolean;
};

export const DogAvatar: React.FC<DogAvatarProps> = ({ dog, useNeutralAvatarColor, ...avatarProps }) => {
  const backgroundColorClass = useDogAvatarBackgroundColorClass(dog.avatarColorIndex, useNeutralAvatarColor);

  if (!!dog.profileImageUrl) {
    return <Avatar {...avatarProps} src={dog.profileImageUrl} alt={`Photo of ${dog.name}`} />;
  }

  return (
    <Avatar {...avatarProps} className={clsx(backgroundColorClass, avatarProps.className)}>
      <DefaultProfilePictureIcon />
    </Avatar>
  );
};
