import * as $ from 'jquery';

export function init(): void {
  $('.copyLink-button').on('click', function (event: JQuery.Event) {
    event.preventDefault();
    const currentTarget = $(event.currentTarget as HTMLElement);
    const text = currentTarget.attr('href');
    if (text) {
      copyTextToClipboard(text);

      const copyLinkText = currentTarget.find('.copyLink-text');
      displayLinkCopiedAnimation(copyLinkText);
    }
  });

  function copyTextToClipboard(text: string): void {
    const textArea = createTextArea(text);
    selectText(textArea);
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  function createTextArea(text: string): HTMLInputElement {
    const textArea = document.createElement('textArea') as HTMLInputElement;
    textArea.value = text;
    textArea.setAttribute('type', 'hidden');
    textArea.style.cssText = 'position: fixed; top: 110px; left: 0px';
    document.body.appendChild(textArea);
    return textArea;
  }

  function selectText(textArea: HTMLInputElement): void {
    if (isIOS()) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      }
    } else {
      textArea.select();
    }
  }

  function isIOS(): boolean {
    return !!navigator.userAgent.match(/ipad|iphone|ipod/i);
  }

  function displayLinkCopiedAnimation(element: JQuery<HTMLElement>): void {
    transitionText(element, 'Link Copied');
    setTimeout(() => {
      transitionText(element, 'Copy Link');
    }, 3000);
  }

  function transitionText(element: JQuery<HTMLElement>, text: string): void {
    element.fadeOut(100, () => {
      element.text(text).fadeIn();
    });
  }
}
