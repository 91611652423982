import { Environments } from 'clientServerShared/types/environment';

export function getEnvironment(): Environments {
  const hostname = location.hostname;
  switch (hostname) {
    case 'my.embarkvet.com':
      return Environments.LIVE_PRODUCTION;
    case 'my.dev.embarkvet.com':
    default:
      return Environments.DEV;
  }
}
