import { ButtonProps, IconButtonProps } from '@mui/material';
import {
  ShareResultsWithVetFragment,
  useShareResultsWithVetMutation,
} from 'projects/sharedComponents/src/generated/graphql-client-types';
import { UseFormReturn, useForm } from 'react-hook-form';

import { FormInputProps } from 'projects/sharedComponents/src/forms/formInputHook';
import { HeapCategory } from 'clientServerShared/types/eventTracking';
import isEmail from 'validator/lib/isEmail';
import { useState } from 'react';
import { useToggle } from 'react-use';

export type ShareResultsWithVetFormProps = {
  pet: ShareResultsWithVetFragment;
  heapCategory: HeapCategory;
};

type UseShareResultsWithVetFormReturn = ShareResultsWithVetFormProps & {
  shareResultsWithVetForm: UseFormReturn<ShareResultsWithVetFormInput>;
  vetNameInput: FormInputProps;
  vetEmailInput: FormInputProps;
  onClickSendReport: ButtonProps['onClick'];
  isShareResultsWithVetLoading: boolean;
  previousShareResultsInput: ShareResultsWithVetFormInput | null;
  isSuccessAlertShown: boolean;
  onDismissSuccessAlert: IconButtonProps['onClick'];
  isErrorAlertShown: boolean;
  onDismissErrorAlert: IconButtonProps['onClick'];
};

type ShareResultsWithVetFormInput = {
  vetName: string;
  vetEmail: string;
};

export function useShareResultsWithVetForm(props: ShareResultsWithVetFormProps): UseShareResultsWithVetFormReturn {
  const { pet } = props;

  const shareResultsWithVetForm = useForm<ShareResultsWithVetFormInput>({
    mode: 'onChange',
    defaultValues: {
      vetName: '',
      vetEmail: '',
    },
  });

  const [previousShareResultsInput, setPreviousShareResultsInput] = useState<ShareResultsWithVetFormInput | null>(null);

  const [isSuccessAlertShown, setIsSuccessAlertShown] = useToggle(false);
  const [isErrorAlertShown, setIsErrorAlertShown] = useToggle(false);

  const [shareResultsWithVet, { loading: isShareResultsWithVetLoading }] = useShareResultsWithVetMutation({
    onCompleted: () => {
      setPreviousShareResultsInput(shareResultsWithVetForm.getValues());
      setIsSuccessAlertShown(true);
      setIsErrorAlertShown(false);
      shareResultsWithVetForm.reset();
    },
    onError: () => {
      setIsSuccessAlertShown(false);
      setIsErrorAlertShown(true);
    },
  });

  const onClickSendReport: UseShareResultsWithVetFormReturn['onClickSendReport'] = shareResultsWithVetForm.handleSubmit(
    async (input) => {
      await shareResultsWithVet({
        variables: {
          shareResultsWithVetInput: {
            petUuid: pet.id,
            vetName: input.vetName,
            vetEmail: input.vetEmail,
          },
        },
      });
    }
  );

  const vetNameInput: UseShareResultsWithVetFormReturn['vetNameInput'] = {
    id: 'vet-name',
    name: 'vetName',
    label: 'Your vet’s name',
    ariaLabel: 'Your vet’s name',
    required: true,
    registerOptions: {
      validate: (value: string) => {
        const trimmedValue = value.trim();
        if (!trimmedValue) {
          return 'Vet name is required';
        }
        return true;
      },
    },
  };
  const vetEmailInput: UseShareResultsWithVetFormReturn['vetNameInput'] = {
    id: 'vet-email',
    name: 'vetEmail',
    label: 'Your vet’s email',
    ariaLabel: 'Your vet’s email',
    required: true,
    registerOptions: {
      validate: (value: string) => {
        const trimmedValue = value.trim();
        if (!trimmedValue) {
          return 'Vet email is required';
        }
        if (!isEmail(trimmedValue)) {
          return 'Vet email is not valid';
        }
        return true;
      },
    },
  };

  const onDismissSuccessAlert: UseShareResultsWithVetFormReturn['onDismissSuccessAlert'] = () => {
    setIsSuccessAlertShown(false);
  };
  const onDismissErrorAlert: UseShareResultsWithVetFormReturn['onDismissErrorAlert'] = () => {
    setIsErrorAlertShown(false);
  };

  return {
    ...props,
    shareResultsWithVetForm,
    vetNameInput,
    vetEmailInput,
    onClickSendReport,
    isShareResultsWithVetLoading,
    previousShareResultsInput,
    isSuccessAlertShown,
    onDismissSuccessAlert,
    isErrorAlertShown,
    onDismissErrorAlert,
  };
}
