type DogAvatarBackgroundColorClass = `dogAvatar-background-${number | 'neutral'}`;

export function useDogAvatarBackgroundColorClass(
  dogAvatarColorIndex: number,
  useNeutralAvatarColor = false
): DogAvatarBackgroundColorClass {
  if (useNeutralAvatarColor) {
    return 'dogAvatar-background-neutral';
  }
  return `dogAvatar-background-${dogAvatarColorIndex}`;
}
