<template>
  <section class="reveal" :id="modalName">
    <button class="close-button" aria-label="Close modal" type="button" data-close>
      <span aria-hidden="true">&times;</span>
    </button>
    <slot></slot>
  </section>
</template>
<script>
import Vue from 'vue';
export default Vue.extend({
  props: ['modalName'],
  mounted() {
    const modal = $(`#${this.modalName}`);
    // Foundation and vue do not play nice when it comes to models, this is a work around.
    new Foundation.Reveal(modal);

    // Foundation events get emited from the JQuery object and not the HTMLElement
    modal.on('open.zf.reveal', () => {
      this.$emit('opened');
    });
    modal.on('closed.zf.reveal', () => {
      this.$emit('closed');
    });
  },
});
</script>
<style scoped>
.close-button {
  margin-top: 20px;
  margin-right: 10px;
}
</style>
