import { AccountDetails } from 'clientServerShared/types/account';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { buildMyEvLegacyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';
import { useDrawerOpenClose } from 'projects/sharedComponents/src/header/headerHooks';

type MobileHeaderLinksProps = {
  chatBubbleAriaLabel: string;
  accountDetails: AccountDetails;
};

export const MobileHeaderLinks: React.FC<MobileHeaderLinksProps> = (props) => {
  const { chatBubbleAriaLabel, accountDetails } = props;
  const { handleDrawerClose, handleDrawerOpen, drawerIsOpen } = useDrawerOpenClose();

  const numUnreadMessages = accountDetails.user?.numUnreadMessages ?? 0;

  const isPetParent = !accountDetails.user?.permissions.isBreeder && !accountDetails.user?.permissions.isVet;

  return (
    <div className="mobileHeader-menu">
      <Toolbar>
        <IconButton
          color="default"
          aria-label="Open Navigation Drawer"
          aria-haspopup="true"
          aria-controls="mobileHeader-drawer"
          aria-expanded={drawerIsOpen}
          onClick={handleDrawerOpen}
          edge="end"
          size="large"
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer id="mobileHeader-drawer" anchor="top" variant="persistent" open={drawerIsOpen}>
        <div>
          <Container>
            <Grid container>
              <Grid item xs={1}>
                <IconButton onClick={handleDrawerClose} aria-label="Close Navigation Drawer" size="large">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
        </div>
        <ul className="mobileHeader-links" role="menu">
          <li role="none">
            <a href={buildMyEvLegacyLink('/')} role="menuitem" onClick={handleDrawerClose}>
              My Embark
            </a>
          </li>
          <li role="none">
            <a
              href={buildMyEvLegacyLink('/members/messages')}
              role="menuitem"
              aria-label={chatBubbleAriaLabel}
              className="mobileHeader-chatLink"
            >
              <span role="img" aria-label="">
                💬&nbsp;
              </span>
              Messages
              {numUnreadMessages > 0 && (
                <span className="header-chatBubble" data-testid="header-chatBubble" role="img">
                  {numUnreadMessages}
                </span>
              )}
            </a>
          </li>
          {isPetParent && (
            <li role="none">
              <a
                href={buildMyEvLegacyLink(`/members/referrals?countryCode=${accountDetails.user?.countryCode}`)}
                role="menuitem"
              >
                <span role="img" aria-label="">
                  💰&nbsp;
                </span>
                Give a Friend $50
              </a>
            </li>
          )}
          <li role="none">
            <a href={buildMyEvLegacyLink('/members/profile')} role="menuitem">
              Settings
            </a>
          </li>
          <li role="none">
            <a href="https://help.embarkvet.com/hc/en-us" target="_self" role="menuitem">
              Help Center
            </a>
          </li>
          <li role="none">
            <a href={buildMyEvLegacyLink('/logout')} role="menuitem">
              Sign Out
            </a>
          </li>
        </ul>
      </Drawer>
    </div>
  );
};
