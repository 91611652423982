import './petResultsNavButton.scss';

import { AnchorButton, AnchorButtonProps } from 'projects/sharedComponents/src/buttons/anchorButton';

import { FC } from 'react';
import { HeapCategory } from 'clientServerShared/types/eventTracking';
import clsx from 'clsx';

export type PetResultsNavButtonProps = AnchorButtonProps &
  Pick<Required<AnchorButtonProps>, 'isLegacyMyEvLink'> & {
    href: string;
    ['data-heap-action']: string;
    parentRoutes: string[];
  };

export const PetResultsNavButton: FC<PetResultsNavButtonProps> = (props) => {
  const { isLegacyMyEvLink, parentRoutes, ...buttonProps } = props;

  const windowPath = window.location.pathname.toLowerCase().trim();

  const isActiveButton = parentRoutes.some((route) => windowPath.includes(route)); // highlights the nav for any subpages under a main category

  const dataParentRoute = parentRoutes.join(';');

  return (
    <AnchorButton
      color={isActiveButton ? 'secondary' : 'primary'}
      variant="contained"
      data-heap-category={HeapCategory.PET_RESULTS_NAV}
      data-parent-route={dataParentRoute}
      {...buttonProps}
      className={clsx(
        'petResultsNav-navButton',
        isActiveButton ? 'petResultsNav-navButton--active' : null,
        props.className
      )}
      isLegacyMyEvLink={isLegacyMyEvLink}
    />
  );
};
