import { FieldValues, UseFormRegister, useFormContext } from 'react-hook-form';

import { RegisterOptions } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';

export type FormInputProps = TextFieldProps & {
  name: string;
  ariaLabel: string;
  helperText?: string;
  registerOptions?: RegisterOptions;
};

type UseFormInputHookReturn = {
  register: UseFormRegister<FieldValues>;
  fieldName: string;
  helperText: string;
  hasError: boolean;
};

export function useFormInputHook(props: FormInputProps): UseFormInputHookReturn {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { name: fieldName, helperText = '' } = props;

  const errorMessage = errors[fieldName]?.message ?? '';
  const hasError = !!errors[fieldName];
  return {
    register,
    fieldName,
    helperText: hasError ? errorMessage : helperText,
    hasError,
  };
}
