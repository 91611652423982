import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  UseShareResultsWithVetCardProps,
  useShareResultsWithVetCard,
} from 'projects/sharedComponents/src/shareResultsWithVet/shareResultsWithVetCardHooks';
import { buildEmbarkCDNUrl, getMyEvContactUsLink } from 'projects/sharedComponents/src/services/links/linkBuilder';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HeapAction } from 'clientServerShared/types/eventTracking';
import { ShareResultsWithVetForm } from 'projects/sharedComponents/src/shareResultsWithVet/shareResultsWithVetForm';
import { ShareResultsWithVetLinkButton } from 'projects/sharedComponents/src/shareResultsWithVet/shareResultsWithVetLinkButton';
import { VFC } from 'react';
import { getHeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';
import { styled } from '@mui/material/styles';

export const ShareResultsWithVetCard: VFC<UseShareResultsWithVetCardProps> = (props) => {
  const { pet, iconUrl, heapCategory, vetReportAbsoluteUrl, copyLinkText, onClickCopyLink } =
    useShareResultsWithVetCard(props);

  return (
    <StyledAccordion
      elevation={0}
      {...getHeapDataAttributes({
        heapCategory,
        heapAction: HeapAction.SHARE_RESULTS_WITH_VET_ACCORDION,
      })}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" aria-hidden="true" htmlColor="black" />}>
        <Stack direction="row" spacing={{ xs: 2, sm: 3 }} alignItems="center">
          <img alt="" src={iconUrl} width={56} height={56} />
          <Typography>
            <b>Share results with your veterinarian</b>
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{ paddingTop: 0, paddingLeft: { xs: 2, sm: 12 }, paddingRight: { xs: 2, sm: 3 }, paddingBottom: 4 }}
      >
        <Stack spacing={3}>
          <ShareResultsWithVetForm pet={pet} heapCategory={heapCategory} />
          <Divider />
          <Stack spacing={{ xs: 3, sm: 2 }} direction={{ xs: 'column', sm: 'row' }}>
            <ShareResultsWithVetLinkButton
              label="Preview, print, and download report (PDF)"
              slotProps={{
                root: {
                  href: `/members/reports/print-vet-report-pdf/${pet.hashPetId}`,
                  ...getHeapDataAttributes({
                    heapCategory,
                    heapAction: HeapAction.PREVIEW_VET_REPORT,
                  }),
                },
                icon: {
                  alt: '',
                  src: buildEmbarkCDNUrl('/img/health/icon-printer.svg'),
                },
              }}
            />
            <ShareResultsWithVetLinkButton
              label={copyLinkText}
              slotProps={{
                root: {
                  href: vetReportAbsoluteUrl,
                  onClick: onClickCopyLink,
                  ...getHeapDataAttributes({
                    heapCategory,
                    heapAction: HeapAction.COPY_VET_REPORT_LINK,
                  }),
                },
                icon: {
                  alt: '',
                  src: buildEmbarkCDNUrl('/img/health/icon-link.svg'),
                },
              }}
            />
          </Stack>
          <Stack spacing={1}>
            <Divider />
            <Typography variant="body2" color={(theme) => theme.palette.helix.greyDark}>
              Only your veterinarian can provide medical recommendations. Embark’s resident veterinarians cannot provide
              diagnoses or medical advice, but can provide expert insights about your dog’s genetic results.
            </Typography>
            <Typography variant="body2" color={(theme) => theme.palette.helix.greyDark}>
              Have any questions?{' '}
              <Link href={getMyEvContactUsLink()} color={(theme) => theme.palette.helix.blueDark}>
                <b>Contact customer service</b>
              </Link>
              .
            </Typography>
          </Stack>
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(Accordion)(({ theme }) => {
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return {
    'backgroundColor': theme.palette.helix.background,
    '& .MuiAccordionSummary-root': {
      paddingLeft: isExtraSmall ? theme.spacing(2) : theme.spacing(3),
      paddingRight: isExtraSmall ? theme.spacing(2) : theme.spacing(3),
    },
    '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    '&:before': {
      height: 0,
    },
    'borderRadius': theme.spacing(1),
  };
});
