type RenderIfProps = {
  condition: boolean;
};

export const RenderIf: React.FC<RenderIfProps> = (props) => {
  const { condition = false, children } = props;
  const isConditionMetAndHasChildren = condition === true && !!children;

  if (isConditionMetAndHasChildren) {
    return <>{children}</>;
  }
  return null;
};
