import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { FC } from 'react';
import { PublicProfileBreedSummaryCard } from '@embark/shared-components/src/publicProfile/breedSummary/publicProfileBreedSummaryCard';
import { PublicProfileBreedSummaryCardFragment } from '@embark/shared-components/src/generated/graphql-client-types';
import ReactDOM from 'react-dom';

export function initPublicProfileBreedSummaryCard(): void {
  ReactDOM.render(
    <AppProviders>
      <PublicProfileBreedSummaryCardContainer />
    </AppProviders>,
    document.getElementById('public-profile-breed-summary-card')
  );
}

const PublicProfileBreedSummaryCardContainer: FC = () => {
  const data: PublicProfileBreedSummaryCardFragment = window['reactPet'];

  return <PublicProfileBreedSummaryCard dog={data} />;
};
