import React from 'react';

export async function copyLinkButtonOnClickHandler(
  event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
): Promise<void> {
  event.preventDefault();
  const linkToCopy = event.currentTarget.getAttribute('href');

  if (!linkToCopy) {
    return Promise.resolve();
  }

  return copyTextToClipboard(linkToCopy);
}

async function copyTextToClipboard(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    const textArea = createTextArea(text);
    selectText(textArea);
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }
}

function createTextArea(text: string): HTMLInputElement {
  const textArea = document.createElement('textArea') as HTMLInputElement;
  textArea.value = text;
  textArea.setAttribute('type', 'hidden');
  textArea.style.cssText = 'position: fixed; top: 110px; left: 0px';
  document.body.appendChild(textArea);
  return textArea;
}

function selectText(textArea: HTMLInputElement): void {
  if (isIOS()) {
    const range = document.createRange();
    range.selectNodeContents(textArea);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    }
  } else {
    textArea.select();
  }
}

function isIOS(): boolean {
  return !!navigator.userAgent.match(/ipad|iphone|ipod/i);
}
