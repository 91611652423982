import * as $ from 'jquery';

export function init(): void {
  const POPUP_WIDTH = 555;
  const POPUP_HEIGHT = 810;

  $('.shareModal-openShareWindow').on('click', function (event: JQuery.Event) {
    event.preventDefault();
    const currentTarget = $(event.currentTarget as HTMLElement);
    const href = currentTarget.attr('href');

    const jQueryWindow = $(window);
    const windowWidth = jQueryWindow.width();
    const windowHeight = jQueryWindow.height();

    if (windowWidth && windowHeight) {
      window.open(
        href,
        'shareWindow',
        `
          width=${POPUP_WIDTH},
          height=${POPUP_HEIGHT},
          left=${windowWidth / 2 - POPUP_WIDTH / 2},
          top=${windowHeight / 2 - POPUP_HEIGHT / 2},
          toolbar=0,
          location=0,
          menubar=0,
          directories=0,
          scrollbars=0`
      );
    }
  });
}
