import {
  UploadPresets as UploadPresetsToReExport,
  uploadPresets,
} from '../projects/frontEnd/src/services/cloudinary/cloudinaryWidget';

// image / video sizes for slider
export const mediaSizes = {
  large: {
    width: 900,
    height: 900,
  },
  appWidth: {
    width: 766, // app width is 768px but 1px border on each side leaves 766px for content
    height: 2000,
  },
  normal: {
    width: 600,
    height: 400,
  },
  // used on mobile
  small: {
    width: 250,
    height: 250,
  },
  thumbnail: {
    width: 100, // display width is 50px, so use 100px for image width for HiDPI screens
    height: 85,
  },
  appWidthSmooshedSquare: {
    width: 766,
    height: 650,
  },
  appWidthCarousel: {
    width: 766,
    height: 400,
  },
  videoAnimatedThumbnail: {
    width: 100,
    height: 100,
  },
};

export const IMAGE = 'image';
export const VIDEO = 'video';
export const DOCUMENT = 'document';

export { uploadPresets };
export type UploadPresets = UploadPresetsToReExport;
