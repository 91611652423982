import * as Logger from 'bunyan';

import { ConsoleRawStream, createLogger } from 'browser-bunyan';
import {
  adaptBunyanLogger,
  createSimpleChildWithRecordSerialization,
} from '../../../../clientServerShared/services/logAdapter';

import { getEnvironment } from '@embark/shared-components/src/services/environment/getEnvironment';

function createBunyanLogger(): Logger {
  const streams: Logger.Stream[] = [
    {
      level: 'info',
      stream: new ConsoleRawStream(),
    },
  ];

  return adaptBunyanLogger(
    {
      name: getEnvironment(),
      streams,
    },
    createLogger,
    JSON.stringify
  );
}

const loggerSingleton = createBunyanLogger();
export const log = loggerSingleton;

export function createInheritingChildLogger(defaultRecords: Record<string, any>): Logger {
  return createSimpleChildWithRecordSerialization(loggerSingleton, defaultRecords);
}
