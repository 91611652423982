import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { FooterContainer } from '@embark/shared-components/src/footer/footerContainer';
import ReactDOM from 'react-dom';

export function initFooter(): void {
  ReactDOM.render(
    <AppProviders>
      <FooterContainer />
    </AppProviders>,
    document.getElementById('footer-container')
  );
}
