import { FormInputProps, useFormInputHook } from 'projects/sharedComponents/src/forms/formInputHook';

import React from 'react';
import { TextField } from '@mui/material';

export const FormInput: React.FC<FormInputProps> = (props) => {
  const { label, type, ariaLabel, registerOptions = {}, ...otherProps } = props;
  const { register, fieldName, hasError, helperText } = useFormInputHook(props);

  return (
    <TextField
      {...otherProps}
      error={hasError}
      helperText={helperText}
      label={label}
      aria-label={ariaLabel}
      type={type}
      {...register(fieldName, registerOptions)}
    />
  );
};
