import * as breedtypeOptions from '../../../../models/breedtypes';

import AdoptiondatePicker from './vue-components/adoptiondate-picker.vue';
import AreBothParentsRegisteredSelect from './vue-components/are-both-parents-registered-select.vue';
import BirthdatePicker from './vue-components/birthdate-picker.vue';
import DogIntactSelect from './vue-components/dog-intact-select.vue';
import DogSexSelect from './vue-components/dog-sex-select.vue';
import { PetWithTitlesList } from './types/aboutPet';
import Vue from 'vue';

export function init(data: PetWithTitlesList): void {
  data.showBreedTypeDefinitions = false;
  const vueModel = new Vue({
    el: '#aboutpet-details-container-vue',
    data: function () {
      return { ...data };
    },
    components: {
      DogSexSelect,
      DogIntactSelect,
      AreBothParentsRegisteredSelect,
      BirthdatePicker,
      AdoptiondatePicker,
    },
    methods: {
      showDefinitions: function (): void {
        data.showBreedTypeDefinitions = true;
        update();
      },
    },
    computed: {
      collectBreedAndRegistrationInformation: function (): boolean {
        if (data.isPrimaryOwnerBreeder) {
          return true;
        }
        const breedtypeOption = breedtypeOptions.getBreedtype(data.profile.breedtype);
        return breedtypeOption ? breedtypeOption.collectBreedAndRegistrationInformation : false;
      },
    },
    watch: {},
  });

  function update(): void {
    vueModel.$forceUpdate();
  }
}
