import { CloudinaryEventResult } from '../../../frontEnd/src/services/cloudinary/cloudinaryWidget';
import { IMAGE } from '../../../../clientServerShared/cloudinary';
import Vue from 'vue';
import { openCloudinaryMediaWidgetWithUploadHandler } from './cloudinary';

export function init(): void {
  const data = (window as any).formCarry;
  new Vue({
    el: '#aboutpet-profile-picture-container-vue',
    data: function () {
      return { ...data };
    },
    methods: {
      uploadNewProfilePicture: uploadNewProfilePicture,
    },
  });
}

function uploadNewProfilePicture(): void {
  openCloudinaryMediaWidgetWithUploadHandler(IMAGE, uploadNewProfilePictureUploadHandler);
}

function uploadNewProfilePictureUploadHandler(_err, data: CloudinaryEventResult): void {
  if (data && data.event === 'success' && data.info) {
    const result = data.info;
    const publicId = result.public_id;
    const phash = result.phash;

    window.location.href = `/members/pet/about/uploadedProfilePicture/${window.petNum}?publicId=${encodeURIComponent(
      publicId
    )}&phash=${phash}`;
  }
}
