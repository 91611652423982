import { Box, Container, Grid, Link, List, ListItem, Stack, Typography, useMediaQuery } from '@mui/material';
import { FooterUserFragment, UserTypeGraphQl } from 'projects/sharedComponents/src/generated/graphql-client-types';

import { ImageBox } from 'projects/sharedComponents/src/imageBox/imageBox';
import { RafBanner } from 'projects/sharedComponents/src/footer/rafBanner';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';

type HelixFooterProps = {
  user: FooterUserFragment;
  logo: {
    src: string;
    altText: string;
    href: string;
  };
  columns?: HelixFooterColumnProps[];
};

export const HelixFooter: React.FC<HelixFooterProps> = ({ user, logo, columns = [] }) => {
  const theme = useTheme();
  const isBelowXL = useMediaQuery(theme.breakpoints.down('xl'));
  const columnGap = isBelowXL ? 3 : 8;
  const gridPadding = isBelowXL ? 3 : 6;
  const imageHeight = isBelowXL ? '30px' : '40px';

  return (
    <HelixFooterWrapper>
      <Container disableGutters={true} sx={{ flexGrow: 1 }}>
        <Box marginX={{ xs: '16px', sm: '32px' }} component="main">
          <Grid container spacing={0} sx={{ paddingTop: gridPadding, paddingBottom: gridPadding }}>
            <Grid item md={2} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <Link href={logo.href}>
                <ImageBox
                  imageBreakpoints={{ md: logo.src }}
                  display="flex"
                  height={imageHeight}
                  altText={logo.altText}
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
              <Stack direction="row" sx={{ display: 'flex', columnGap, rowGap: 4, flexWrap: 'wrap' }}>
                {columns.map(({ items, header }) => (
                  <HelixFooterColumn key={header} header={header} items={items} />
                ))}
              </Stack>
              {user.userType === UserTypeGraphQl.PetParent && <RafBanner user={user} />}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </HelixFooterWrapper>
  );
};

const HelixFooterWrapper = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

export type HelixFooterColumnProps = {
  header: string;
  items: JSX.Element[];
};

const HelixFooterColumn: React.FC<HelixFooterColumnProps> = ({ header, items }) => {
  return (
    <Stack spacing={1} sx={{ 'minWidth': '151px', 'maxWidth': '167px', '&&': { marginLeft: '0' } }}>
      <Typography variant="caption" pb={1} fontSize="14px">
        <strong>{header}</strong>
      </Typography>
      <List disablePadding>
        {items.map((item, index) => (
          <ListItem disableGutters key={index} sx={{ padding: '4px 0px' }}>
            {item}
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};
