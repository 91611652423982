import { AddADogResponse, makeAddADogRequest } from 'projects/frontEnd/src/pages/addADog/apiRequests/addADogRequests';
import {
  AddressComponent,
  BusinessType,
  LocationDetails as GraphQLLocationDetails,
  LocationType,
  PetInput,
  Sex,
} from '@embark/shared-components/src/generated/graphql-client-types';

import { LocationDetails } from 'projects/frontEnd/src/pages/addADog/state/addADogSlice';
import { useAppSelector } from 'projects/frontEnd/src/state/hooks';
import { useMutation } from 'react-query';

type UseCreateDogProps = {
  handleAddADogSuccess: () => void;
};

type UseCreateDogReturn = {
  submitCreateDog: () => void;
  isLoading: boolean;
  data?: AddADogResponse | null;
  error?: Error;
};

export function useCreateDog(props: UseCreateDogProps): UseCreateDogReturn {
  const { handleAddADogSuccess } = props;

  const sex = useAppSelector((state) => state.addADog.sex) ?? Sex.F;
  const profilePicture = useAppSelector((state) => state.addADog.profilePicture);
  const currentLocation = useAppSelector((state) => state.addADog.currentLocation);
  const adoptionLocation = useAppSelector((state) => state.addADog.adoptionLocation);
  const birthLocation = useAppSelector((state) => state.addADog.birthLocation);
  const practiceLocation = useAppSelector((state) => state.addADog.practiceLocation);
  const profile = useAppSelector((state) => state.addADog.profile);
  const embarkMayContactVet = useAppSelector((state) => state.addADog.embarkMayContactVet);

  const pet: PetInput = {
    ...useAppSelector((state) => state.addADog),
    profile: { ...profile, breed: profile.breed?.displayName },
    profilePicture: { phash: profilePicture.phash, imagePublicId: profilePicture.imagePublicId },
    currentLocation: mapLocation(currentLocation),
    adoptionLocation: mapLocation(adoptionLocation),
    birthLocation: mapLocation(birthLocation),
    practiceLocation: mapLocation(practiceLocation),
    embarkMayContactVet,
    sex,
  };

  const addADogMutation = useMutation(makeAddADogRequest);

  function submitCreateDog(): void {
    addADogMutation.mutate(pet, {
      onSuccess: () => {
        handleAddADogSuccess();
      },
      onError: (error) => {
        // TODO: We should probably surface this if it is a user error at some point
        console.log(JSON.stringify(error));
      },
    });
  }

  return {
    submitCreateDog,
    data: addADogMutation.data,
    isLoading: addADogMutation.isLoading,
    error: addADogMutation.error as Error,
  };
}

// TODO: CONS-375 align location data handling with other location data handlers (ex: create a practice, breeder profile)
export function mapLocation(location?: LocationDetails | null): GraphQLLocationDetails | undefined {
  if (!location || !location.googleLocationDetails || !location.googleLocationDetails.details) {
    return;
  }

  const location_type = location.locationType as LocationType;
  const business_type = BusinessType.Other;
  const address_input = location.googleLocationDetails.description;
  const formatted_address = location.googleLocationDetails.details.formatted_address ?? '';
  const lng_lat = {
    x: location.googleLocationDetails.details.geometry?.location?.lng() ?? -1,
    y: location.googleLocationDetails.details.geometry?.location?.lat() ?? -1,
  };
  const name = location.googleLocationDetails.details.name ?? '';
  const place_id = location.googleLocationDetails.place_id;
  const types = location.googleLocationDetails.types;
  const vicinity = location.googleLocationDetails.details.vicinity;
  const formatted_phone_number = location.googleLocationDetails.details.formatted_phone_number;
  const website = location.googleLocationDetails.details.website;
  const address_components: AddressComponent[] = location.googleLocationDetails.details.address_components ?? [];
  return {
    location_type,
    business_type,
    address_input,
    formatted_address,
    lng_lat,
    name,
    place_id,
    types,
    vicinity,
    formatted_phone_number,
    website,
    address_components,
  };
}
