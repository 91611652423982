import './petResultsNavItems.scss';

import { FC, Fragment, ReactNode } from 'react';
import { UserType, getUserType } from 'projects/sharedComponents/src/services/user/userTypes';

import { AccountDetails } from 'clientServerShared/types/account';
import { DogAvatar } from 'projects/sharedComponents/src/dogAvatar/dogAvatar';
import { HeapAction } from 'clientServerShared/types/eventTracking';
import LockIcon from '@mui/icons-material/Lock';
import { PetResultsNavButton } from 'projects/sharedComponents/src/pet/navigation/petResultsNavButton';
import { PetResultsNavFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { buildMyEvLegacyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';
import { useEffectOnce } from 'react-use';

export type PetResultsNavItemsProps = {
  pet: PetResultsNavFragment;
  accountDetails: AccountDetails;
  isReactRouterLinks?: boolean;
};

export const PetResultsNavItems: FC<PetResultsNavItemsProps> = (props) => {
  const { pet, accountDetails, isReactRouterLinks = false } = props;

  const userType = getUserType(accountDetails);
  const countryCode = accountDetails.user?.countryCode ?? 'US';

  useEffectOnce(() => {
    scrollSelectedNavItemIntoView();
  });

  const profileNav = (
    <li className="petResultsNav-profileItem">
      <PetResultsNavButton
        href={buildMyEvLegacyLink(`/members/results/profile?i=${pet.petNum}`)}
        data-heap-action={HeapAction.VIEW_PROFILE}
        isLegacyMyEvLink
        parentRoutes={['/members/results/profile', '/members/pet/about']}
      >
        Profile
      </PetResultsNavButton>
    </li>
  );

  const summaryNav = (
    <li className="petResultsNav-summaryItem">
      <PetResultsNavButton
        className="petResultsNav-summaryButton"
        href={`/members/results/summary?i=${pet.petNum}`}
        data-heap-action={HeapAction.SUMMARY}
        isLegacyMyEvLink={!isReactRouterLinks}
        parentRoutes={['/members/results/summary']}
      >
        <DogAvatar className="petResultsNav-dogAvatar-react" dog={pet} /> Summary
      </PetResultsNavButton>
    </li>
  );

  const researchNav = (
    <li>
      <PetResultsNavButton
        href={`/members/results/research-surveys?i=${pet.petNum}`}
        data-heap-action={HeapAction.RESEARCH}
        isLegacyMyEvLink={!isReactRouterLinks}
        parentRoutes={['/members/results/research-surveys']}
      >
        Research
      </PetResultsNavButton>
    </li>
  );

  const breedNavText = userType === UserType.Vet ? 'Breed' : 'Breed & Ancestry';
  const breedNavClassName =
    userType === UserType.Vet ? 'petResultsNav-breedItem' : 'petResultsNav-breedAndAncestryItem';

  const breedNav = (
    <li className={breedNavClassName}>
      <PetResultsNavButton
        href={`/members/results/breed-and-ancestry?i=${pet.petNum}&countryCode=${countryCode}`}
        data-heap-action={HeapAction.BREED}
        isLegacyMyEvLink={!isReactRouterLinks}
        parentRoutes={['/members/results/breed-and-ancestry']}
      >
        {breedNavText}
      </PetResultsNavButton>
    </li>
  );

  const healthNav = (
    <li>
      <PetResultsNavButton
        href={`/members/results/health?i=${pet.petNum}`}
        data-heap-action={HeapAction.HEALTH}
        isLegacyMyEvLink={!isReactRouterLinks}
        parentRoutes={[
          '/members/results/health',
          '/members/results/health/breed-relevant',
          '/members/results/health/other',
        ]}
      >
        Health
      </PetResultsNavButton>
    </li>
  );

  const relativeFinderNav = (
    <li>
      <PetResultsNavButton
        href={buildMyEvLegacyLink(`/members/results/relatives?i=${pet.petNum}&countryCode=${countryCode}`)}
        data-heap-action={HeapAction.RELATIVES}
        isLegacyMyEvLink
        parentRoutes={['/members/results/relatives']}
      >
        Relatives
      </PetResultsNavButton>
    </li>
  );

  const traitsNav = (
    <li>
      <PetResultsNavButton
        href={`/members/results/traits?i=${pet.petNum}`}
        data-heap-action={HeapAction.TRAITS}
        isLegacyMyEvLink={!isReactRouterLinks}
        parentRoutes={['/members/results/traits']}
      >
        Traits
      </PetResultsNavButton>
    </li>
  );

  const careResourcesNav = (
    <li className="petResultsNav-careResourcesItem">
      <PetResultsNavButton
        href={`/members/results/care?i=${pet.petNum}`}
        data-heap-action={HeapAction.CARE_RESOURCES}
        isLegacyMyEvLink={!isReactRouterLinks}
        parentRoutes={['/members/results/care']}
      >
        Care Resources
      </PetResultsNavButton>
    </li>
  );

  const upgradeNavHealthLocked = (
    <li className="petResultsNav-upgradeHealthLockedItem">
      <PetResultsNavButton
        href={buildMyEvLegacyLink(`/members/results/health?i=${pet.petNum}`)}
        data-heap-action={HeapAction.HEALTH}
        aria-label="Unlock Health and Traits"
        isLegacyMyEvLink
        parentRoutes={['/members/results/health', '/members/results/traits']}
      >
        Health & Traits <LockIcon className="petResultsNav-lockIcon" />
      </PetResultsNavButton>
    </li>
  );

  const navItems: ReactNode[] = [];

  if (userType === UserType.PetParent) {
    navItems.push(summaryNav);

    if (pet.shouldShowCareResources) {
      navItems.push(careResourcesNav);
    }

    navItems.push(researchNav);

    if (pet.showRelativeFinder) {
      navItems.push(relativeFinderNav);
    }

    navItems.push(breedNav);

    if (!pet.isUpgradeAvailable) {
      navItems.push(healthNav);
      navItems.push(traitsNav);
    }

    if (pet.isUpgradeAvailable) {
      navItems.push(upgradeNavHealthLocked);
    }

    navItems.push(profileNav);
  } else {
    navItems.push(profileNav);

    navItems.push(summaryNav);

    navItems.push(researchNav);

    if (!pet.isUpgradeAvailable) {
      navItems.push(healthNav);
    }

    navItems.push(breedNav);

    if (pet.showRelativeFinder && userType !== UserType.Vet) {
      navItems.push(relativeFinderNav);
    }

    if (!pet.isUpgradeAvailable) {
      navItems.push(traitsNav);
    }

    if (pet.showRelativeFinder && userType === UserType.Vet) {
      navItems.push(relativeFinderNav);
    }

    if (pet.shouldShowCareResources) {
      navItems.push(careResourcesNav);
    }

    if (pet.isUpgradeAvailable) {
      navItems.push(upgradeNavHealthLocked);
    }
  }

  return (
    <ul className="petResultsNav-buttonList">
      {navItems.map((navItem, index) => (
        <Fragment key={index}>{navItem}</Fragment>
      ))}
    </ul>
  );
};

function scrollSelectedNavItemIntoView(): void {
  const navLinks = Array.from(document.getElementsByClassName('petResultsNav-navButton'));

  const currentLink = navLinks.find((button) => {
    const windowPath = window.location.pathname;
    const parentRoute = button.attributes.getNamedItem('data-parent-route')?.value;
    const parentRoutes = parentRoute?.split(';');
    return !!parentRoutes ? parentRoutes.some((route) => windowPath.includes(route)) : false;
  });

  // We need to check if scrollIntoView exists as it does not exist in Jest.
  if (!!currentLink && !!currentLink.scrollIntoView) {
    currentLink.scrollIntoView(false);

    const nav = document.getElementsByClassName('petResultsNav-nav')[0];

    // Adjust the scroll so the right item peaks out.
    if (!!nav && nav.scrollLeft > 0) {
      nav.scrollTo({
        left: nav.scrollLeft + 50,
      });
    }
  }
}
