import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { FC } from 'react';
import { GeneticStatsSection } from '@embark/shared-components/src/geneticStats/geneticStatsSection';
import { GeneticStatsSectionFragment } from '@embark/shared-components/src/generated/graphql-client-types';
import ReactDOM from 'react-dom';
import { useAppSelector } from 'projects/legacyClient/src/react/state/hooks';

export function initPublicProfileGeneticStatsSection(): void {
  ReactDOM.render(
    <AppProviders>
      <GeneticStatsSectionContainer />
    </AppProviders>,
    document.getElementById('public-profile-genetic-stats')
  );
}

const GeneticStatsSectionContainer: FC = () => {
  const data: GeneticStatsSectionFragment = window['reactPet'];
  const accountDetails = useAppSelector((state) => state.accountDetails);

  return <GeneticStatsSection pet={data} accountDetails={accountDetails} isPublicProfile={true} />;
};
