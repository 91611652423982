import { Dispatch, RefObject, SetStateAction, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';

import { AutocompleteChangeDetails } from '@mui/material';
import { AutocompleteChangeReason } from '@mui/material/useAutocomplete';
import { HeapCustomEvent } from 'clientServerShared/types/eventTracking';
import { LocationAutocompleteProps } from 'projects/sharedComponents/src/locationAutocomplete/locationAutocomplete';
import { UseQueryResult } from 'react-query';
import { debounce } from 'lodash';
import { trackHeapEvent } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';
import { usePromise } from 'react-use';

export type GooglePlacesAutocompletePrediction = google.maps.places.AutocompletePrediction;
export type GooglePlacesDetail = google.maps.places.PlaceResult;
export type LocationAutocompleteResult = GooglePlacesAutocompletePrediction & { details?: GooglePlacesDetail | null };

export interface GooglePlacesGetPlacePredictionsReturn {
  predictions?: GooglePlacesAutocompletePrediction[];
}

type UseLocationAutocompleteHookReturn = {
  setInputValue: Dispatch<SetStateAction<string>>;
  options: GooglePlacesAutocompletePrediction[];
  handleChange: LocationAutocompleteProps['onChange'];
};

export function useLocationAutocompleteHook(
  placesServiceRef: RefObject<HTMLDivElement>,
  onChange: LocationAutocompleteProps['onChange'],
  value?: LocationAutocompleteProps['value']
): UseLocationAutocompleteHookReturn {
  const [inputValue, setInputValue] = useState('');
  const placesService = useRef<google.maps.places.PlacesService | null>(null);

  const { data: options } = useFetchGoogleLocationPrediction(inputValue, value);

  useEffect(() => {
    if (!placesService.current && window.google && placesServiceRef.current) {
      placesService.current = new window.google.maps.places.PlacesService(placesServiceRef.current);
    }
  });

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    value: LocationAutocompleteResult | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<LocationAutocompleteResult>
  ): void => {
    if (!value) {
      if (!!onChange) {
        onChange(event, null, reason, details);
      }
      return;
    }

    if (!!placesService.current && !!onChange) {
      onChange(event, value, reason, details);
      placesService.current.getDetails({ placeId: value.place_id }, (result) => {
        trackHeapEvent(HeapCustomEvent.GOOGLE_MAPS_API_PLACES_REQUEST, {
          requestType: 'google.maps.places.PlacesService.getDetails',
          loadingContext: 'useLocationAutocompleteHook',
        });
        onChange(event, { ...value, details: result }, reason, details);
      });
    }
  };

  return {
    setInputValue,
    options,
    handleChange,
  };
}

type FetchGoogleLocationPredictionHookReturn = Pick<UseQueryResult, 'isError' | 'error'> & {
  data: GooglePlacesAutocompletePrediction[];
};

export function useFetchGoogleLocationPrediction(
  inputValue?: string,
  value?: LocationAutocompleteProps['value']
): FetchGoogleLocationPredictionHookReturn {
  const mounted = usePromise();
  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);
  const [options, setOptions] = useState<GooglePlacesAutocompletePrediction[]>([]);
  const [googleApiError, setGoogleApiError] = useState<Error | null>(null);

  const fetchGooglePlacePredictions = useMemo(
    () =>
      debounce(async (request: { input: string }): Promise<void> => {
        if (!autocompleteService.current) {
          return;
        }

        try {
          const results: GooglePlacesGetPlacePredictionsReturn = await mounted(
            autocompleteService.current.getPlacePredictions(request)
          );

          trackHeapEvent(HeapCustomEvent.GOOGLE_MAPS_API_PLACES_REQUEST, {
            requestType: 'google.maps.places.AutocompleteService.getPlacePredictions',
            loadingContext: 'useFetchGoogleLocationPrediction',
          });

          if (!!results.predictions) {
            setOptions(results.predictions);
          }
        } catch (e) {
          setGoogleApiError(e as Error);
        }
      }, 800),
    [setOptions, setGoogleApiError, mounted]
  );

  useEffect(() => {
    if (!!googleApiError) {
      return;
    }

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current || !inputValue) {
      return;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return;
    }
    if (inputValue == value?.description) {
      return;
    }

    void fetchGooglePlacePredictions({ input: inputValue });
  }, [googleApiError, inputValue, fetchGooglePlacePredictions, value]);

  return { data: options, isError: !!googleApiError, error: googleApiError };
}
