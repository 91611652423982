import { FeedbackItem } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { HeapCategory } from 'clientServerShared/types/eventTracking';
import { SubmitHandler } from 'react-hook-form';
import { showCommonErrorSnackbar } from 'projects/sharedComponents/src/services/snackbar/snackbar';
import { useAddFeedbackMutation } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { useSnackbar } from 'notistack';

export type FeedbackProps = {
  page: string;
  variant?: string | null;
  petUuid?: string | null;
  helpfulFeedbackItems?: FeedbackItem[];
  unhelpfulFeedbackItems?: FeedbackItem[];
  heapCategory: HeapCategory;
  helpfulConfirmationText?: string | null;
  unhelpfulConfirmationText?: string | null;
  pageVariables?: string | null;
};

export type AddFeedbackFormInput = {
  selectedFeedback?: { [Key in FeedbackItem]?: boolean };
  additionalText?: string;
};

type UseAddFeedbackRequestProps = FeedbackProps & {
  isHelpful: boolean;
  showConfirmation: (value: boolean) => void;
};

type UseAddFeedbackRequestReturn = {
  addFeedback: SubmitHandler<AddFeedbackFormInput>;
  loading: boolean;
};

export function useAddFeedbackRequestProps(props: UseAddFeedbackRequestProps): UseAddFeedbackRequestReturn {
  const { petUuid, page, variant, isHelpful, showConfirmation, pageVariables } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [createFeedback, { loading }] = useAddFeedbackMutation({
    onCompleted: () => {
      showConfirmation(true);
    },
    onError: () => {
      showCommonErrorSnackbar(enqueueSnackbar, 'Error submitting feedback');
    },
  });

  const addFeedback: SubmitHandler<AddFeedbackFormInput> = async (requestData): Promise<void> => {
    const selectedFeedbackAsList = Object.entries(requestData.selectedFeedback ?? {})
      .filter(([_feedbackItem, isSelected]) => isSelected)
      .map(([feedbackItem]) => feedbackItem) as FeedbackItem[];
    await createFeedback({
      variables: {
        feedback: {
          page,
          variant,
          petUuid,
          isHelpful,
          selectedFeedback: selectedFeedbackAsList,
          additionalText: requestData.additionalText,
          pageVariables,
        },
      },
    });
  };
  return { ...props, addFeedback, loading };
}
