import { Box } from '@mui/material';
import { Property } from 'csstype';
import React from 'react';

type ImageBoxBreakpoints = {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
};

type ImageBoxProps = {
  height?: Property.Height;
  display?: Property.Display;
  imageBreakpoints: ImageBoxBreakpoints;
  altText: string;
};

export const ImageBox: React.FC<ImageBoxProps> = ({ imageBreakpoints, display, height, altText }) => {
  const breakpoints: Array<keyof ImageBoxBreakpoints> = ['xs', 'sm', 'md', 'lg', 'xl'];
  const content = breakpoints.reduce<typeof imageBreakpoints>((previousValue, currentValue) => {
    const breakpointTarget = imageBreakpoints[currentValue];
    if (breakpointTarget) {
      previousValue[currentValue] = `url(${breakpointTarget})`;
    }
    return previousValue;
  }, {});

  return (
    <Box
      component="img"
      sx={{
        content,
        display,
        height,
      }}
      alt={altText}
    />
  );
};
