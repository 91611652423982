import {
  FooterPetFragment,
  FooterUserFragment,
  UserTypeGraphQl,
} from 'projects/sharedComponents/src/generated/graphql-client-types';
import { HeapAction, HeapCategory, HeapMetadata } from 'clientServerShared/types/eventTracking';
import { Link, LinkProps } from '@mui/material';

import { Link as ReactRouterLink } from 'react-router-dom';
import { buildMyEvLegacyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';

type DisallowedFooterLinkProps = {
  style?: never;
  children?: never;
  color?: never;
  textTransform?: never;
  variant?: never;
  underline?: never;
  TypographyClasses?: never;
  paragraph?: never;
  gutterBottom?: never;
  variantMapping?: never;
};

type FooterLinkBaseProps = {
  label: string;
  isNew?: boolean;
};

type HrefRequiredIfNotCustomComponent<C extends React.ElementType> =
  | { component: C }
  | { component?: never; href: string };

type HelixFooterLinkProps<C extends React.ElementType> = LinkProps<
  C,
  HrefRequiredIfNotCustomComponent<C> & FooterLinkBaseProps & DisallowedFooterLinkProps
>;

export type FooterLinkProps = HelixFooterLinkProps<typeof Link | typeof ReactRouterLink> & {
  /** True if we should use React router links for this link when on a React page */
  isReactRouterLink: boolean;
  shouldOpenInNewTab?: boolean;
  heap?: HeapMetadata;
};

export function buildFooterPetResultLinkProps(user: FooterUserFragment, pet: FooterPetFragment): FooterLinkProps[] {
  const footerLinks: FooterLinkProps[] = [];

  const profileLink: FooterLinkProps = {
    href: buildMyEvLegacyLink(`/members/results/profile?i=${pet.petNum}`),
    label: 'Profile',
    isReactRouterLink: false,
    heap: {
      heapAction: HeapAction.VIEW_PROFILE,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  };

  const summaryLink: FooterLinkProps = {
    href: `/members/results/summary?i=${pet.petNum}`,
    label: 'Summary',
    isReactRouterLink: true,
    heap: {
      heapAction: HeapAction.SUMMARY,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  };

  const researchLink: FooterLinkProps = {
    href: `/members/results/research-surveys?i=${pet.petNum}`,
    label: 'Research',
    isReactRouterLink: true,
    heap: {
      heapAction: HeapAction.RESEARCH,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  };

  const breedNavText = user.userType === UserTypeGraphQl.Vet ? 'Breed' : 'Breed & Ancestry';

  const breedLink: FooterLinkProps = {
    href: `/members/results/breed-and-ancestry?i=${pet.petNum}&countryCode=${user.countryCode}`,
    label: breedNavText,
    isReactRouterLink: true,
    heap: {
      heapAction: HeapAction.BREED,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  };

  const healthLink: FooterLinkProps = {
    href: buildMyEvLegacyLink(`/members/results/health?i=${pet.petNum}`),
    label: 'Health',
    isReactRouterLink: false,
    heap: {
      heapAction: HeapAction.HEALTH,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  };

  const traitLink: FooterLinkProps = {
    href: buildMyEvLegacyLink(`/members/results/traits?i=${pet.petNum}`),
    label: 'Traits',
    isReactRouterLink: false,
    heap: {
      heapAction: HeapAction.TRAITS,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  };

  const healthAndTraitsLink: FooterLinkProps = {
    href: buildMyEvLegacyLink(`/members/results/health?i=${pet.petNum}`),
    label: 'Health & Traits',
    isReactRouterLink: false,
    heap: {
      heapAction: HeapAction.HEALTH,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  };

  const relativesLink: FooterLinkProps = {
    href: buildMyEvLegacyLink(`/members/results/relatives?i=${pet.petNum}&countryCode=${user.countryCode}`),
    label: 'Relatives',
    isReactRouterLink: false,
    heap: {
      heapAction: HeapAction.RELATIVES,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  };

  const careResourcesLink: FooterLinkProps = {
    href: `/members/results/care?i=${pet.petNum}`,
    label: 'Care Resources',
    isReactRouterLink: true,
    isNew: true,
    heap: {
      heapAction: HeapAction.CARE_RESOURCES,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  };

  footerLinks.push(profileLink);
  footerLinks.push(summaryLink);
  footerLinks.push(researchLink);

  if (user.userType !== UserTypeGraphQl.PetParent && !pet.isUpgradeAvailable) {
    footerLinks.push(healthLink);
  }

  footerLinks.push(breedLink);

  if (pet.showRelativeFinder && user.userType !== UserTypeGraphQl.Vet) {
    footerLinks.push(relativesLink);
  }

  if (user.userType === UserTypeGraphQl.PetParent && !pet.isUpgradeAvailable) {
    footerLinks.push(healthLink);
  }

  if (!pet.isUpgradeAvailable) {
    footerLinks.push(traitLink);
  }

  if (pet.showRelativeFinder && user.userType === UserTypeGraphQl.Vet) {
    footerLinks.push(relativesLink);
  }

  if (pet.shouldShowCareResources) {
    footerLinks.push(careResourcesLink);
  }

  if (pet.isUpgradeAvailable) {
    footerLinks.push(healthAndTraitsLink);
  }

  return footerLinks;
}

export function buildMyAccountLinkProps(user: FooterUserFragment): FooterLinkProps[] {
  const footerLinks: FooterLinkProps[] = [];

  // Add a Dog
  footerLinks.push({
    // Vets add pets on their dashboard.
    href:
      user.userType === UserTypeGraphQl.Vet ? buildMyEvLegacyLink('/vets') : buildMyEvLegacyLink('/members/add-a-dog'),
    label: 'Add a Dog',
    isReactRouterLink: false,
    heap: {
      heapAction: HeapAction.ADD_PET,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  // My Dogs
  if (user.userType !== UserTypeGraphQl.PetParent || user.petCount > 1) {
    footerLinks.push({
      href: buildMyEvLegacyLink('/'),
      label: 'My Dogs',
      // This currently relies on hitting the server but shouldn't long term.
      isReactRouterLink: false,
      heap: {
        heapAction: HeapAction.DASHBOARD,
        heapCategory: HeapCategory.FOOTER_NAV,
      },
    });
  }

  // Activate Kit
  footerLinks.push({
    href: buildMyEvLegacyLink('/members/activate'),
    label: 'Activate Kit',
    isReactRouterLink: false,
    heap: {
      heapAction: HeapAction.ACTIVATE_KIT,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  let shopLink = `${user.shopLink}/pages/shop-all`;
  if (user.userType === UserTypeGraphQl.Breeder) {
    shopLink = `${user.shopLink}/products/embark-for-breeders-dog-dna-test-kit`;
  } else if (user.userType === UserTypeGraphQl.Vet) {
    shopLink = buildMyEvLegacyLink('/members/shopify/vet');
  }

  // Order a Kit
  footerLinks.push({
    href: shopLink,
    label: 'Order a Kit',
    isReactRouterLink: false,
    shouldOpenInNewTab: true,
    heap: {
      heapAction: HeapAction.ORDER_KITS,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  // Refer a Friend
  footerLinks.push({
    href:
      user.userType === UserTypeGraphQl.Breeder
        ? buildMyEvLegacyLink(`/members/breeder-referrals?countryCode=${user.countryCode}`)
        : buildMyEvLegacyLink(`/members/referrals?countryCode=${user.countryCode}`),
    label: user.userType === UserTypeGraphQl.Breeder ? 'Refer a Breeder' : 'Refer a Friend',
    isReactRouterLink: false,
    heap: {
      heapAction: user.userType === UserTypeGraphQl.Breeder ? HeapAction.REFER_A_BREEDER : HeapAction.REFER_A_FRIEND,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  // Messages
  footerLinks.push({
    href: buildMyEvLegacyLink('/members/messages'),
    label: 'Messages',
    isReactRouterLink: false,
    heap: {
      heapAction: HeapAction.CHAT,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  // Settings
  footerLinks.push({
    href: buildMyEvLegacyLink('/members/profile'),
    label: 'Settings',
    isReactRouterLink: false,
    heap: {
      heapAction: HeapAction.USER_SETTINGS,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  return footerLinks;
}

export function buildBreederToolsLinkProps(user: FooterUserFragment): FooterLinkProps[] {
  const footerLinks: FooterLinkProps[] = [];

  // Breeder Profile
  footerLinks.push({
    href: '/breeders/breeder-profile',
    label: 'Breeder Profile',
    isReactRouterLink: true,
    heap: {
      heapAction: HeapAction.BREEDER_PROFILE,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  // Matchmaker
  if (user.permissions.showMatchMaker) {
    footerLinks.push({
      href: buildMyEvLegacyLink('/members/breeder-tools/matchmaker'),
      label: 'Matchmaker',
      isReactRouterLink: false,
      heap: {
        heapAction: HeapAction.MATCHMAKER,
        heapCategory: HeapCategory.FOOTER_NAV,
      },
    });
  }

  // Pair Predictor
  footerLinks.push({
    href: '/breeders/pair-predictor',
    label: 'Pair Predictor',
    isReactRouterLink: true,
    isNew: true,
    heap: {
      heapAction: HeapAction.PAIR_PREDICTOR,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  return footerLinks;
}

export function buildMoreLinkProps(user: FooterUserFragment, pet: FooterPetFragment | null): FooterLinkProps[] {
  const footerLinks: FooterLinkProps[] = [];

  // embarkvet.com
  footerLinks.push({
    href: user.ecommLink,
    label: 'EmbarkVet.com',
    isReactRouterLink: false,
    shouldOpenInNewTab: true,
    heap: {
      heapAction: HeapAction.EMBARK_WEBSITE,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  // Pet insurance
  if (!!pet && pet.isEligibleForInsurance) {
    footerLinks.push({
      href: pet.insuranceUrl,
      label: 'Pet Insurance',
      isReactRouterLink: false,
      shouldOpenInNewTab: true,
      heap: {
        heapAction: HeapAction.INSURANCE,
        heapCategory: HeapCategory.FOOTER_NAV,
      },
    });
  }

  // Help Center
  footerLinks.push({
    href: buildMyEvLegacyLink('/help'),
    label: 'Help Center',
    isReactRouterLink: false,
    heap: {
      heapAction: HeapAction.SUPPORT_HELP_CENTER,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  // Resources & Insights
  footerLinks.push({
    href: `${user.ecommLink}/resources`,
    label: 'Resources & Insights',
    isReactRouterLink: false,
    shouldOpenInNewTab: true,
    heap: {
      heapAction: HeapAction.RESOURCE_CENTER,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  // Contact Us
  footerLinks.push({
    href: `${user.ecommLink}/support`,
    label: 'Contact Us',
    isReactRouterLink: false,
    shouldOpenInNewTab: true,
    heap: {
      heapAction: HeapAction.CONTACT_US,
      heapCategory: HeapCategory.FOOTER_NAV,
    },
  });

  return footerLinks;
}
