import * as $ from 'jquery';
import * as _ from 'lodash';

import {
  AKC_PREFIX_TITLES,
  AKC_SUFFIX_TITLES,
  POPULAR_CUSTOMER_SUFFIX_TITLES,
  UKC_PREFIX_TITLES,
} from '../../../../models/petTitles';

import InputTag from 'vue-input-tag';
import { PetWithTitlesList } from './types/aboutPet';
import Vue from 'vue';

const PRESORTED_LOWERCASE_PREFIX_TITLES = transformTitle([...UKC_PREFIX_TITLES, ...AKC_PREFIX_TITLES]);
const PRESORTED_LOWERCASE_SUFFIX_TITLES = transformTitle([...AKC_SUFFIX_TITLES, ...POPULAR_CUSTOMER_SUFFIX_TITLES]);

function transformTitle(titles: string[]): string[] {
  return Array.from(new Set(titles))
    .map((title) => title.toLowerCase())
    .sort();
}

export function init(data: PetWithTitlesList): void {
  const titleTypes = ['prefixTitles', 'suffixTitles'];
  for (const type of titleTypes) {
    if (data.profile[type] && data.profile[type].length) {
      data.profile[type] = _.map(data.profile[type].split(', '), (a) => a.toLowerCase());
    } else {
      data.profile[type] = [];
    }
  }

  data.handleFeedback = '';

  Vue.component('input-tag', InputTag);

  new Vue({
    el: '#aboutpet-name-and-story-container-vue',
    data: data,
    methods: {},
    computed: {},
    watch: {
      handle: _.debounce(checkHandle, 1000),
    },
  });

  $('.titles-div div.vue-input-tag-wrapper').each(function (index) {
    let titleType;
    let autoCompleteList;
    const titleId = index + 1;
    titleId === 1 ? (titleType = titleTypes[0]) : (titleType = titleTypes[1]);
    $(this)
      .find('input.new-tag')
      .attr('id', 'title' + titleId);
    $(`#title${titleId}`).on('keyup', function () {
      const query = $(`#title${titleId}`).val() as string;
      if (query !== '') {
        $(`#searchResult-${titleType}`).empty();
        if (titleType === titleTypes[0]) {
          autoCompleteList = _.difference(PRESORTED_LOWERCASE_PREFIX_TITLES, data.profile[titleType]);
        } else {
          autoCompleteList = _.difference(PRESORTED_LOWERCASE_SUFFIX_TITLES, data.profile[titleType]);
        }
        // Case insensitive search for autocomplete list
        // User may enter things like `ABc (123` which is an invalid RegExp, so we need to escape it
        const escapedQuerySuitableForRegExp = query.replace(/[\/\\^$*+?.()|[\]{}]/g, '\\$&'); // eslint-disable-line
        const results = $.grep(autoCompleteList, function (item: string) {
          return item.search(RegExp(escapedQuerySuitableForRegExp, 'i')) !== -1;
        });

        for (const term in results) {
          $(`#searchResult-${titleType}`).append('<li>' + results[term] + '</li>');
        }
      } else {
        $(`#searchResult-${titleType}`).empty();
      }
    });

    // Bind click event to list elements in results
    $(`#searchResult-${titleType}`).on('click', 'li', function () {
      data.profile[titleType].push($(this).text().toLowerCase());
      $(`#searchResult-${titleType}`).empty();
    });
  });

  function checkHandle(handle: string): void {
    void $.get(`/members/check-handle/'${encodeURIComponent(handle)}`, function (result) {
      if (result === handle) {
        data.handleFeedback = '<em class="aboutPet-handleFeedback text-green">Available</em>';
      } else {
        data.handleFeedback = `<em class="aboutPet-handleFeedback text-red">${handle} is not available.</em> Alternative: <strong class="text-green">${result}</strong>`;
      }
    });
  }
}
