import * as Sentry from '@sentry/react';

import React, { FC } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { ApolloClientProvider } from '@embark/shared-components/src/providers/apolloClientProvider';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { legacyClientStore } from 'projects/legacyClient/src/react/state/legacyClientStore';
import { sharedTheme } from '@embark/shared-components/src/theme/sharedTheme';

/** Sets up all global Context providers that are needed for our core libraries. */
export const AppProviders: FC = (props) => {
  const { children } = props;

  return (
    <React.StrictMode>
      <BrowserRouter>
        <ApolloClientProvider>
          <ReduxProvider store={legacyClientStore}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={sharedTheme}>
                <SnackbarProvider>
                  <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>
                </SnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </ReduxProvider>
        </ApolloClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};
