import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { FC } from 'react';
import { HeapCategory } from 'clientServerShared/types/eventTracking';
import ReactDOM from 'react-dom';
import { ShareResultsWithVetCard } from '@embark/shared-components/src/shareResultsWithVet/shareResultsWithVetCard';
import { getDataAttributeOrThrow } from 'projects/legacyClient/src/react/services/attributeHelpers';
import { getPetNumFromQueryString } from 'projects/legacyClient/src/react/pet/getPetNumFromQueryString';
import { toNumber } from 'lodash';
import { useGetPetForShareResultsWithVetQuery } from '@embark/shared-components/src/generated/graphql-client-types';

export const shareResultsWithVetCardId = 'share-results-with-vet';

export function initShareResultsWithVetCard(): void {
  ReactDOM.render(
    <AppProviders>
      <ShareResultsWithVetContainer />
    </AppProviders>,
    document.getElementById(shareResultsWithVetCardId)
  );
}

const ShareResultsWithVetContainer: FC = () => {
  const petNum = getPetNumFromQueryString();
  const { data } = useGetPetForShareResultsWithVetQuery({
    skip: !petNum,
    variables: {
      petNum: toNumber(petNum),
    },
  });

  const pet = data?.me.petByPetNum;
  if (!pet) {
    return null;
  }

  const shareResultsWithVetCardElement = document.getElementById(shareResultsWithVetCardId);
  const heapCategory = getDataAttributeOrThrow(shareResultsWithVetCardElement, 'heapCategory') as HeapCategory;

  const shareResultsWithVetCardWrapperElement = document.getElementById('share-results-with-vet-wrapper');
  if (!!shareResultsWithVetCardWrapperElement) {
    shareResultsWithVetCardWrapperElement.hidden = false;
  }

  return <ShareResultsWithVetCard pet={pet} heapCategory={heapCategory} />;
};
