import { Autocomplete, Box, Typography } from '@mui/material';
import {
  LocationAutocompleteResult,
  useLocationAutocompleteHook,
} from 'projects/sharedComponents/src/locationAutocomplete/locationAutocompleteHooks';
import React, { useRef } from 'react';

import { AutocompleteProps } from '@mui/material/Autocomplete';

export type LocationAutocompleteProps = Omit<
  AutocompleteProps<LocationAutocompleteResult, false, false, false>,
  'options' | 'getOptionSelected' | 'onInputChange' | 'filterOptions'
> & {
  // list of options for place types: https://developers.google.com/maps/documentation/places/web-service/supported_types
  placeTypesToInclude?: LocationAutocompleteResult['types'];
};

// inspired by https://v4.mui.com/components/autocomplete/#google-maps-place
export const LocationAutocomplete: React.FC<LocationAutocompleteProps> = (props) => {
  const placesServiceRef = useRef<HTMLDivElement>(null);
  const { value, placeTypesToInclude, onChange, ...muiAutocompleteProps } = props;
  const { setInputValue, options, handleChange } = useLocationAutocompleteHook(placesServiceRef, onChange, value);

  return (
    <section>
      <Autocomplete
        getOptionLabel={(address) => address.description}
        renderOption={(autocompleteProps, option) => {
          return (
            <li {...autocompleteProps}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">
                  <strong>{option.structured_formatting.main_text}</strong>
                </Typography>
                <Box ml={1}>
                  <Typography variant="caption">{option.structured_formatting.secondary_text}</Typography>
                </Box>
              </Box>
            </li>
          );
        }}
        {...muiAutocompleteProps}
        isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
        options={options}
        value={value}
        onChange={handleChange}
        onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
        filterOptions={(options) =>
          !!placeTypesToInclude
            ? options.filter((o) => placeTypesToInclude.some((t) => o.types.indexOf(t) >= 0))
            : options
        }
      />
      <div ref={placesServiceRef} />
    </section>
  );
};
