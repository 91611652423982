import { ButtonProps } from '@mui/material';
import { HeapCategory } from 'clientServerShared/types/eventTracking';
import { ShareResultsWithVetFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { buildEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';
import { copyLinkButtonOnClickHandler } from 'projects/sharedComponents/src/services/eventHandlers/copyLinkButtons';
import { useState } from 'react';

export type UseShareResultsWithVetCardProps = {
  pet: ShareResultsWithVetFragment;
  iconUrl?: string;
  heapCategory: HeapCategory;
};

type UseShareResultsWithVetCardReturn = UseShareResultsWithVetCardProps & {
  vetReportAbsoluteUrl: string;
  copyLinkText: string;
  iconUrl: string;
  onClickCopyLink: ButtonProps['onClick'];
};

export function useShareResultsWithVetCard(props: UseShareResultsWithVetCardProps): UseShareResultsWithVetCardReturn {
  const { pet } = props;
  const { iconUrl = buildEmbarkCDNUrl('/img/health/icon-vet-illustration.svg') } = props;

  const vetReportAbsoluteUrl = new URL(
    `/members/reports/print-vet-report-pdf/${pet.hashPetId}`,
    window.location.origin
  ).toString();

  const [copyLinkText, setCopyLinkText] = useState('Copy link');
  const onClickCopyLink: UseShareResultsWithVetCardReturn['onClickCopyLink'] = async (event) => {
    await copyLinkButtonOnClickHandler(event);
    setCopyLinkText('Link copied');
    setTimeout(() => {
      setCopyLinkText('Copy link');
    }, 2000);
  };

  return {
    ...props,
    iconUrl,
    vetReportAbsoluteUrl,
    copyLinkText,
    onClickCopyLink,
  };
}
