<template>
  <div>
    <select :aria-label="label" :name="name" :id="name" :value="value" @input="$emit('input', +$event.target.value)">
      <option value="0">{{ label }}</option>
      <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
    </select>
  </div>
</template>
<script>
import Vue from 'vue';
export default Vue.extend({
  props: ['value', 'label', 'name', 'options'],
});
</script>
