// enable the JavaScript API for an embedded player
export function init() {
  const e = document.getElementsByTagName('iframe');
  let youtubePresent = false;
  for (let x = e.length; x--; ) {
    if (/youtube.com\/embed/.test(e[x].src)) {
      youtubePresent = true;
      if (e[x].src.indexOf('enablejsapi=') === -1) {
        e[x].src += (e[x].src.indexOf('?') === -1 ? '?' : '&') + 'enablejsapi=1';
      }
    }
  }

  if (!youtubePresent) {
    return;
  }

  // load the Youtube JS api and get going
  const j = document.createElement('script');
  const f = document.getElementsByTagName('script')[0];
  j.src = '//www.youtube.com/iframe_api';
  j.async = true;
  f.parentNode.insertBefore(j, f);

  const gtmYTListeners = []; // support multiple players on the same page
  // attach our YT listener once the API is loaded
  window.onYouTubeIframeAPIReady = function () {
    const e = document.getElementsByTagName('iframe');
    for (let x = e.length; x--; ) {
      if (/youtube.com\/embed/.test(e[x].src)) {
        gtmYTListeners.push(
          new window.YT.Player(e[x], {
            events: {
              onStateChange: onPlayerStateChange,
              onError: onPlayerError,
            },
          })
        );
        window.YT.gtmLastAction = 'p';
      }
    }
  };

  // listen for play, pause and end states
  // also report % played every second
  function onPlayerStateChange(e) {
    const YT = window.YT;
    e['data'] === YT.PlayerState.PLAYING && setTimeout(onPlayerPercent, 1000, e['target']);
    const videoData = e.target['getVideoData']();
    let label = videoData.title;

    // Get title of the current page
    const pageTitle = document.title;
    if (e['data'] === YT.PlayerState.PLAYING && YT.gtmLastAction === 'p') {
      label = 'Video Played - ' + videoData.title;
      window.dataLayer.push({
        event: 'youtube',
        eventCategory: 'Youtube Videos',
        eventAction: pageTitle,
        eventLabel: label,
      });
      YT.gtmLastAction = '';
    } else if (e['data'] === YT.PlayerState.PAUSED) {
      label = 'Video Paused - ' + videoData.title;
      window.dataLayer.push({
        event: 'youtube',
        eventCategory: 'Youtube Videos',
        eventAction: pageTitle,
        eventLabel: label,
      });
      YT.gtmLastAction = 'p';
    }
  }

  // catch all to report errors through the GTM data layer
  // once the error is exposed to GTM, it can be tracked in UA as an event!
  function onPlayerError(e) {
    window.dataLayer.push({
      event: 'error',
      eventCategory: 'Youtube Videos',
      eventAction: 'GTM',
      eventLabel: 'youtube:' + e['target']['src'] + '-' + e['data'],
    });
  }

  // report the % played if it matches 0%, 25%, 50%, 75% or completed
  function onPlayerPercent(e) {
    const YT = window.YT;
    if (e['getPlayerState']() === YT.PlayerState.PLAYING) {
      let t;
      if (e['getDuration']() - e['getCurrentTime']() <= 1.5) {
        t = 1;
      } else {
        t = (Math.floor((e['getCurrentTime']() / e['getDuration']()) * 4) / 4).toFixed(2);
      }
      if (!e['lastP'] || t > e['lastP']) {
        const videoData = e['getVideoData']();
        // Get title of the current page
        const pageTitle = document.title;
        e['lastP'] = t;
        const label = t * 100 + '% Video played - ' + videoData.title;
        window.dataLayer.push({
          event: 'youtube',
          eventCategory: 'Youtube Videos',
          eventAction: pageTitle,
          eventLabel: label,
        });
      }
      e['lastP'] !== 1 && setTimeout(onPlayerPercent, 1000, e);
    }
  }
}
