import 'projects/sharedComponents/src/publicProfile/breedSummary/publicProfileBreedSummaryCard.scss';

import { Card, CardProps, Grid, Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { LegacyBreedHalo } from 'projects/sharedComponents/src/dogAvatar/legacy/legacyBreedHalo';
import { PublicProfileBreedSummaryBars } from 'projects/sharedComponents/src/publicProfile/breedSummary/publicProfileBreedSummaryBars';
import { PublicProfileBreedSummaryCardFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { shorten } from 'projects/sharedComponents/src/services/stringFormatters/stringFormatters';
import { styled } from '@mui/material/styles';

type PublicProfileBreedSummaryCardProps = {
  dog: PublicProfileBreedSummaryCardFragment;
};

export const PublicProfileBreedSummaryCard: FC<PublicProfileBreedSummaryCardProps> = ({ dog }) => {
  const dogName = shorten(dog.name, 25);

  return (
    <SummaryCard>
      <Stack spacing={3}>
        <Grid container spacing={3} className="breedSummaryCard-clickableContent">
          <Grid item container className="breedSummaryCard-haloContainer" sm={3} direction="column" alignItems="center">
            <Grid item>
              <LegacyBreedHalo dog={dog} width={138} />
            </Grid>
          </Grid>
          <Grid className="breedSummaryCard-dogDetails" container item direction="column" sm={9} spacing={1}>
            <Grid item container>
              <Grid item sm={6}>
                <Typography variant="h5" component="p">
                  <strong>{dogName}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>
                <strong>{dog.genotype?.breedType}</strong>
              </Typography>
            </Grid>
            <Grid item className="breedSummaryCard-breedBars-container">
              <PublicProfileBreedSummaryBars dog={dog} />
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </SummaryCard>
  );
};

const SummaryCard = styled(Card)<CardProps>(({ theme }) => ({
  borderRadius: 0,
  boxShadow: `0 1px 8px ${theme.palette.helix.greyLight}`,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));
