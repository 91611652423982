import 'projects/sharedComponents/src/feedback/feedbackDialogForm.scss';

import { Button, Typography } from '@mui/material';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { FC, MouseEventHandler, useState } from 'react';

import { AddFeedbackFormInput } from 'projects/sharedComponents/src/feedback/feedbackHooks';
import { FeedbackDialogChip } from 'projects/sharedComponents/src/feedback/feedbackDialogChip';
import { FeedbackItem } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { HeapCategory } from 'clientServerShared/types/eventTracking';
import { LoadingButton } from 'projects/sharedComponents/src/buttons/loadingButton';
import { RenderIf } from 'projects/sharedComponents/src/renderStates/renderIf';
import { TextFieldCharacterLimit } from 'projects/sharedComponents/src/textField/textFieldCharacterLimit';
import { getHeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';

type FeedbackDialogFormProps = {
  helpfulFeedbackItems: FeedbackItem[];
  unhelpfulFeedbackItems: FeedbackItem[];
  heapCategory: HeapCategory;
  isHelpful: boolean;
  isLoading: boolean;
  addFeedback: SubmitHandler<AddFeedbackFormInput>;
  onCloseDialog: MouseEventHandler;
};

export const FeedbackDialogForm: FC<FeedbackDialogFormProps> = (props) => {
  const {
    helpfulFeedbackItems,
    unhelpfulFeedbackItems,
    isHelpful,
    isLoading,
    addFeedback,
    onCloseDialog,
    heapCategory,
  } = props;
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<AddFeedbackFormInput>();
  const [showAdditionalText, setShowAdditionalText] = useState(false);

  return (
    <form onSubmit={handleSubmit(addFeedback)}>
      <RenderIf condition={isHelpful}>
        <Typography className="feedbackDialogForm-header">What did you like about this page?</Typography>
        <div className="feedbackDialogForm-chipContainer">
          {helpfulFeedbackItems.map((feedbackItem) => {
            return <FeedbackDialogChip key={feedbackItem} feedbackItem={feedbackItem} heapCategory={heapCategory} />;
          })}
        </div>
      </RenderIf>
      <RenderIf condition={!isHelpful}>
        <Typography className="feedbackDialogForm-header">What disappointed you about this page?</Typography>
        <div className="feedbackDialogForm-chipContainer">
          {unhelpfulFeedbackItems.map((feedbackItem) => {
            return <FeedbackDialogChip key={feedbackItem} feedbackItem={feedbackItem} heapCategory={heapCategory} />;
          })}
        </div>
      </RenderIf>
      <div className="feedbackDialogForm-additionalTextBlock">
        <RenderIf condition={!showAdditionalText}>
          <Button
            className="feedbackDialogForm-button"
            onClick={() => setShowAdditionalText(true)}
            {...getHeapDataAttributes({
              heapCategory,
              heapAction: 'Show Additional Feedback',
            })}
          >
            Anything else to add?
          </Button>
        </RenderIf>
        <RenderIf condition={showAdditionalText}>
          <Controller
            name="additionalText"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextFieldCharacterLimit
                {...getHeapDataAttributes({
                  heapCategory,
                  heapAction: 'Add Additional Feedback',
                  heapDetails: field.value,
                })}
                className="feedbackDialogForm-additionalText"
                id="additionalText"
                aria-label="Add additional feedback text area"
                limit={500}
                variant="outlined"
                multiline
                fullWidth
                minRows={4}
                maxRows={6}
                onChange={field.onChange}
              />
            )}
          />
        </RenderIf>
      </div>

      <div className="feedbackDialogForm-buttonGroup">
        <LoadingButton
          className="feedbackDialogForm-submitButton"
          type="submit"
          variant="contained"
          color="primary"
          loading={isLoading}
          disabled={!isValid}
          {...getHeapDataAttributes({
            heapCategory,
            heapAction: 'Submit Feedback',
          })}
        >
          Submit
        </LoadingButton>
        <Button
          className="feedbackDialogForm-button"
          onClick={onCloseDialog}
          {...getHeapDataAttributes({
            heapCategory,
            heapAction: 'Cancel Feedback',
          })}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
