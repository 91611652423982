<template>
  <div>
    <template v-for="option in options">
      <input
        style="border: none"
        type="radio"
        :id="`${inputIdPrefix}-${option.value}`"
        :name="name"
        @input="$emit('input', $event.target.value)"
        v-model="value"
        :value="option.value"
      />
      <label :class="buttonClasses" :style="buttonStyle" :for="`${inputIdPrefix}-${option.value}`">
        {{ option.label }}
      </label>
    </template>
  </div>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      required: true,
      type: String,
    },
    inputIdPrefix: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
      validator(options) {
        return options.length > 0 && options.every((option) => !!option.value && !!option.label);
      },
    },
    buttonClasses: {
      type: String,
      required: false,
    },
    buttonStyle: {
      type: String,
      required: false,
    },
  },
});
</script>
<style scoped>
div {
  position: relative;
}

input[type='radio'] {
  position: absolute;
  opacity: 0;
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  border: 0;

  display: block;
}

input[type='radio'] + label {
  border: 1px solid var(--embark-light-grey);
}

/* This handles focus events */
input[type='radio']:focus + label {
  outline: black dotted 1px;
}

input[type='radio']:checked + label {
  border: 4px solid var(--embark-yellow);
}
</style>
