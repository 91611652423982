import { Bar } from '@visx/shape';
import { FC } from 'react';
import { Group } from '@visx/group';

type PublicProfileBreedSummaryBarProps = {
  colorIndex: number;
  width: number;
  isPurebredKit: boolean;
};

export const PublicProfileBreedSummaryBar: FC<PublicProfileBreedSummaryBarProps> = ({
  colorIndex,
  width,
  isPurebredKit,
}) => {
  const svgClassName = isPurebredKit ? 'breedBar-purebredColors' : 'breedBar-normalColors';
  return (
    <>
      <svg className={`${svgClassName}`} width={`${width}%`} height={8} aria-hidden>
        <Group>
          <Bar className={`breedBar-breedColor-${colorIndex} breedBar-animate`} width="100%" height={8} rx={5} />
        </Group>
      </svg>
    </>
  );
};
