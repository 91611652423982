import { Button } from '@mui/material';
import { FC } from 'react';
import { LifeStageCalculationModal } from 'projects/sharedComponents/src/geneticStats/lifeStage/lifeStageCalculationModal';
import { useToggle } from 'react-use';

export const LifeStageCalculationModalButton: FC = () => {
  const [modalOpen, setModalOpen] = useToggle(false);

  return (
    <>
      <Button variant="text" onClick={setModalOpen} className="geneticStatsModal-button">
        <strong>How is life stage calculated?</strong>
      </Button>
      <LifeStageCalculationModal open={modalOpen} onClose={setModalOpen} />
    </>
  );
};
