const $ = require('jquery');
const _ = require('lodash');

import { ROW_LIMIT } from '../../../../models/dataTableConstants';

// Keys of dataTables are valid 'tableName' values eg pets. The Values describe the search fields and isLimitHit and URL for each admin page with a primary-data-table
const dataTables = {
  pets: {
    url: '/admin/data-table/pets_info',

    // Hold a reference to the DataTable once initialized
    dataTable: null,

    // The ajax-data function uses this inputId variable to know which form's input value to send to the server
    currentInputId: null,

    // The Keys of searchFields are the element IDs of input fields in the HTML page
    // The Values of searchFields are functions that return the search object sent as 'data' by the DataTables ajax request
    searchFields: {
      adminPetsSearchPetNameOrIdForm(userInput) {
        return $.isNumeric(userInput) ? { petID: userInput } : { petName: `%${userInput}%` };
      },
      adminPetsSearchPetHandleForm(userInput) {
        return { petHandle: userInput.toLowerCase() };
      },
    },

    isLimitHit(currentInputId, data) {
      // Must return true if the SQL statement for the search hit any of its LIMIT clauses
      return data.responseJSON.recordsTotal >= ROW_LIMIT;
    },
  },

  users: {
    url: '/admin/data-table/users',

    // Hold a reference to the DataTable once initialized
    dataTable: null,

    // The ajax-data function uses this inputId variable to know which form's input value to send to the server
    currentInputId: null,

    // The Keys of searchFields are the element IDs of input fields in the HTML page
    // The Values of searchFields are functions that return the search object sent as 'data' by the DataTables ajax request
    searchFields: {
      adminUsersSearchEmailOrNameForm(userInput) {
        return { userNameOrEmail: `%${userInput}%` };
      },
      adminUsersSearchUserIdForm(userInput) {
        return { userId: userInput };
      },
    },

    isLimitHit(currentInputId, data) {
      // Must return true if the SQL statement for the search hit any of its LIMIT clauses
      return data.responseJSON.recordsTotal >= ROW_LIMIT;
    },
  },

  authorizedReswabs: {
    url: '/admin/data-table/authorized_reswabs',

    // Hold a reference to the DataTable once initialized
    dataTable: null,

    // The ajax-data function uses this inputId variable to know which form's input value to send to the server
    currentInputId: null,

    // The Keys of searchFields are the element IDs of input fields in the HTML page
    // The Values of searchFields are functions that return the search object sent as 'data' by the DataTables ajax request
    searchFields: {
      authorizedReswabsUserIDForm(userInput) {
        return [{ userOrPetID: userInput }, 'authorizedReswab'];
      },
      authorizedReswabsBadSwabCodeForm(userInput) {
        return [{ badSwabCode: `%${userInput}%` }, 'authorizedReswab'];
      },
      authorizedReswabsAuthorizedByForm(userInput) {
        return $.isNumeric(userInput)
          ? [{ createdBy: userInput }, 'authorizedReswab']
          : [{ authorizedBy: `%${userInput}%` }, 'authorizedReswab'];
      },
    },

    isLimitHit(currentInputId, data) {
      // Must return true if the SQL statement for the search hit any of its LIMIT clauses
      return data.responseJSON.recordsTotal >= ROW_LIMIT;
    },

    hasMoreThanOneTable: true,
  },

  orderedReswabs: {
    url: '/admin/data-table/ordered_reswabs',

    // Hold a reference to the DataTable once initialized
    dataTable: null,

    // The ajax-data function uses this inputId variable to know which form's input value to send to the server
    currentInputId: null,

    // The Keys of searchFields are the element IDs of input fields in the HTML page
    // The Values of searchFields are functions that return the search object sent as 'data' by the DataTables ajax request
    searchFields: {
      orderedReswabsUserIDForm(userInput) {
        return [{ userOrOrderedReswabID: userInput }, 'orderedReswab'];
      },
      orderedReswabsOrderIDForm(userInput) {
        return [{ orderId: userInput }, 'orderedReswab'];
      },
      orderedReswabsCustomerForm(userInput) {
        return [{ customer: `%${userInput}%` }, 'orderedReswab'];
      },
    },

    isLimitHit(currentInputId, data) {
      // Must return true if the SQL statement for the search hit any of its LIMIT clauses
      return data.responseJSON.recordsTotal >= ROW_LIMIT;
    },

    hasMoreThanOneTable: true,
  },
};

function handleSubmit(ev, inputId, tableName) {
  // Only submit the search query when the user presses Enter key
  if (ev.key !== 'Enter') {
    return;
  }

  const table = dataTables[tableName];
  table.currentInputId = inputId; // the ajax `data` function will read it from here each time the user searches

  // Must provide a search term; cannot be blank
  const inputValue = _.trim(document.getElementById(inputId).value);
  if (!inputValue) {
    return;
  }

  const limitWarningMessage = document.getElementById('limitWarningMessage');
  limitWarningMessage.classList.add('hide'); // hide warning message now that we have begun fetching new data

  if (table.dataTable) {
    table.dataTable.ajax.reload();
  } else {
    const tableId = table.hasMoreThanOneTable
      ? table.searchFields[table.currentInputId](document.getElementById(table.currentInputId))[1]
      : '';
    const tableElement = table.hasMoreThanOneTable
      ? $(`table.data-table-server-side.primary-data-table#${tableId}`)
      : $('table.data-table-server-side.primary-data-table');
    if (!tableElement.length) {
      window.alert('Missing tableElement');
      return;
    }
    if (!tableElement.DataTable) {
      // sometimes the dataTable object takes a little time to initialize after page load for some unknown reason
      setTimeout(handleSubmit(ev, inputId), 500);
      return;
    }

    table.dataTable = tableElement.DataTable({
      serverSide: false,
      ajax: {
        url: table.url,
        // we pass a function in to the `data` option that dynamically reads from the Input the user pressed Enter on each time
        data: () => {
          const currentInputValue = _.trim(document.getElementById(table.currentInputId).value);
          if (table.hasMoreThanOneTable) {
            return {
              searchParam: table.searchFields[table.currentInputId](currentInputValue)[0],
            };
          }
          return {
            searchParam: table.searchFields[table.currentInputId](currentInputValue),
          };
        },
        complete: function (data) {
          if (table.isLimitHit(table.currentInputId, data)) {
            limitWarningMessage.classList.remove('hide');
          }
        },
      },
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, 'All'],
      ],
      buttons: ['excel', 'csv', 'print'],
      dom: 'lfrpit',
    });
    table.dataTable.buttons().container().appendTo($('.data-table-buttons'));
  }
}

export function init(tableName) {
  const { searchFields } = dataTables[tableName];

  // Call handleSubmit when user presses Enter in one of the search boxes
  for (const inputId of _.keys(searchFields)) {
    document.getElementById(inputId).addEventListener('keydown', (event) => handleSubmit(event, inputId, tableName));
  }
}
