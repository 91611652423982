import 'projects/sharedComponents/src/publicProfile/digInBanner.scss';

import { Button, Grid, Typography } from '@mui/material';

import { buildEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';

export const DigInBanner: React.FC = () => {
  return (
    <div className="digInBanner-container">
      <Grid className="digInBanner-grid">
        <Grid item className="digInBanner-gridText">
          <Typography className="digInBanner-header">Dig into your dog’s mix</Typography>
          <Typography className="digInBanner-text">
            From energy to appetite, from herding to health — it’s amazing how much you can learn about your dog with
            just a cheek swab. What will you find out?
          </Typography>
          <Button
            className="digInBanner-button"
            href="https://shop.embarkvet.com/products/embark-dog-dna-test-kit"
            variant="contained"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get your test
          </Button>
        </Grid>
        <Grid item>
          <img className="digInBanner-img" src={buildEmbarkCDNUrl('/img/pet/img-kits.png')} alt="" />
        </Grid>
      </Grid>
    </div>
  );
};
