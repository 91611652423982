import { AccountDetails } from 'clientServerShared/types/account';

export enum UserType {
  PetParent = 'PetParent',
  Breeder = 'Breeder',
  Vet = 'Vet',
}

export function getUserType(accountDetails: AccountDetails): UserType {
  if (accountDetails.user?.permissions.isVet) {
    return UserType.Vet;
  }

  if (accountDetails.user?.permissions.isBreeder) {
    return UserType.Breeder;
  }

  return UserType.PetParent;
}
