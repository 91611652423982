import { UserType, getUserType } from 'projects/sharedComponents/src/services/user/userTypes';

import { AccountDetails } from 'clientServerShared/types/account';
import { BackToLink } from 'projects/sharedComponents/src/pet/navigation/backToLink';
import { FC } from 'react';

export type BackToMyDogsLinkProps = {
  petCount: number;
  accountDetails: AccountDetails;
  className?: string;
};

export const BackToMyDogsLink: FC<BackToMyDogsLinkProps> = (props) => {
  const { petCount, accountDetails, className } = props;

  const userType = getUserType(accountDetails);

  const shouldShowBackToMyDogs = petCount > 1 || userType !== UserType.PetParent;

  const linkText = userType === UserType.Vet ? 'Back to my patients' : 'Back to my dogs';

  if (!shouldShowBackToMyDogs) {
    return <></>;
  }

  return <BackToLink linkText={linkText} urlPath="/" className={className} isReactRouterLink={false} />;
};
