import { AccountDetails } from 'clientServerShared/types/account';
import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { FC } from 'react';
import { GeneticStatsSection } from '@embark/shared-components/src/geneticStats/geneticStatsSection';
import ReactDOM from 'react-dom';
import { getPetNumFromQueryString } from 'projects/legacyClient/src/react/pet/getPetNumFromQueryString';
import { handleGraphQLError } from '@embark/shared-components/src/services/errors/handleGraphQLError';
import { useAppSelector } from 'projects/legacyClient/src/react/state/hooks';
import { useGetGeneticStatsSectionDataQuery } from '@embark/shared-components/src/generated/graphql-client-types';

export function initGeneticStatsSection(): void {
  ReactDOM.render(
    <AppProviders>
      <GeneticStatsSectionContainer />
    </AppProviders>,
    document.getElementById('geneticStatsSection')
  );
}

const GeneticStatsSectionContainer: FC = () => {
  const petNum = getPetNumFromQueryString();
  const accountDetails: AccountDetails | undefined = useAppSelector((state) => state.accountDetails);

  const { data, error } = useGetGeneticStatsSectionDataQuery({
    variables: {
      petNum: petNum,
    },
  });

  if (error) {
    handleGraphQLError(error);
    return <></>;
  }

  if (!data?.me || !data.me.petByPetNum) {
    return <></>;
  }

  return <GeneticStatsSection pet={data.me.petByPetNum} accountDetails={accountDetails} isPublicProfile={false} />;
};
