import { AppProviders } from 'projects/legacyClient/src/appProviders';
import { FC } from 'react';
import { ProfileDataSettingsCard } from '@embark/shared-components/src/profileDataSetting/profileDataSettingsCard';
import ReactDOM from 'react-dom';
import { getPetNumFromQueryString } from 'projects/legacyClient/src/react/pet/getPetNumFromQueryString';
import { handleGraphQLError } from '@embark/shared-components/src/services/errors/handleGraphQLError';
import { useGetProfileDataSettingsDataQuery } from '@embark/shared-components/src/generated/graphql-client-types';

export function initProfileDataSettingsCard(): void {
  ReactDOM.render(
    <AppProviders>
      <ProfileDataSettingsContainer />
    </AppProviders>,
    document.getElementById('data-settings-card')
  );
}

const ProfileDataSettingsContainer: FC = () => {
  const petNum = getPetNumFromQueryString();

  const { data, error } = useGetProfileDataSettingsDataQuery({
    variables: {
      petNum: petNum,
    },
  });

  if (error) {
    handleGraphQLError(error);
    return <></>;
  }

  if (!data?.me || !data.me.petByPetNum) {
    return <></>;
  }

  return <ProfileDataSettingsCard user={data.me} pet={data.me.petByPetNum} />;
};
