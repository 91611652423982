import { getCsrfToken } from './csrf';

// Location column has values like '123 miles' and '2 miles' which we want to sort numerically on the numeric component, not in alphabetical order
// Source: https://datatables.net/plug-ins/sorting/any-number
function sortNumericallyIgnoringText(a, b, high): number {
  // we also need to deal with values for `a` and `b` that are html anchor tags with the numerical-text inside them, e.g.
  // "<a target="_blank" href="https://www.google.com/maps/search/?api=1&query=42.3600825,-71.05888010000001">190 miles</a>"
  const innerValueA = a.match(/<a.*>(.*?)<\/a>/);
  if (innerValueA) {
    a = innerValueA[1]; // if the regex matched, then the matching group i.e. `190 miles` is the second element of the resulting array
  }
  const innerValueB = b.match(/<a.*>(.*?)<\/a>/);
  if (innerValueB) {
    b = innerValueB[1];
  }
  const reg = /[+-]?((\d+(\.\d*)?)|\.\d+)([eE][+-]?[0-9]+)?/;
  a = a.replace(',', '.').match(reg);
  a = a !== null ? parseFloat(a[0]) : high;
  b = b.replace(',', '.').match(reg);
  b = b !== null ? parseFloat(b[0]) : high;
  return a < b ? -1 : a > b ? 1 : 0;
}

function customFilterDistance(_settings, data, _dataIndex): boolean {
  const LOCATION_COL_IDX = window.embark.matchmaker.columnIndexes.location;
  const checkedDistanceFilters = Array.from(
    document.querySelectorAll('input.distanceFilter:checked')
  ) as HTMLInputElement[];
  const distance = data[LOCATION_COL_IDX].toLowerCase(); // 'unknown' or '100 miles'
  const distanceInt = parseInt(distance, 10) || 0;

  for (const filterInput of checkedDistanceFilters) {
    const filter = filterInput.value; // filter is "unknown" or "250-500"
    if (distance === 'unknown') {
      if (filter === 'unknown') {
        return true;
      }
    } else {
      const min = parseInt(filter.split('-')[0], 10);
      const max = parseInt(filter.split('-')[1], 10);
      if (min <= distanceInt && distanceInt <= max) {
        return true;
      }
    }
  }
  return false;
}

function customFilterCoi(_settings, data, _dataIndex): boolean {
  const OFFSPRING_COI_COL_IDX = window.embark.matchmaker.columnIndexes.offspringCoi;
  const checkedCoiFilters = Array.from(
    document.querySelectorAll('input.offspringCoiFilter:checked')
  ) as HTMLInputElement[];
  const coiAsPercentage = parseInt(data[OFFSPRING_COI_COL_IDX], 10); // 20 or 37

  for (const filterInput of checkedCoiFilters) {
    const filter = filterInput.value; // filter is "0-20"
    const min = parseInt(filter.split('-')[0], 10);
    const max = parseInt(filter.split('-')[1], 10);
    if (min <= coiAsPercentage && coiAsPercentage < max) {
      // we want <= at the lower bound, since we asses these filters in ascending order, to match getCoiTrafficLightColor logic (a dog at coi 20 is yellow, not green)
      return true;
    }
  }
  return false;
}

function customFilterTitle(_settings, data, _dataIndex): boolean {
  const NAME_COL_IDX = window.embark.matchmaker.columnIndexes.name;
  const checkedTitleFilters = Array.from(document.querySelectorAll('input.titleFilter:checked')) as HTMLInputElement[];
  if (checkedTitleFilters.length === 0) {
    // if no title filters are checked, just show all the dogs
    return true;
  }
  const name = data[NAME_COL_IDX]; // "HRCH Uniques Royal Rachael CD MH"

  for (const filterInput of checkedTitleFilters) {
    const filter = filterInput.value; // filter is "CHD"
    if (name.indexOf(filter) !== -1) {
      return true;
    }
  }
  return false;
}

function customFilterAge(_settings, data, _dataIndex): boolean {
  const AGE_COL_IDX = window.embark.matchmaker.columnIndexes.age;
  const checkedAgeFilters = Array.from(document.querySelectorAll('input.ageFilter:checked')) as HTMLInputElement[];
  const age = data[AGE_COL_IDX].toLowerCase(); // "3 yrs 8 months" or "n/a"
  const ageYears = age.split(' ').length ? parseInt(age.split(' ')[0], 10) : 0;

  for (const filterInput of checkedAgeFilters) {
    const filter = filterInput.value; // filter is "unknown" or "250-500"
    if (age === 'n/a') {
      if (filter === 'unknown') {
        return true;
      }
    } else {
      const min = parseInt(filter.split('-')[0], 10);
      const max = parseInt(filter.split('-')[1], 10);
      if (min <= ageYears && ageYears < max) {
        // 8-10 should include "8yrs 1 month" and not include "10yrs 1 month"
        return true;
      }
    }
  }
  return false;
}

function customFilterBreeds(_settings, data, _dataIndex): boolean {
  const BREEDS_COL_IDX = window.embark.matchmaker.columnIndexes.breeds;
  const checkedBreedFilters = Array.from(document.querySelectorAll('input.breedFilter:checked')) as HTMLInputElement[];
  const breeds: string = data[BREEDS_COL_IDX];

  let countMatch = 0;
  for (const filterInput of checkedBreedFilters) {
    const filter = filterInput.value;
    if (breeds.indexOf(filter) !== -1) {
      countMatch += 1;
    }
  }

  return countMatch > 0;
}

function isBreedFilterHidden(): boolean {
  return !document.querySelector('input.breedFilter');
}

function makeMatchmakerDataTable($): NodeJS.Timeout | undefined {
  if (typeof $ === 'undefined' || !$.fn || !$.fn.dataTable || !$.fn.dataTable.ext) {
    return setTimeout(makeMatchmakerDataTable, 500);
  }

  // Source: https://datatables.net/plug-ins/sorting/any-number
  $.extend($.fn.dataTableExt.oSort, {
    'numeric-ignoring-text-asc': function (a, b) {
      return sortNumericallyIgnoringText(a, b, Number.POSITIVE_INFINITY);
    },
    'numeric-ignoring-text-desc': function (a, b) {
      return sortNumericallyIgnoringText(a, b, Number.NEGATIVE_INFINITY) * -1;
    },
  });

  $.fn.dataTable.ext.search.push(customFilterDistance);
  $.fn.dataTable.ext.search.push(customFilterCoi);
  $.fn.dataTable.ext.search.push(customFilterAge);
  $.fn.dataTable.ext.search.push(customFilterTitle);

  if (!isBreedFilterHidden()) {
    $.fn.dataTable.ext.search.push(customFilterBreeds);
  }

  function addParametersToMatchmakerAjax(d): void {
    d._csrf = getCsrfToken();
    d.petNum = window.embark.matchmaker.petNum;
  }

  // the export buttons don't seem to work on server-side tables
  const tableElement = $('table.data-table-server-side.data-table-matchmaker');
  if (tableElement.length) {
    const matchmakerDataTable = tableElement.DataTable({
      // processing: true,
      orderCellsTop: true, // so that sorting buttons attach to the top header row, and not the dog-info header row
      ajax: {
        url: '/members/breeder-tools/matchmaker-data',
        type: 'POST',
        data: addParametersToMatchmakerAjax,
      },
      lengthMenu: [
        [5, 10, 25, 50, 100, -1],
        [5, 10, 25, 50, 100, 'All'],
      ],
      pageLength: 10,
      dom: 'fptlr',
      columnDefs: [
        {
          type: 'numeric-ignoring-text',
          targets: window.embark.matchmaker.columnIndexes.location,
        },
      ],
    });

    function addChildRows(): void {
      matchmakerDataTable.rows().every(function (this: any) {
        const profileBioColumnIndex = this.data().length - 1;
        const profileBioHtml: string = this.data()[profileBioColumnIndex];
        this.child(profileBioHtml).show();
      });
    }

    function updateMatchesFoundText(): void {
      const numberOfFilteredRows = matchmakerDataTable.page.info().recordsDisplay;
      const numberOfMatchesFoundElement = document.getElementById('numberOfMatchesFound');
      if (numberOfMatchesFoundElement) {
        numberOfMatchesFoundElement.hidden = false;
        numberOfMatchesFoundElement.innerText = `${numberOfFilteredRows} potential match${
          numberOfFilteredRows === 1 ? '' : 'es'
        } found`;
      }
    }

    matchmakerDataTable.on('preDraw', () => {
      addChildRows();
      updateMatchesFoundText();
    });

    // highlight selected rows. Not sure what to use this for yet
    if (window.embark.matchmaker.useRowSelection) {
      tableElement.on('click', 'tr', function (this: HTMLTableElement) {
        if ($(this).hasClass('selected')) {
          $(this).removeClass('selected');
        } else {
          tableElement.find('tr.selected').removeClass('selected');
          $(this).addClass('selected');
        }
      });
    }

    $('.customFilter').click(function () {
      matchmakerDataTable.draw();
    });
  }

  return undefined;
}

export function init($): void {
  makeMatchmakerDataTable($);
}
