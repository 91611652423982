export interface BreedType {
  code: string;
  display: string;
  collectBreedAndRegistrationInformation: boolean;
}

export const breedtypeOptions: BreedType[] = [
  {
    code: '',
    display: 'Choose breed type',
    collectBreedAndRegistrationInformation: false,
  },
  {
    code: 'purebred',
    display: 'Purebred',
    collectBreedAndRegistrationInformation: true,
  },
  {
    code: 'designer',
    display: 'Designer breed',
    collectBreedAndRegistrationInformation: true,
  },
  {
    code: 'mixed',
    display: 'Mixed breed',
    collectBreedAndRegistrationInformation: false,
  },
  {
    code: 'village',
    display: 'Village dog / Street dog',
    collectBreedAndRegistrationInformation: false,
  },
  {
    code: 'unknown',
    display: 'I’m not sure yet!',
    collectBreedAndRegistrationInformation: false,
  },
  {
    code: 'notShared',
    display: 'I prefer not to say',
    collectBreedAndRegistrationInformation: false,
  },
];

export function getBreedtype(code: string | undefined | null): BreedType | undefined {
  for (const breedType of breedtypeOptions) {
    if (breedType.code === code) {
      return breedType;
    }
  }

  return undefined;
}
