import { PetWithTitlesList } from './types/aboutPet';
import Vue from 'vue';

export function init(data: PetWithTitlesList): void {
  new Vue({
    el: '#aboutpet-privacy-container-vue',
    data: data,
    methods: {
      setAgreedToResearch: function (): void {
        data.agreedToResearch = true;
      },
      unsetAgreedToResearch: function (): void {
        data.agreedToResearch = false;
      },
    },
    computed: {},
    watch: {},
  });
}
