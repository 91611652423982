const $ = require('jquery');

export function init() {
  $('.familytree-overlay a').on('click', function (e) {
    e.preventDefault();
    const toShow = $(e.currentTarget).data('target');
    $('.familytree-view').hide();
    $('#' + toShow).show();
  });
}
