import { Box, Typography } from '@mui/material';

import { Dialog } from 'projects/sharedComponents/src/dialog/dialog';
import { FC } from 'react';
import { buildEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';

export type PredictedWeightModalProps = {
  open: boolean;
  onClose: () => void;
};

export const PredictedWeightModal: FC<PredictedWeightModalProps> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" aria-label="Wolfiness">
      <div>
        <Box display="flex" justifyContent="center" className="geneticStatsModal-icon">
          <img src={buildEmbarkCDNUrl('/img/genetic-stats/icon-weight-scale-blue.svg')} alt="" height={104} />
        </Box>

        <Typography variant="h5" component="h2" className="geneticStatsModal-header geneticStatsModal-textBlock">
          <b>Predicted Adult Weight</b>
        </Typography>

        <Typography variant="body1">
          <b>How does weight matter?</b>
        </Typography>

        <Typography variant="body2" className="geneticStatsModal-textBlock">
          For people with puppies, you probably want to know how big of a crate to buy or just how big to expect your
          dog to become. But genetic weight is also useful for people with fully grown dogs. Just like with people,
          overweight and obese dogs suffer reduced length and quality of life. They can develop chronic health
          conditions and suffer from limited mobility and other issues. While over half of American dogs are overweight
          or obese, fewer than 15% of their owners realize it. By comparing your dog’s weight to their genetic predicted
          weight you have one more piece of information about their ideal weight. With this and other pieces of
          information like weight history and body condition, you and your veterinarian may want to discuss your dog’s
          diet, exercise, and weight control plan to give your pup the longest, healthiest life possible.
        </Typography>

        <Typography variant="body1">
          <b>How do we predict weight?</b>
        </Typography>

        <Typography variant="body2" className="geneticStatsModal-textBlock">
          Our test is the only dog DNA test that provides true genetic size not based just on breed ancestry but based
          on over a dozen genes known to influence a dog’s weight. It uses the most advanced science to determine your
          dog’s expected weight based on their sex, the combination of these genes, and breed-specific modifiers.
        </Typography>

        <Typography variant="body1">
          <b>How accurate is the predicted weight?</b>
        </Typography>

        <Typography variant="body2">
          Unlike in people, healthy weight in dogs is controlled largely by only a few genes. Our algorithm explains
          over 85% of the variance in healthy adult weight. However, due to a few as-yet-undiscovered genes and genetic
          interactions that affect size, this algorithm sometimes misses. Occasionally it misses by a fairly large
          amount especially when a dog has a breed with an unknown size-influencing gene. If we have missed your dog’s
          weight, your dog may be a scientific discovery waiting to happen! Please be sure to go to the Research tab and
          complete the Getting to know your dog survey, where you can answer questions about your dog’s current weight
          and body shape. This information will inform our ongoing research into the genetics of size and weight in
          dogs.
        </Typography>
      </div>
    </Dialog>
  );
};
