const d3 = window.d3;

export function drawDetectedInBreedCirclesInEachElementBySelector(selector: string): void {
  d3.selectAll(selector).each(function (this: any) {
    const detectedInBreedCircle = d3.select(this);
    const percentDetected = +detectedInBreedCircle.attr('data-percentdetected');
    const imagePath = detectedInBreedCircle.attr('data-imagepath');
    const altText = detectedInBreedCircle.attr('data-breedname');

    const diameter = 100;
    const radius = diameter / 2;
    const innerDiameter = 86;
    const innerRadius = innerDiameter / 2;
    const tau = 2 * Math.PI;

    const percentageArc = d3.svg
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(radius)
      .startAngle(0)
      .endAngle(tau * (percentDetected / 100));

    const backgroundArc = d3.svg.arc().innerRadius(innerRadius).outerRadius(radius).startAngle(0).endAngle(tau);

    const breedCircleSvg = detectedInBreedCircle
      .append('svg')
      .attr('role', 'img')
      .attr('aria-label', altText)
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `0 0 ${diameter} ${diameter}`)
      .append('g')
      .attr('transform', `translate(${radius}, ${radius})`)
      .classed('svg-responsive', true);
    const squareInCircleDimensions = getLargestSquareSideForCircle(innerDiameter);
    breedCircleSvg
      .append('image')
      .attr('xlink:href', imagePath)
      .attr('x', -squareInCircleDimensions / 2)
      .attr('y', -squareInCircleDimensions / 2)
      .attr('width', squareInCircleDimensions)
      .attr('height', squareInCircleDimensions);
    breedCircleSvg.append('path').attr('d', backgroundArc).attr('fill', '#E6E6E6');
    breedCircleSvg.append('path').attr('d', percentageArc).attr('fill', '#F99F1E');
  });
}

function getLargestSquareSideForCircle(diameter: number): number {
  return diameter / Math.sqrt(2);
}
