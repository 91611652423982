import 'projects/sharedComponents/src/footer/footer.scss';

import { FC, useMemo } from 'react';
import {
  FooterLinkProps,
  buildBreederToolsLinkProps,
  buildFooterPetResultLinkProps,
  buildMoreLinkProps,
  buildMyAccountLinkProps,
} from 'projects/sharedComponents/src/footer/buildFooterNavLinks';
import {
  FooterPetFragment,
  FooterUserFragment,
  UserTypeGraphQl,
} from 'projects/sharedComponents/src/generated/graphql-client-types';
import { HelixFooter, HelixFooterColumnProps } from 'projects/sharedComponents/src/footer/helixFooter/helixFooter';

import { HelixFooterLink } from 'projects/sharedComponents/src/footer/helixFooter/helixFooterLink';
import { Link } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import { buildEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';
import { getHeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';
import { shorten } from 'projects/sharedComponents/src/services/stringFormatters/stringFormatters';

export type FooterProps = {
  user: FooterUserFragment;
  pet: FooterPetFragment | null;
  /** True if this is a page in the React SPA and we can use React Router for routing, false if it's in the legacy client */
  isSpaPage: boolean;
};

export const Footer: FC<FooterProps> = (props) => {
  const { user, pet, isSpaPage } = props;

  const footerColumns = useMemo(() => {
    return buildFooterNavLinks(user, pet, isSpaPage);
  }, [user, pet, isSpaPage]);

  const logo = {
    altText: 'Logo',
    href: '/',
    src: buildEmbarkCDNUrl('/img/logo/embark-logo-rectangle-yellow-dog-black-text.svg'),
  };

  return (
    <div className="footer">
      <HelixFooter columns={footerColumns} logo={logo} user={user} />
    </div>
  );
};

export const buildFooterNavLinks = (
  user: FooterUserFragment,
  pet: FooterPetFragment | null,
  isSpaPage: boolean
): HelixFooterColumnProps[] => {
  const footerColumns: HelixFooterColumnProps[] = [];

  if (!!pet) {
    const petResultLinks = buildFooterPetResultLinkProps(user, pet).map((props) => {
      return buildHelixFooterLink(props, isSpaPage);
    });
    const petName = !!pet.displayName ? shorten(pet.displayName, 25) : 'your dog';

    footerColumns.push({
      header: `Explore ${petName}’s Results`,
      items: petResultLinks,
    });
  }

  const myAccountLinks = buildMyAccountLinkProps(user).map((props) => {
    return buildHelixFooterLink(props, isSpaPage);
  });

  footerColumns.push({
    header: 'My Account',
    items: myAccountLinks,
  });

  if (user.userType === UserTypeGraphQl.Breeder) {
    const breederToolsLinks = buildBreederToolsLinkProps(user).map((props) => {
      return buildHelixFooterLink(props, isSpaPage);
    });

    footerColumns.push({
      header: 'Breeder Tools',
      items: breederToolsLinks,
    });
  }

  if (user.userType !== UserTypeGraphQl.Vet) {
    const moreLinks = buildMoreLinkProps(user, pet).map((props) => {
      return buildHelixFooterLink(props, isSpaPage);
    });

    footerColumns.push({
      header: 'More',
      items: moreLinks,
    });
  }

  return footerColumns;
};

export const buildHelixFooterLink = (props: FooterLinkProps, isSpaPage: boolean): JSX.Element => {
  const { heap, href, shouldOpenInNewTab, isReactRouterLink, ...linkProps } = props;
  const newTabProps = shouldOpenInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  const heapMetadata = getHeapDataAttributes(heap);
  const componentProps =
    isSpaPage && isReactRouterLink ? { component: ReactRouterLink, to: href } : { component: Link };
  return (
    <HelixFooterLink
      key={linkProps.label}
      href={href}
      {...componentProps}
      {...newTabProps}
      {...heapMetadata}
      {...linkProps}
    />
  );
};
