import { ProviderContext } from 'notistack';

export function showCommonErrorSnackbar(
  enqueueSnackbar: ProviderContext['enqueueSnackbar'],
  errorSpecificMessage = 'Something went wrong'
): void {
  enqueueSnackbar(`${errorSpecificMessage}, please try again later`, {
    variant: 'error',
  });
}
