// Make material-ui aware of the custom helix colors.
declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends ColorPalette {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends ColorPalette {}
}

type ColorPalette = typeof materialUIColorPalette;

export const materialUIColorPalette = {
  helix: {
    // Helix - neutrals
    white: '#ffffff',

    // Helix - colors
    blueLightest: '#F4FCFF',
    blueLight: '#DEF3FB',
    blueDark: '#0073a8',
    blueDarkest: '#004D71',
    greyDark: '#61656B',
    greyLight: '#DDDDDD',
    background: '#F7F7F7',
    greyLightest: '#F0F0F0',
    yellowLight: '#FFEEB6',
    purpleLightest: '#EEE7EE',
    purpleLight: '#D6C4D6',
    cream: '#FFF9DC',
    orange: '#F99F1E',
    redLight: '#FDF4F7',
  },
  // Hybrid Health Result Severity
  healthResultSeverity: {
    clear: '#2E7D32',
    notable: '#0288D1',
    increasedRisk: '#C84346',
    noResult: '#F0F0F0',
  },
  // Breed colors
  breed: {
    yellow: '#FFCE34',
    orange: '#F99F1E',
    red: '#F15B5C',
    purple: '#A776A6',
    blue: '#57C1E9',
    green: '#95D600',
    gray: '#797D82',
    tan: '#D7CA9E',
  },
};
