import { ApolloError } from '@apollo/client';
import { graphqlErrorMessages } from 'clientServerShared/types/graphqlErrors';
import { redirectToLegacySite } from 'projects/sharedComponents/src/services/routing/redirect';

const redirectErrors = new Set([graphqlErrorMessages.NotAuthorized, graphqlErrorMessages.Login]);

export function handleGraphQLError(error: ApolloError | undefined): void {
  if (!error) {
    return;
  }

  // We need to check all errors here as sometimes the error comes in as a regular error and sometimes a network error.
  const allErrors: Error[] = [...error.graphQLErrors, ...error.clientErrors];
  if (error.networkError) {
    // If we get a CSRF token validation error, there's not much we can do about it besides asking the user to refresh the page.
    // Throw a specific error here so we can ignore it in Sentry.
    if ('result' in error.networkError) {
      if (error.networkError.result?.message === graphqlErrorMessages.CSRFTokenError) {
        throw new Error(graphqlErrorMessages.CSRFTokenError);
      }
    }

    allErrors.push(error.networkError);
  }

  if (allErrors.some((graphQLError) => redirectErrors.has(graphQLError.message))) {
    redirectToLegacySite('/');
    return;
  }

  // Log this out so we get the console log in Sentry for debugging.
  console.log(JSON.stringify(error, null, 2));

  throw error;
}
