import Vue from 'vue';
import axios from 'axios';
import { getCsrfToken } from './csrf';
import { log } from './browserLogger';

export function init(): void {
  (window as any).vueModel = new Vue({
    el: '#breeder-dog-settings',
    data: {
      sending: false,
      ...window.embark.breederDogSettings,
    },
    methods: {
      togglePrivacy(dog, privacyType) {
        dog.privacy[privacyType] = dog.privacy[privacyType].indexOf('public') === -1 ? 'public-private' : 'private';
      },
      toggleMatchmaker(dog) {
        dog.excludeFromMatchmaker = !dog.excludeFromMatchmaker;
      },
      submitPrivacy: async function (this: any) {
        if (this.sending) {
          return;
        }
        this.sending = true;
        try {
          await axios.post('/members/breeder-dog-settings/privacy', {
            dogsPrivacy: this.dogs.map((d) => ({
              petNum: d.petNum,
              handle: d.handle,
              privacy: d.privacy,
              excludeFromMatchmaker: d.excludeFromMatchmaker,
            })),
            _csrf: getCsrfToken(),
          });
          this.sending = false;
        } catch (err) {
          this.onSendFailed(null, err);
        }
      },
      onSendFailed(this: any, _jqXHR, textStatus) {
        log.info(`${JSON.stringify(textStatus)}`);
        window.alert('Failed to send');
        this.sending = false;
      },
    },
  });
}
