export const AKC_PREFIX_TITLES = [
  'AFC',
  'AGCH',
  'CCH',
  'CFC',
  'CGCH',
  'CGF',
  'CGN',
  'CGW',
  'CH',
  'CNC',
  'CSG',
  'CSGF',
  'CSGN',
  'CSGW',
  'CT',
  'CWC',
  'CWSG',
  'DC',
  'FC',
  'GCH',
  'GCHB',
  'GCHS',
  'GCHG',
  'GCHP',
  'GDSC',
  'GAFC',
  'GFC',
  'HC',
  'MACH',
  'NAC',
  'NAFC',
  'NAGDC',
  'NFC',
  'NGDC',
  'NOC',
  'NOGDC',
  'OTCH',
  'PACH',
  'PNAC',
  'POC',
  'RACH',
  'RGDSC',
  'RNC',
  'TC',
  'VCCH',
  'WNC',
];

export const UKC_PREFIX_TITLES = [
  'A1',
  'A2',
  'AB',
  'AC',
  'ADGCH',
  'AE',
  'AEGCH',
  'AHD',
  'AI',
  'ALCCH',
  'ALCH',
  'ALGCC',
  'ALGCH',
  'AMCH',
  'AN',
  'AOB',
  'APR',
  'ARGCH',
  'ASGCH',
  'AV',
  'AX',
  'BC',
  'CA',
  'CAS',
  'CAX',
  'CCB',
  'CCH',
  'CCM',
  'CD',
  'CDX',
  'CGRCH',
  'CH',
  'CHF',
  'CHF-L',
  'CHF-W',
  'CHSD',
  'CPR',
  'CT',
  'CTS',
  'CTX',
  'DGCH',
  'DOY',
  'EB',
  'EC',
  'EE',
  'EHD',
  'EI',
  'EII',
  'EGCH',
  'EN',
  'ESD',
  'EV',
  'EWDI',
  'F.CH',
  'FC',
  'FO',
  'GAC',
  'GB',
  'GMPR',
  'GF',
  'GH',
  'GJJCH',
  'GMJCH',
  'GN',
  'GNJCH',
  'GOC',
  'GQ',
  'GCB',
  'GCM',
  'GRCHF',
  'GRECH',
  'GRPCH',
  'GRUVC',
  'GS',
  'GSFCH',
  'GSJCH',
  'GSUVC',
  'GT',
  'GUFCH',
  'GUJCH',
  'GUN',
  'GW',
  'GWPC1',
  'GWPCH',
  'HC',
  'HR',
  'HOF',
  'HOF',
  'HOF',
  'HTX',
  'HUNT',
  'LC',
  'MB',
  'ME',
  'MI',
  'MN',
  'MPR',
  'MV',
  'NB',
  'NBC',
  'NBOB',
  'NC',
  'NC',
  'NCH',
  'NDCV',
  'NE',
  'NF.CH',
  'NG',
  'NGC',
  'NGRC',
  'NGRNC',
  'NGRCH2X',
  'NHD',
  'NI',
  'NN',
  'NTLCH',
  'NV',
  'OB1',
  'OB2',
  'OB3',
  'OBPD1',
  'OBPD2',
  'OC',
  'P1',
  'P2',
  'P3',
  'PA',
  'PCH',
  'PD1',
  'PD2',
  'PTA',
  'PTE',
  'PTM',
  'PTN',
  'PTS',
  'REGCH',
  'RGCH',
  'RNFCH',
  'RNTLC',
  'RUVCH',
  'SB',
  'SC',
  'SE',
  'SI',
  'SM',
  'SN',
  'SPOT',
  'SPOT-ON',
  'SQ',
  'SGC',
  'SGCH',
  'ST',
  'SUVCH',
  'SV',
  'SRC',
  'SRAC',
  'SRCC',
  'SRACC',
  'TAN',
  'TR',
  'TRCH',
  'UD',
  'UDX',
  'UEWPCH',
  'UFA',
  'UFCH',
  'UFGCH',
  'UFR',
  'UH',
  'UJJ',
  'UJJCH',
  'UMJ',
  'UMJCH',
  'UNJ',
  'UNJCH',
  'URO1',
  'URO2',
  'URO3',
  'UROC',
  'UROG',
  'URUV',
  'URX',
  'USA',
  'USCH',
  'USFCH',
  'USF',
  'USGCH',
  'USJ',
  'USJCH',
  'USR',
  'USUV',
  'UUFCH',
  'UUF',
  'UUJ',
  'UUJCH',
  'UWPNC',
  'UWPU',
  'VPA',
  'VPE',
  'VPN',
  'VPU',
  'WB',
  'WC',
  'WC',
  'WDPCH',
  'WN',
  'WP',
  'WORLDPCH2X',
  'WPC',
  'WPO',
  'WPPNC',
  'WPS',
  'WPV',
  'WPX',
  'WRT',
  'WS',
  'WS2',
  'WSD',
  'WSQDC',
];

export const AKC_SUFFIX_TITLES = [
  'ACT1',
  'ACT2',
  'AJP',
  'AX',
  'AXJ',
  'AXP',
  'BCAT',
  'BN',
  'CA',
  'CAA',
  'CAX',
  'CAX2',
  'CD',
  'CDX',
  'CGC',
  'CGCA',
  'CGCU',
  'CM',
  'CZ8B',
  'CZ8G',
  'CZ8P',
  'CZ8S',
  'DCAT',
  'EE',
  'FCAT',
  'FDC',
  'FTC',
  'FTCP',
  'GN',
  'GO',
  'HI',
  'HIAdsc',
  'HIBdsc',
  'HICs',
  'HS',
  'HSAdsc',
  'HSBdsc',
  'HSCs',
  'HT',
  'HX',
  'HXAdsc',
  'HXBdsc',
  'HXCs',
  'JC',
  'JE',
  'JH',
  'JHA',
  'JHR',
  'JHU',
  'LCX',
  'MC',
  'ME',
  'MFB',
  'MFC',
  'MFG',
  'MFP',
  'MFPB',
  'MFPC',
  'MFPG',
  'MFPS',
  'MFS',
  'MH',
  'MHA',
  'MHR',
  'MHU',
  'MJB',
  'MJC',
  'MJG',
  'MJP',
  'MJPB',
  'MJPC',
  'MJPG',
  'MJPS',
  'MJS',
  'MNH',
  'MX',
  'MXB',
  'MXC',
  'MXF',
  'MXG',
  'MXJ',
  'MXP',
  'MXPB',
  'MXPC',
  'MXPG',
  'MXPS',
  'MXS',
  'NA',
  'NAJ',
  'NAP',
  'NF',
  'NFP',
  'NJP',
  'OA',
  'OAJ',
  'OAP',
  'OF',
  'OFP',
  'OGM',
  'OJP',
  'OM',
  'PAD',
  'PADP',
  'PAX',
  'PCD',
  'PCDX',
  'PDB',
  'PDBP',
  'PDC',
  'PDCP',
  'PDG',
  'PDGP',
  'PDS',
  'PDSP',
  'PJB',
  'PJBP',
  'PJC',
  'PJCP',
  'PJD',
  'PJDP',
  'PJG',
  'PJGP',
  'PJS',
  'PJSP',
  'PT',
  'PUDX',
  'PUTD',
  'RN',
  'RA',
  'RATCH',
  'RATCHX',
  'RATM',
  'RATN',
  'RATO',
  'RATS',
  'RE',
  'RAE',
  'RI',
  'RM',
  'SBA',
  'SBAE',
  'SBE',
  'SBEE',
  'SBM',
  'SBME',
  'SBN',
  'SBNE',
  'SC',
  'SCA',
  'SCAE',
  'SCE',
  'SCEE',
  'SCM',
  'SCME',
  'SCN',
  'SCNE',
  'SE',
  'SEA',
  'SEAE',
  'SEE',
  'SEEE',
  'SEM',
  'SEME',
  'SEN',
  'SENE',
  'SH',
  'SHA',
  'SHDA',
  'SHDAE',
  'SHDE',
  'SHDEE',
  'SHDM',
  'SHDME',
  'SHDN',
  'SHDNE',
  'SHR',
  'SHU',
  'SIA',
  'SIAE',
  'SIE',
  'SIEE',
  'SIM',
  'SIME',
  'SIN',
  'SINE',
  'SWA',
  'SWAE',
  'SWD',
  'SWE',
  'SWEE',
  'SWM',
  'SWME',
  'SWN',
  'SWNE',
  'T2B',
  'T2BP',
  'TD',
  'TDU',
  'TDX',
  'THD',
  'THDA',
  'THDD',
  'THDN',
  'THDX',
  'TKN',
  'TKI',
  'TKA',
  'TKP',
  'TKE',
  'TQX',
  'TQXP',
  'UD',
  'UDX',
  'VCD1',
  'VCD2',
  'VCD3',
  'VCD4',
  'VER',
  'VST',
  'XF',
  'XFP',
];

export const POPULAR_CUSTOMER_SUFFIX_TITLES = ['BH', 'RATI', 'WAC', 'DJ'];
