import {
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  CareResourcesReasonCategory,
  CareResourcesResultsBannerFragment,
} from 'projects/sharedComponents/src/generated/graphql-client-types';
import { HeapAction, HeapCategory, HeapMetadata } from 'clientServerShared/types/eventTracking';
import { UrlPathPrefix, buildEmbarkCDNUrl } from 'projects/sharedComponents/src/services/links/linkBuilder';
import { himOrHer, hisOrHer } from 'projects/sharedComponents/src/services/formatting/sexFormatting';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FC } from 'react';
import { getHeapDataAttributes } from 'projects/sharedComponents/src/services/eventTracking/eventTracking';
import { navigateToLegacySite } from 'projects/sharedComponents/src/services/routing/redirect';
import { pluralize } from 'projects/sharedComponents/src/services/stringFormatters/stringFormatters';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router';
import { useShortPetName } from 'projects/sharedComponents/src/hooks/useShortPetName';

export type CareResourcesResultsBannerProps = {
  pet: CareResourcesResultsBannerFragment;
  category: CareResourcesReasonCategory;
  isOnReactPage?: boolean;
};

type Content = {
  heapMetadata: HeapMetadata;
};

export const CareResourcesResultsBanner: FC<CareResourcesResultsBannerProps> = (props) => {
  const { pet, category, isOnReactPage } = props;

  const categoryToContentMap = new Map<CareResourcesReasonCategory, Content>([
    [
      CareResourcesReasonCategory.Breed,
      {
        heapMetadata: {
          heapCategory: HeapCategory.RESULTS_BREED,
          heapAction: HeapAction.CARE_RESOURCES,
        },
      },
    ],
    [
      CareResourcesReasonCategory.Health,
      {
        heapMetadata: {
          heapCategory: HeapCategory.RESULTS_HEALTH,
          heapAction: HeapAction.CARE_RESOURCES,
        },
      },
    ],
    [
      CareResourcesReasonCategory.Trait,
      {
        heapMetadata: {
          heapCategory: HeapCategory.RESULTS_TRAITS,
          heapAction: HeapAction.CARE_RESOURCES,
        },
      },
    ],
  ]);

  const history = useHistory();

  const shortPetName = useShortPetName(pet.name);

  const content = categoryToContentMap.get(category);

  const categoryTargets = (pet.careResources?.targets ?? []).filter((target) => {
    return target.category === category;
  });

  if (!pet.shouldShowCareResources || categoryTargets.length < 1 || !content) {
    return null;
  }

  const handleOnClick: CardActionAreaProps['onClick'] = () => {
    const careResourcesUrl: UrlPathPrefix = `/members/results/care?i=${pet.petNum}`;
    if (!!isOnReactPage) {
      history.push(careResourcesUrl);
    } else {
      navigateToLegacySite(careResourcesUrl);
    }
  };

  const resourceOrResources = pluralize(categoryTargets.length, 'resource', 'resources');

  return (
    <CareResourcesCard elevation={0}>
      <CardActionArea disableRipple onClick={handleOnClick} {...getHeapDataAttributes(content.heapMetadata)}>
        <CareResourcesCardContent>
          <Stack direction="row" spacing={{ xs: 2, sm: 3 }} alignItems="center">
            <img alt="" src={buildEmbarkCDNUrl('/img/health/icon-slightly-open-book.svg')} width={56} height={56} />
            <Stack flexGrow={1}>
              <Typography>
                <b>{shortPetName}’s care resources are here!</b>
              </Typography>
              <Typography fontSize="0.875rem">
                Check out {categoryTargets.length} new care {resourceOrResources} for {himOrHer(pet.sex)} based on{' '}
                {hisOrHer(pet.sex)} {category.toLowerCase()} results.
              </Typography>
            </Stack>
            <ChevronRightIcon aria-hidden="true" fontSize="large" />
          </Stack>
        </CareResourcesCardContent>
      </CardActionArea>
    </CareResourcesCard>
  );
};

const CareResourcesCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.helix.yellowLight,
  borderRadius: theme.spacing(1),
}));

const CareResourcesCardContent = styled(CardContent)(({ theme }) => {
  const isSmallOrLargerScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return {
    'padding': `${theme.spacing(2)} ${theme.spacing(isSmallOrLargerScreen ? 3 : 2)}`,
    'paddingRight': `${theme.spacing(isSmallOrLargerScreen ? 2 : 1)}`,
    '&.MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(2),
    },
  };
});
