import 'projects/sharedComponents/src/header/header.scss';

import React, { useEffect } from 'react';
import { buildMyEvLegacyLink, getMyEvLegacyLink } from 'projects/sharedComponents/src/services/links/linkBuilder';

import { AccountDetails } from 'clientServerShared/types/account';
import AppBar from '@mui/material/AppBar';
import { HeaderLinks } from 'projects/sharedComponents/src/header/headerLinks';
import { Impersonation } from 'projects/sharedComponents/src/header/impersonation';
import { Link } from 'react-router-dom';
import { MobileHeaderLinks } from 'projects/sharedComponents/src/header/mobileLinks';
import { RenderIf } from 'projects/sharedComponents/src/renderStates/renderIf';
import { StandardLink } from 'projects/sharedComponents/src/links/standardLink';
import { isEmpty } from 'lodash';
import { useHeader } from 'projects/sharedComponents/src/header/headerHooks';

type HeaderProps = {
  title?: string;
  logoLinksToLegacyMyEV?: boolean;
  accountDetails: AccountDetails;
  className?: string;
};

const defaultTitlePrefix = 'Embark Dog DNA Test';

export const Header: React.FC<HeaderProps> = ({ title, accountDetails, logoLinksToLegacyMyEV = false, className }) => {
  const { title: headerTitle, desktopLogoSrc, mobileLogoSrc, chatBubbleAriaLabel } = useHeader(accountDetails, title);

  // Set the tab title in the browser to the header title if it changes.
  useEffect(() => {
    let pageTitle = defaultTitlePrefix;
    if (!isEmpty(headerTitle)) {
      pageTitle += ` - ${headerTitle}`;
    }

    document.title = pageTitle;
  }, [headerTitle]);

  const userIsAdmin = !!accountDetails.user?.permissions.isAdmin;
  const canImpersonateAsSubAdmin = !!accountDetails.user?.permissions.canImpersonateAsSubAdmin && !userIsAdmin;

  return (
    <>
      <Impersonation accountDetails={accountDetails} />
      <AppBar elevation={1} position="static" className={className}>
        <nav className="header-nav" aria-label="Primary">
          <div className="left-nav-section">
            <LogoLinkComponent logoLinksToLegacyMyEV={logoLinksToLegacyMyEV}>
              <img className="mediumHeader-logo desktop-embark-logo" src={desktopLogoSrc} alt="Embark Logo" />
              <img className="mediumHeader-logo mobile-embark-logo" src={mobileLogoSrc} alt="Embark Logo" />
            </LogoLinkComponent>
          </div>
          <div className="header-centerNavContent">
            <RenderIf condition={userIsAdmin}>
              <StandardLink className="header-centerNavContentAdminLink" href={buildMyEvLegacyLink('/admin')}>
                *ADMIN*
              </StandardLink>
            </RenderIf>
            <RenderIf condition={canImpersonateAsSubAdmin}>
              <StandardLink className="header-centerNavContentAdminLink" href={buildMyEvLegacyLink('/cs/impersonate')}>
                *Impersonate*
              </StandardLink>
            </RenderIf>
          </div>
          <div className="right-nav-section">
            <MobileHeaderLinks chatBubbleAriaLabel={chatBubbleAriaLabel} accountDetails={accountDetails} />
            <HeaderLinks chatBubbleAriaLabel={chatBubbleAriaLabel} accountDetails={accountDetails} />
          </div>
        </nav>
      </AppBar>
    </>
  );
};

const LogoLinkComponent: React.FC<Pick<HeaderProps, 'logoLinksToLegacyMyEV'>> = ({
  logoLinksToLegacyMyEV,
  children,
}) => {
  if (!logoLinksToLegacyMyEV) {
    return <Link to="/breeders">{children}</Link>;
  } else {
    return <a href={getMyEvLegacyLink()}>{children}</a>;
  }
};
